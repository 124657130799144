import { call, all, takeEvery, put } from 'redux-saga/effects'
import * as API_ESTIMATION from '../estimation/services'
import actions from "./actions";
export function * GET_CURRENT_USER({payload}) {
    const {user = {}} = payload;
    const profile = yield call(API_ESTIMATION.getUserWithUID, user.uid);
    if(user) {
        yield put({
            type: actions.SET_STATE,
            payload: {
                ...payload,
                profile: profile,
                user: user
            }
        })
    }
}

export default function * userSagas() {
    yield all([
        takeEvery(actions.GET_CURRENT_USER, GET_CURRENT_USER),
    ])
}