import React from "react";
import { Link } from "react-router-dom";

const ImmobiliereSectionTwo = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="text-center">
          <h2 className="mb-4 font-poppins font-weight-600 max-w-2 mx-auto">
            ÉVALUATION IMMOBILIÈRE : C’EST QUOI ?
          </h2>
          <p className="text-16">
            De façon très simple, l’évaluation immobilière ou estimation
            immobilière est le processus permettant de connaître la valeur
            marchande d’un bien immobilier (maison, appartement, studio, condo,
            immeuble).
          </p>
          <p className="text-16">
            Celle-ci se base sur plusieurs facteurs tels que l’emplacement
            géographique, l’état du logement, l’âge de la propriété, les
            dimensions, la surface habitable, etc. L’évaluation immobilière peut
            être réalisée dans le cadre d’un projet de vente, d’achat, de
            location ou par simple curiosité.
          </p>
          <p className="text-16">
            L’important lors d’une évaluation immobilière réside dans une
            impartialité et une neutralité de l’estimation. De ce fait, il est
            fortement recommandé d’effectuer vos évaluations immobilières en
            partenariat avec des professionnels tiers agréés.
          </p>
          <p className="mb-0 text-16">
            Ziaway garantie la promesse de fournir des estimations neutres et
            impartiales pour tous vos projets. En effet, Ziaway est une
            plateforme développée au Québec par des professionnels de
            l'immobilier et des nouvelles technologies. Par conséquent, nous
            n’appartenons à aucun organisme ou groupe immobilier. Donc, notre
            indépendance vous assure des estimations immobilières précises,
            justes, équitables, neutres et impartiales.
          </p>
          <div className="mt-4">
            <Link
              to="/homepage"
              className="btn btn-primary font-poppins rounded-2 px-4 py-3 font-weight-500 text-16"
            >
              Estimer ma propriété
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionTwo;
