import firebase from '../../firebaseService';

export const getLatestEstimation = async () => {
    const querySnapshot = await firebase.firestore()
        .collection("RapportsEvaluations")
        .orderBy("dateCreation", "desc")
        .limit(12)
        .get();
    const estimations = [];
    querySnapshot.forEach(item => {
        const data = item.data();
        estimations.push({id: item.id, ...data});
    })
    return estimations;
}

