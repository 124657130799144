import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import heropage from '../../../assets/images/homepage/hero-image.webp';
import AdressField from "../../../components/adressField";
import Suggestions from "../../../components/suggestion";
import {useDispatch} from "react-redux";
import estimationActions from "../../../redux/estimation/actions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const images = [
    "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHVzZXJ8ZW58MHx8MHx8fDA%3D",
];

const renderImages = () => {
    const elements = [];
    for (let i = 0; i < images.length; i++) {
        elements.push(
            <span key={i} className="block avatar rounded-circle">
        <img
            className="d-block h-100 w-100 rounded-circle"
            src={images[i]}
            alt={`avatar-${i}`}
        />
      </span>
        );
    }
    return elements;
};

const SectionHero = () => {
    const {
        handleSubmit,
        setValue,
    } = useForm();
    const history = useNavigate();
    const { t } = useTranslation();
    const [address, setAddress] = useState(null);
    const [validationError, setValidationError] = useState("");
    const [place, setPlace] = useState(null);
    const dispatch = useDispatch();
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
        placesService,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        debounce: 1000,
    });

    const adressInputRef = useRef(null);

    const [showSuggestions, setShowSuggestions] = useState(false);

    function placeToAddress(place) {
        var address = {};
        if (place.address_components) {
            address.value = place.formatted_address;
            address.latlng = place.geometry && {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            place.address_components.forEach(function (c) {
                switch (c.types[0]) {
                    case "street_number":
                        address.StreetNumber = c;
                        break;
                    case "route":
                        address.StreetName = c;
                        break;
                    case "neighborhood":
                    case "locality": // North Hollywood or Los Angeles?
                        address.city = c.long_name;
                        break;
                    case "administrative_area_level_1": //  Note some countries don't have states
                        address.state = c;
                        break;
                    case "postal_code":
                        address.postcode = c.short_name;
                        break;
                    case "country":
                        address.Country = c;
                        break;
                    default:
                        break;
                    /*
                     *   . . .
                     */
                }
            });
        }
        return address;
    }

    const validateField = (values) => {
        const provinces = ["QC", "Québec"];
        if (!values.StreetName) {
            return "L'adresse est requise";
        } else if (!values.postcode) {
            return "Le code postal est requis";
        } else if (provinces.indexOf(values.state.short_name) === -1) {
            return "Notre service est limité à la province de Québec seulement";
        }

        // Add other validation rules as needed

        return ""; // Return an empty string if validation passes
    };

    const onSubmit = (data) => {
        if (!validationError) {
            dispatch({
                type: estimationActions.SET_STATE,
                payload: {
                    address: data.address || {}
                }
            })
            history("/accommodation", {
                state: {address: data?.address}
            });
        }
    };

    return (
        <section className="home-section-one steps-home mt-lg-4 pt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container mx-auto pb-3">
                    <Row className="align-items-center">
                        <Col>
                            <div>
                                <h2 className="font-poppins font-weight-bold text-violet-2 mb-3">
                                    {t("home-new.1")}{" "}
                                    <span className="text-orange"> {t("home-new.2")}</span>
                                    <br className="d-none d-xl-block" />
                                    {t("home-new.3")}
                                </h2>
                                <p className="font-poppins h5 font-weight-normal mb-4 text-black-2">
                                    {t("home-1.2")}
                                </p>
                                <div className="position-relative">
                                    {showSuggestions && placePredictions?.length > 0 && (
                                        <>
                                            <Suggestions
                                            placePredictions={placePredictions}
                                            placesService={placesService}
                                            setValue={setPlace}
                                            placeToAddress={placeToAddress}
                                            followUpFunc={(place) => {
                                                setAddress(place);
                                                const error = validateField(place);
                                                setValidationError(error);
                                            }}
                                            setFieldValue={setValue}
                                            setShowPredictions={setShowSuggestions}
                                            adressInputRef={adressInputRef}
                                        />
                                        </>
                                    )}
                                    <div className="bg-white p-3 box-shadow-2 rounded-xl">
                                        <div
                                            className="d-flex flex-column flex-md-row"
                                            style={{ gap: 16 }}
                                        >
                                            <AdressField
                                                getPlacePredictions={getPlacePredictions}
                                                isPlacePredictionsLoading={isPlacePredictionsLoading}
                                                setShowPredictions={setShowSuggestions}
                                                value={place}
                                                setValue={setPlace}
                                                adressInputRef={adressInputRef}
                                            />
                                            <button
                                                disabled={address === null ? true : false}
                                                type="submit"
                                                className="font-poppins rounded-2 p-3 btn btn-primary font-weight-500 text-12"
                                            >
                                                {t("home-new.6")}
                                            </button>
                                        </div>
                                        {validationError && (
                                            <span className="text-danger">{validationError}</span>
                                        )}
                                    </div>
                                    <p style={{ color: "#8792a2" }} className="mt-2 text-justify">
                                        {t("home-new.7")}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-4 mb-lg-0 order-first order-lg-last">
                            <div className="position-relative box-image">
                <span className="box-cover-image">
                  <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 424 424"
                      fill="none"
                  >
                    <rect
                        x="-1.42456"
                        y="26.393"
                        width={400}
                        height={400}
                        rx={30}
                        transform="rotate(-3.98779 -1.42456 26.393)"
                        fill="#5F3994"
                    />
                  </svg>
                </span>
                                <LazyLoadImage src={heropage} wrapperClassName={"p-2 d-block position-relative"} effect="blur" />
                            </div>
                        </Col>
                    </Row>

                    <div className="max-w-2 mx-auto mt-5">
                        <div
                            className="d-flex flex-column flex-lg-row"
                            style={{ gap: "1.5rem" }}
                        >
                            <div className="w-100 card-3 p-3 p-md-4 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <div className="d-flex">
                                    {renderImages()}{" "}
                                    <span className="plus-avatar rounded-circle">+</span>
                                </div>
                                <span className="mt-2 mt-md-0 ml-3 title font-poppins">
                  +150 <br className="d-none d-md-block" /> professionnels
                </span>
                            </div>
                            <div className="w-100 card-3 p-3 p-md-4 d-flex align-items-center justify-content-center">
                <span className="block box-size rounded-circle">
                  <img
                      className="d-block h-100 w-100 rounded-circle"
                      src={heropage}
                      alt="..."
                  />
                </span>
                                <span className="ml-3 title font-poppins px-0 col-7 col-sm-6">
                  +10k simulations effectuées
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default SectionHero;
