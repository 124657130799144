import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import store from "./redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./assets/styles/app.scss";
import Loader from "./components/loader";
import { Suspense } from "react";
import MainLayout from "./layout/mainLayout";
import firebase from "./firebaseService";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const App = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Suspense fallback={<Loader />}>
            <MainLayout />
          </Suspense>
        </ReactReduxFirebaseProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};
export default App;
