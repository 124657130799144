import axios from "axios";
import firebase, { auth } from "../../firebaseService";

const baseUrlPro = "https://us-central1-ziaapp-ac0eb.cloudfunctions.net";

const getNews = async () => {
  return axios
    .get(`${baseUrlPro}/GetNews`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

export const getUsers = async () => {
  const querySnapshot = await firebase.firestore().collection("users").get();
  const users = [];
  querySnapshot.forEach((item) => {
    const data = item.data();
    users.push({ id: item.id, ...data });
  });
  return users;
};

export const captchaVerifier = async (setCaptchaSuccess, setValue, id) => {
  return new firebase.auth.RecaptchaVerifier(
    id ? id : "recaptcha",
    {
      size: "normal",
      callback: () => {
        setCaptchaSuccess(true);
        setValue("captcha", "done");
      },
      "expired-callback": () => {
        setCaptchaSuccess(false);
        setValue("captcha", "");
      },
    },
    auth.app
  );
};
export const onConfirmCode = async (confirmationResult, code) => {
  return confirmationResult
    .confirm(code)
    .then((res) => {
      return res.user;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const zohoPostLead = (data) => {
  const postZoho = firebase.functions().httpsCallable("useZohoPostNewLead");
  postZoho(data);
};

export { getNews };
