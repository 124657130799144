import React from "react";
import { Link } from "react-router-dom";

const ImmobiliereSectionFive = () => {
  return (
    <section className="py-5 bg-primary">
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col">
            <div className="pr-xl-5">
              <h2 className="mb-4 font-poppins font-weight-600 text-white">
                ÉVALUATION IMMOBILIÈRE : COMBIEN ÇA COÛTE AVEC ZIAWAY ?
              </h2>
              <p className="text-16 text-gray-400">
                Chez Ziaway, l’estimation de la valeur marchande de votre
                propriété est gratuite, instantanée et sans engagement.
              </p>
              <p className="text-16 text-gray-400">
                Toute l’équipe de Ziaway travaille dans un but commun, celui de
                vous permettre de réaliser votre projet immobilier. Nous
                fournissons des évaluations immobilières fiables calculées à
                partir des prix de ventes réels.
              </p>

              <p className="text-16 text-gray-400">
                Nos algorithmes sont puissants et pertinents. Toutes nos
                estimations mettent à votre disposition plusieurs outils utiles
                au succès de votre projet immobilier. Bien entendu, la réussite
                d’un projet implique plusieurs partenaires. C’est pourquoi,
                selon votre projet, Ziaway vous propose une liste de partenaires
                de confiance les plus pertinents selon vos objectifs.
              </p>

              <p className="text-16 text-gray-400">
                Concrètement, Ziaway devient le créateur de synergie à forte
                valeur ajoutée et votre évaluateur de confiance. Nous mettons
                toutes nos compétences et nos savoir-faire pour créer un cercle
                vertueux basé sur la transparence entre toutes les parties
                prenantes.
              </p>
            </div>
          </div>
          <div className="col d-none d-lg-block">
            <img
              className="d-block h-100 w-100 rounded-2 object-cover"
              src="https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="..."
            />
          </div>
        </div>
        <div className="mt-4 mt-lg-5 text-center">
          <Link
            to="/homepage"
            className="btn btn-outline-gray-200 font-poppins rounded-2 px-4 py-3 font-weight-500 text-16"
          >
            Estimer ma propriété
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionFive;
