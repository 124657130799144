import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "../../firebaseService";
import {useDispatch, useSelector} from "react-redux";
import estimateActions from "../../redux/estimation/actions";
import FormModify from "../evaluationBien/FormModify";
import MapEstimate from "../../components/mapEstimate";
import ziawayLoader from "assets/images/ziawayLoader.gif";
import rent from "assets/images/rent.png";

const AccommodationPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { address = {}} = useSelector(state => state.estimation);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState(null);
    const [charData, setCharData] = useState([]);
    const [information, setInformation] = useState([]);

    const characteristicsData = [
        {
            title: t("Estimate-new.8"),
            badges: ["Appartement", "1980", "54m2", "3 pièces"],
        },
        {
            title: t("Estimate-new.9"),
            badges: [
                "3 chambres",
                "2 salles de bain",
                "1 garage",
                "2 stationnements",
            ],
        },
    ];

    const goToEstimationForm = () => {
        const formData = {
            anneeConstruction: data.anneeConstruction,
            superficie: data.superficieTerrain,
            chambres: data.NbrChambres,
            bains: data.salleBains,
            stationnement: data.stationnements,
            taxesMunicipale: data.taxeMunicipaleCalc,
            evaluationMunicipale: data.evalMunicipalCalc,
            pieces: data.Nbrpieces,
            type: data.type,
            genreProprietes: data.genreProprietes,
            typeBatiment: data.typeBatiment,
        }
        const getStep = () => {
            if (data?.type === "Unifamiliale") {
                return 7;
            } else if (data?.type === "condo") {
                return 6
            }
            return 1;
        }
        navigate("/estimate-form", {
            state: {
                pathname: "/estimate-form",
                address: location.state,
                data: formData,
                step: getStep()
            }
        })
    }

    useEffect(() => {
        if (location) {
            setLoading(true);
            dispatch({
                type: estimateActions.SET_STATE,
                payload: {
                    showHeader: false
                }
            })
            const data = {
                postalCode: address.postcode,
                address: `${address.StreetNumber?.long_name} ${address.StreetName?.long_name}`,
            };
            var EvaluerBien = firebase
                .functions()
                .httpsCallable("GetProperyDetailInfoNew");
            EvaluerBien(data)
                .then((res) => {
                    dispatch({
                        type: estimateActions.SET_STATE,
                        payload: {
                            showHeader: true
                        }
                    })
                    if (res?.data?.length === 0) {
                        navigate("/estimate-form", {
                            state: {
                                pathname: "/estimate-form",
                                address: location.state?.address,
                                data: null,
                                step: 1,
                                state: { address: location.state, data: null },
                            }
                        })

                    } else {
                        setLoading(false);
                        console.log("GetProperyDetailInfoNew", res);
                        setData(res?.data[0]);
                        setCharData([
                            res?.data[0]?.type,
                            res?.data[0]?.genreProprietes,
                            res?.data[0]?.anneeConstruction,
                            ` ${parseInt(res?.data[0]?.superficieTerrain)} sq ft`,
                        ]);
                        setInformation([
                            `${res?.data[0]?.Nbrpieces} pieces`,
                            `${res?.data[0]?.NbrChambres} chambres`,
                            `${res?.data[0]?.salleBains} salles de bain`,
                            `${res?.data[0]?.stationnements} stationnements`,
                        ]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [location]);

    return (
        <>
            {loading ?
                <div
                    className="d-flex flex-column align-items-center justify-content-center bg-white shadow-sm rounded-xl p-4"
                    style={{
                        height: "100vh",
                    }}
                >
                    <img
                        height={200}
                        width={200}
                        src={ziawayLoader}
                        alt=""
                    />
                    <h4 className="mb-0">{t("Estimate-new.120")}</h4>
                </div>
                :
                <section className="flex-fill py-5 my-xl-5">
                    <div className="container">
                        <div className="px-0 mx-auto col-12 col-xl-10">
                            <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
                                {t("Estimate-new.1")}
                            </h2>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="bg-white box-shadow-3 rounded-2 p-3">
                                        <div
                                            className="bg-gray-100 rounded-lg"
                                            style={{height: 180}}
                                        >
                                            <div className="d-block h-100 w-100 rounded-lg border-0">
                                                <MapEstimate
                                                    markers={[
                                                        {
                                                            location: address,
                                                        },
                                                    ]}
                                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCd2d_otf6zxLsyj9OVyzJoZVAPiGgpfsY&v=3.exp&libraries=geometry,drawing,places"
                                                    loadingElement={<div style={{height: `100%`}}/>}
                                                    containerElement={
                                                        <div style={{height: `100%`, width: `100%`}}/>
                                                    }
                                                    mapElement={<div style={{height: `100%`}}/>}
                                                    polygon={[]}
                                                    h
                                                />
                                            </div>
                                        </div>
                                        <ul
                                            className="nav flex-column flex-nowrap my-4"
                                            style={{gap: 20}}
                                        >
                                            <li>
                                                <h6 className="mb-2 d-block text-dark-gray font-weight-normal font-poppins">
                                                    {t("Estimate-new.2")}
                                                </h6>
                                                <h6 className="mb-0 d-block text-black-2 font-weight-bold font-roboto">
                                                    {address.value}
                                                </h6>
                                            </li>
                                            {loading === false &&
                                                data &&
                                                characteristicsData.map((data) => (
                                                    <li key={data.title}>
                                                        <h6 className="mb-3 d-block text-dark-gray font-weight-normal font-poppins">
                                                            {data.title}
                                                        </h6>
                                                        <div className="d-flex flex-wrap" style={{gap: 10}}>
                                                            {data?.title === "Caractéristiques :"
                                                                ? charData.map((badge) => (
                                                                    <span
                                                                        key={badge}
                                                                        className="badge-box font-roboto"
                                                                    >
                                    {badge}
                                  </span>
                                                                ))
                                                                : information.map((badge) => (
                                                                    <span
                                                                        key={badge}
                                                                        className="badge-box font-roboto"
                                                                    >
                                    {badge}
                                  </span>
                                                                ))}
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                        {loading === false && data && (
                                            <div
                                                className="d-flex flex-column flex-sm-row"
                                                style={{gap: 10}}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() => setShowForm(true)}
                                                    className="w-100 btn btn-lg text-14 btn-outline-primary font-weight-600 font-poppins rounded-lg py-2"
                                                >
                                                    {t("Estimate-new.3")}
                                                </button>
                                                <button
                                                    onClick={goToEstimationForm}
                                                    className="w-100 btn btn-lg text-14 btn-primary font-weight-600 font-poppins rounded-lg py-2"
                                                >
                                                    {t("Estimate-new.4")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                                    {showForm ? (
                                        <FormModify
                                            data={data}
                                            address={location.state}
                                            setShowForm={setShowForm}
                                        />
                                    ) : (
                                        <div
                                            className="bg-white box-shadow-3 rounded-2 p-3 h-100 d-flex flex-column align-items-center justify-content-center text-center">
                                            <img src={rent} alt="..."/>
                                            <h6 className="mt-4 mb-3 d-block text-violet-2 font-weight-bold font-poppins">
                                                {t("Estimate-new.5")} <br/> {t("Estimate-new.6")}
                                            </h6>
                                            <Link
                                                to={{
                                                    pathname: "/estimate-form",
                                                    state: {address: location.state, data: null, step: 1},
                                                }}
                                                className="btn btn-lg text-12 btn-primary font-weight-600 font-poppins rounded-lg py-2"
                                            >
                                                {t("Estimate-new.7")}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default AccommodationPage;
