import React from "react";

const HowtoCalculate = () => {

    return (<>
        <section className="services-section  mt-5 py-5">
            <div className="container mt-4">
                <div className="text-center ">
                    <h2 className="heading-2 mb-0">
                        Évaluation maison : comment le simulateur de ziaway Calcule
                        l’estimation de votre bien ?
                    </h2>
                    <p className="mb-0 mt-2">
                        Notre outil d’évaluation foncière utilise des données sur
                        l'adresse que vous souhaitez estimer telles que les spécificités
                        du bâtiment et du quartier, l’évolution du prix et de la
                        population, ainsi que les ventes récentes.
                    </p>
                    <p className="mb-0 mt-2">
                        Ensuite, nous ajustons et affinons ce prix grâce aux
                        caractéristiques que vous aurez préalablement renseigné sur le
                        bien à évaluer (type, taille, nombre de pièces, étage, travaux,
                        etc...). Cette étape est primordiale afin que nous puissions
                        calculer au plus près la valeur marchande de ce bien sur le marché
                        actuel au Québec.
                    </p>
                    <h3 className="mt-5">
                        Pour déterminer l’évaluation immobilière de votre bien, notre
                        outil évaluateur se base sur :
                    </h3>
                </div>

                <div className=" card shadow">
                    <div
                        className="row row-cols-1 row-cols-md-2 row-cols-lg-3"
                        style={{rowGap: "1.8rem"}}
                    >
                        <div className="col">
                            <div className="h-100 p-4">
                                <div
                                    style={{display: "flex", flexDirection: "column"}}
                                    className="align-items-center"
                                >
                    <span className="text-primary">
                      <i className="i-Dollar-Sign text-40"/>
                    </span>

                                    <div className="mt-2">
                                        <div className="mb-0 font-poppins text-14 text-center">
                                            Les prix réels et récents des ventes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="h-100 p-4">
                                <div
                                    style={{display: "flex", flexDirection: "column"}}
                                    className="align-items-center"
                                >
                    <span className="text-primary">
                      <i className="i-Map2 text-40"/>
                    </span>
                                    <div className="mt-2">
                                        <div className="mb-0 font-poppins text-14 text-center">
                                            Les informations géographiques
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="h-100 p-4">
                                <div
                                    style={{display: "flex", flexDirection: "column"}}
                                    className="align-items-center"
                                >
                    <span className="text-primary">
                      <i className="i-Landscape1 text-40"/>
                    </span>
                                    <div className="mt-2">
                                        <div className="mb-0 font-poppins text-14 text-center">
                                            Les caractéristiques cadastrales et foncières des biens
                                            et propriétés
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
};

export default HowtoCalculate;
