import {combineReducers} from "@reduxjs/toolkit";
import headerReducer from "./header/reducer";
import homeReducer from "./home/reducer";
import estimationReducer from "./estimation/reducer";
import userReducer from "./user/reducer";
import layoutReducer from "./layout/reducer";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'
import dashboardReducer from "./dashboard/reducer";

const reducers = combineReducers({
    header: headerReducer,
    home: homeReducer,
    estimation: estimationReducer,
    user: userReducer,
    layout: layoutReducer,
    dashboard: dashboardReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
})

export default reducers;
