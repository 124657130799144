import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/Logo_h86.png";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

const Footer = () => {
    const { t } = useTranslation();
    const {auth = {}} = useSelector((state) => state.firebase);
    return (
        <footer className="new-footer py-4 py-lg-5 bg-white">
            <div className="container">
                <div
                    className="row justify-content-between"
                    style={{ rowGap: "1.4rem" }}
                >
                    <div style={{width: 135}} className="new-footer-ziaway-icon col-12 col-md-12">
                        <div>
                            <Link className="d-inline-block mb-3" to="/homepage">
                                <img width={69} height={86} src={logo} alt=".." />
                            </Link>
                            <p className="mb-0">{t("footer.14")}</p>
                        </div>
                    </div>
                    <div className={"new-footer-sections col-12 col-md-6"}>
                        <div className=" ">
                            <div>
                                <h2 className="mb-4">{t("footer.12")}</h2>
                                <ul className="nav footer-list">
                                    <li>
                                        <Link to={auth?.uid ? "/dashboard" : "/homepage"}>
                                            {t("footer.3")}
                                        </Link>
                                    </li>
                                    <li>
                                        {/* <Link to>Offre Pro</Link> */}
                                        <a href="https://pro.ziaway.ca/" target="_blank">
                                            {t("footer.9")}
                                        </a>
                                    </li>
                                    {auth?.uid && (
                                        <li>
                                            <li className="nav-item">
                                                <Link to={"faqin"}>FAQ</Link>
                                            </li>
                                        </li>
                                    )}
                                    <li>
                                        <a href="https://blog.ziaway.ca/" target="_blank">
                                            {t("footer.4")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=" ">
                            <div>
                                <h2 className="mb-4">{t("footer.13")}</h2>
                                <ul className="nav footer-list">
                                    <li>
                                        <Link
                                            to={"/dataPolicy"}
                                        >
                                            {t("footer.10")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/termofservice"}
                                        >
                                            {t("footer.11")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to>Nous contacter</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
