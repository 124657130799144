import React from "react";
import { Link } from "react-router-dom";

const list = [
  "La cuisine",
  "La salle de bain",
  "Le sous-sol",
  "La toiture",
  "Les peintures",
  "Les portes",
  "Les fenêtres",
  "Les luminaires",
  "Les équipements",
  "L’isolation",
  "Les espaces de rangements, etc.",
];

const ImmobiliereSectionSeven = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="max-w-2 mx-auto">
          <h2 className="mb-4 text-center font-poppins font-weight-600">
            ÉVALUATION IMMOBILIÈRE : COMMENT AUGMENTER LA VALEUR MARCHANDE DE MA
            MAISON ?
          </h2>
          <p className="text-16">
            Dans le cadre d’un projet de vente ou de location, de nombreux
            propriétaires cherchent à augmenter la valeur de leur propriété afin
            d’en tirer un maximum de bénéfice. Pour augmenter la valeur
            marchande de votre bien, plusieurs solutions existent.
          </p>
          <p className="text-16">
            Dans l'univers immobilier, les projets de rénovations sont les
            solutions les plus rentables pour augmenter la valeur d’une maison,
            d’un appartement, d’un logement ou de tout bien immobilier. En
            effet, les rénovations ajoutent toujours une plus-value à votre
            propriété. Nous pouvons citer en exemple des rénovations concernant:
          </p>
          <ul
            className="mb-4 text-left pl-3 text-16"
            style={{ columnCount: 2 }}
          >
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p className="text-16">
            Par ailleurs, les rénovations écoresponsables ont le vent en poupe
            et sont de plus en plus recherchées et appréciées. Néanmoins, nous
            vous recommandons de vous renseigner préalablement sur les
            rénovations pouvant faire réellement augmenter la valeur marchande
            de votre bien selon vos objectifs.
          </p>
          <p className="text-16">
            En effet, en fonction des rénovations, certaines peuvent être
            considérées uniquement d’ordre esthétique et ne pas avoir un grand
            impact sur la valeur réelle du bien. C’est le cas, par exemple, d’un
            aménagement paysager ou le choix de matériaux aux finitions haut de
            gamme.
          </p>
          <div className="mt-5 text-center">
            <Link
              to="/homepage"
              className="btn btn-primary font-poppins rounded-2 px-4 py-3 font-weight-500 text-16"
            >
              Je fais mon estimation
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionSeven;
