import PublicHeader from "../../components/publicHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../../pages/homePage";
import Blogs from "../../pages/blogs";
import PrivacyPolicyPage from "../../pages/privacy";
import AccommodationPage from "../../pages/accommodationPage";
import EstimateFormPage from "../../pages/estimatFormPage";
import EstimationImmobiliere from "../../pages/estimationImmobiliere";
import ThankyouPage from "../../pages/estimatFormPage/steps/ThankyouPage";
import Footer from "../../components/footer";
import CookiesModal from "../../components/cookiesModal";
import TermOfService from "../../pages/termOfService";
import React from "react";

const PublicLayout = ({}) => {
  return (
    <div className={"d-flex flex-column min-vh-100"}>
      <div className={"flex-fill"}>
        <CookiesModal />
        <main>
          <PublicHeader />
          <div className={"bg-gray-100 min-vh-100 d-flex flex-column"}>
            <Routes>
              <Route path="/homepage" element={<HomePage />} exact />
              <Route path="/blog" element={<Blogs />} />
              <Route path={"/dataPolicy"} element={<PrivacyPolicyPage />} />
              <Route path={"/accommodation"} element={<AccommodationPage />} />
              <Route path={"estimate-form"} element={<EstimateFormPage />} />
              <Route
                path="/estimation-mobiliere"
                element={<EstimationImmobiliere />}
              />
              <Route path="/redirectUrl" element={<ThankyouPage />} />
              <Route path={"/termofservice"} element={<TermOfService />} />
              <Route path="*" element={<Navigate to="/homepage" replace />} />
            </Routes>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default PublicLayout;
