import {useEffect, lazy, Suspense} from "react";
import {useDispatch} from "react-redux";
import headerActions from "../../redux/header/actions";
import SectionHero from "./sectionHero";
import WhoAreWeSection from "./whoAreWeSection";
import HowtoCalculate from "./howtoCalculate";
import TrustZiaway from "./trustZiaway";
import GetFirstEstimateSection from "./getFirstEstimateSection";
const OurLatestEstimatesSection = lazy(() => import('./ourLatestEstimatesSection'))
const OurServicesSection = lazy(() => import('./ourServicesSection'))
const BlogSection = lazy(() => import('./blogSection'))
const FaqsSection = lazy(() => import('./faqsSection'))

const HomePage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: headerActions.SET_STATE,
            payload: {
                redux: true
            }
        })
    }, []);
    return (
        <section className="pb-5">
            <SectionHero />
            <WhoAreWeSection />
            <OurLatestEstimatesSection />
            <OurServicesSection />
            <BlogSection />
            <HowtoCalculate />
            <TrustZiaway />
            <GetFirstEstimateSection />
            <Suspense fallback={'...loading'}>
                <FaqsSection />
            </Suspense>
        </section>
)
}
export default HomePage;
