import React from "react";

const UserOctagonIcon = () => {
  return (
    <svg width={44} height={48} viewBox="0 0 44 48" fill="none">
      <path
        d="M42 16.4855V31.5433C42 34.0089 40.6791 36.2984 38.5437 37.5532L25.4672 45.1041C23.3318 46.3369 20.6901 46.3369 18.5327 45.1041L5.45625 37.5532C3.32086 36.3204 2 34.0309 2 31.5433V16.4855C2 14.0199 3.32086 11.7303 5.45625 10.4755L18.5327 2.9246C20.6681 1.6918 23.3098 1.6918 25.4672 2.9246L38.5437 10.4755C40.6791 11.7303 42 13.9979 42 16.4855Z"
        stroke="#5F3994"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0111 21.8134C24.8441 21.8134 27.1404 19.5168 27.1404 16.6839C27.1404 13.8511 24.8441 11.5547 22.0111 11.5547C19.1783 11.5547 16.8818 13.8511 16.8818 16.6839C16.8818 19.5168 19.1783 21.8134 22.0111 21.8134Z"
        stroke="#5F3994"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8168 34.2734C30.8168 30.3108 26.8762 27.0967 22.0111 27.0967C17.1459 27.0967 13.2053 30.3108 13.2053 34.2734"
        stroke="#5F3994"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserOctagonIcon;
