import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage = () => {
    const { i18n } = useTranslation();

    if (i18n.language === "en")
        return (
            <div className="container py-5">
                <h1 className="mb-4 text-primary">PRIVACY POLICY</h1>
                <p>(Version updated on 15/11/2022)</p>

                <section>
                    <h2 className="mb-3">ARTICLE 1: PREAMBLE</h2>
                    <p>
                        This privacy policy concerns the processing of data carried out
                        by ZIAWAY in its capacity as data controller and the processing
                        carried out and in the context of the operation of the website
                        <a
                            className="px-1 font-weight-bold"
                            href="http://www.ziaway.ca/homepage"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            http://www.ziaway.ca/homepage
                        </a>
                        <br/>
                        <br/>
                        The purpose of this privacy policy is to explain to the persons
                        concerned by the processing of data :
                        <br/>
                        <br/>
                        How their personal data is collected and processed. Personal
                        data can be considered as any data that can be related to an
                        identified or identifiable natural person.
                    </p>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`
          ▪ What are their rights in relation to the processing of their data;
          ▪ Who is responsible for the processing of the personal data collected and processed ;
          ▪ To whom this data may be transmitted;
          ▪ The site's policy on "cookies".
          `}
                    </p>
                    <p>
                        This Privacy Policy is in addition to and forms an integral part
                        of the various General Terms and Conditions of Sale and General
                        Terms and Conditions of Use which expressly refer to it.
                    </p>
                </section>
                <section>
                    <h2 className="mb-3">
                        ARTICLE 2: GENERAL PRINCIPLES OF DATA COLLECTION AND PROCESSING
                    </h2>
                    <p>
                        In accordance with the provisions of Article 5 of the European
                        Regulation 2016/679, the collection and processing of data will
                        be done in accordance with the following principles:
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`▪ Lawfulness, fairness and transparency: data can only be collected and processed fairly on the basis of the legal grounds provided by Article 6 of the GDPR.
                ▪ Limited purposes: the collection and processing of data is carried out for one or more specific purposes;
                ▪ Minimization of data collection and processing: only the data necessary for the proper execution of the objectives are collected;
                ▪ Conservation of data reduced in time: the data are kept for a limited time, freely communicated to the persons concerned.
                ▪ Integrity and confidentiality of collected and processed data: the data controller undertakes to guarantee the integrity and confidentiality of the data collected;`}
                    </p>
                </section>
                <section>
                    <h2 className="mb-3">
                        ARTICLE 3: PERSONAL DATA COLLECTED AND PROCESSED
                    </h2>

                    <p>
                        As part of its business, ZIAWAY is required to process personal
                        data. The company needs these data to :
                    </p>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`• Creation and management of User accounts
                • Provision and operation of services, in particular those related to real estate price information and determination of real estate prices
                • Follow-up of the relationship with the users
                • Transmit information requests made by users to the real estate professionals present on the site in order to contact them again
                • To enable users to be put in touch with a real estate professional present on the site, in particular through a call service, and to respond as best as possible to the users' real estate project.
                • Carry out information and communication campaigns by sending emails
                • Analyze demand and usage patterns
                • Strengthen our data security and fraud prevention capabilities
                • Respond to user feedback, requests and other inquiries to provide assistance
                • Carrying out statistical studies, analyses and audience measurements to improve the performance of the site when Internet users consent to the deposit of cookies
                • Investigate possible violations, enforce our terms or policies, or comply with any applicable law, regulation or governmental authority.`}
                    </p>
                    <h3 className="font-weight-bold">What data is collected ?</h3>
                    <p>
                        In order to achieve the purposes of processing set out above,
                        ZIAWAY may need to collect:
                    </p>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`• Name and surname
• The phone number
• The address of the property
• The email address
• A copy of the exchanges between ZIAWAY and the User
• The connection identifiers 
• Data related to the geolocation of the users of the application
• The IP address (Internet Protocol)
• Connection and navigation data  `}
                    </p>
                    <h3 className="font-weight-bold">
                        What are the legal bases for the processing?
                    </h3>
                    <p>
                        In order to clearly explain the reasons for the data treatments
                        presented, a table has been drawn up below.
                    </p>
                    <table className="table-responsive table-bordered">
                        <thead>
                        <tr>
                            <th>Treatments</th>
                            <th>Legal basis</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="border-bottom">
                            <td>Creation and management of User accounts</td>
                            <td>
                                Performance of the contract related to the services (art
                                6.b RGPD)
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Provide and operate services</td>
                            <td>
                                Performance of the contract related to the services (art
                                6.b RGPD)
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Analysis of patterns and usage requests</td>
                            <td>
                                Legitimate interest of ZIAWAY to personalize and improve
                                its services. (Art 6.a RGPD)
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Management of requests and queries</td>
                            <td>
                                Consent and legitimate interest of ZIAWAY to provide
                                assistance (art 6.a and 6.f RGPD)
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>
                                Investigation of possible violations in accordance with
                                applicable regulations
                            </td>
                            <td>Legal obligation (art 6.c RGPD)</td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Capacity building in fraud prevention</td>
                            <td>
                                Interest of ZIAWAY to ensure the security of the processed
                                data (Article 6.f RGPD)
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Analysis and measurement of the website audience</td>
                            <td>Consent to cookies (art 6.a RGPD)</td>
                        </tr>
                        <tr className="border-bottom">
                            <td>
                                Management of information and communication campaigns and
                                prospecting
                            </td>
                            <td>Consent (art 6.a RGPD)</td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Management of customer reviews</td>
                            <td>Consent (art 6.a RGPD)</td>
                        </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 4: DATA CONTROLLER</h2>
                    <h3 className="font-weight-bold">
                        A. IDENTITY AND CONTACT INFORMATION
                    </h3>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The person in charge of processing personal data is the company
                        ZIAWAY, represented by : El Kihel - Aziz
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Email: support@ziaway.ca Phone number: 581 986 2176
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Website :{" "}
                        <a href="https://ziaway.ca/homepage">
                            https://ziaway.ca/homepage
                        </a>
                    </p>
                    <br/>
                    <br/>
                    <h3 className="font-weight-bold">
                        B. OBLIGATIONS OF THE DATA CONTROLLER
                    </h3>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The data controller undertakes to protect the personal data
                        collected, not to transmit them to third parties without the
                        user's knowledge and to respect the purposes for which the data
                        were collected.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Furthermore, the data controller undertakes to notify the user
                        in the event of rectification or deletion of the data, unless
                        this would entail disproportionate formalities, costs and steps
                        for him.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In the event that the integrity, confidentiality or security of
                        the user's personal data is compromised, the data controller
                        undertakes to inform the user by any means.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 5: DATA RECIPIENTS</h2>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        ZIAWAY is the primary recipient of your personal data. However,
                        in the course of its business, the company may share your data
                        with certain collaborators, such as its real estate professional
                        partners, affiliated sites or Applications, service providers
                        (customer service, marketing service, payment services,
                        commercial analysis, public relations, network advertisers,
                        distribution of surveys or prize draws) and other third parties
                        involved in the sale of your property (notary, diagnosticians,
                        etc...). Employees who have access to personal information are
                        not permitted to share it or use it for any other purpose not
                        related to the performance of their duties.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Where you have expressly agreed, we may share some of your
                        personal information with our business partners so that you may
                        receive promotional offers from them. They may also, where you
                        have consented, collect some of your personal data through
                        cookies.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        If we sell or transfer our business or a portion of it, and your
                        personal information relates to that portion sold or
                        transferred, or if we merge with another company, we may share
                        your personal information with the new owner of the company or
                        our merger partner. If you post content to our Digital
                        Properties or otherwise provide it in connection with the use of
                        a Service, you do so at your own risk. We cannot control the
                        actions of other users or members of the public who may access
                        your information and content. You understand and acknowledge
                        that, even after removal by you or us, copies of your
                        information may remain visible on archived or cached pages, or
                        that a third party may have copied or stored such content.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Finally, please note that where required by law, certain
                        authorized third parties (such as relevant courts, mediators,
                        attorneys, bailiffs and other court officials) may have access
                        to your personal data.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 6: DATA RETENTION </h2>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The length of time we retain your personal data is limited. We
                        keep it only as long as necessary to provide our Services and to
                        comply with our legal or contractual obligations to you, to
                        resolve disputes and to enforce our agreements.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In any event, the retention period will not exceed a maximum of
                        36 months (3 years) from the time the company has not heard from
                        you. This is particularly the case when you have not responded
                        to the last solicitation or when you have not contacted the
                        company for some time.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        With regard to the retention periods for data recorded by
                        cookies, we invite you to refer to "Article 10; Cookies and
                        other "tracers" of this Policy.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 7: DATA TRANSFER </h2>

                    {/* <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                We are personally committed to keeping your data in Canada
              </p> */}
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        To the extent possible, we are personally committed to keeping
                        your data in Canada. However, due to the location of some of our
                        partners, we may need to transfer some of your data to Third
                        Countries. This may be the case, for example, to Third Countries
                        for which Canada has not made an "adequate protection" decision.
                        In such a case, Ziaway ensures that the transfer is made in
                        accordance with the applicable regulations in order to guarantee
                        an adequate level of protection for the privacy and family life
                        of the persons concerned.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">
                        ARTICLE 8: DATA SUBJECT'S RIGHTS REGARDING DATA PROCESSING
                    </h2>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In accordance with the regulations, the persons concerned by the
                        processing of their personal data have rights.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In order for ZIAWAY, as data controller, to comply with requests
                        to exercise rights, data subjects are required to provide: their
                        first and last names as well as their email address, and to
                        prove their identity by providing a copy of an identification
                        document belonging to them.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The data controller is obliged to respond to requests to
                        exercise the rights of data subjects within a maximum of 30
                        (thirty) days from receipt of the request.
                    </p>
                    <br/>
                    <br/>

                    <h3 className="font-weight-bold">
                        What are the rights on data processing?
                    </h3>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        1) Right of access, rectification and deletion
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The persons concerned can read, update, rectify or request the
                        deletion of their data. They can directly on the ZIAWAY
                        application to rectify or delete their data through the button
                        dedicated to this purpose.
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        2) Right to limit and object to data processing
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Data subjects have the right to request the restriction or to
                        object to the processing of their data, unless there are
                        compelling legitimate reasons that override their interests and
                        rights and freedoms.
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        3) Right not to be subjected to a decision based exclusively on
                        an automated process
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Data subjects have the right not to be subject to a decision
                        based exclusively on an automated process if the decision
                        produces legal effects concerning them, or significantly affects
                        them in a similar way.
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        4) Right to portability
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Data subjects have the right to receive personal data that they
                        have provided, or that have been generated by their activity or
                        that have been observed. This data must be provided in a
                        structured, commonly used and machine-readable format.
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        5) Right to determine the fate of data after death
                    </p>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Data subjects can organize themselves and define the fate of
                        their collected and processed data if they die.
                    </p>

                    <h3 className="font-weight-bold">
                        How do I exercise my rights?{" "}
                    </h3>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The rights on data processing can be exercised at any time by
                        contacting ZIAWAY at one of the following addresses :
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`• support@ziaway.ca
• 104-600 avenue wilfrid laurier, Quebec, QC, G1R 2L5
`}
                        <br/>
                        <br/>
                        <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                            In case of lack of response or refusal of response from ZIAWAY
                            to requests to exercise the rights of data subjects, data
                            subjects have the opportunity to file a complaint with the
                            Office of the Privacy Commissioner of Canada.
                        </p>
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 9 - DATA SECURITY</h2>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        ZIAWAY respects the RGPD in terms of security and
                        confidentiality of your data. The company implements all the
                        technical and organizational measures necessary to ensure the
                        security of the processing of personal data and the
                        confidentiality of the data recorded.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The hosting service of our Digital Properties provides us with
                        the online platform that allows us to provide the Service to
                        you. Your data may be stored through our hosting provider's data
                        storage applications and databases. Our hosting provider stores
                        your personal information on secure, firewall-protected servers
                        and provides secure HTTPS access to most of its services.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In addition, ZIAWAY encourages the use of strong passwords and
                        recommends that you do not provide any sensitive information
                        that you believe could cause substantial or irreparable harm, in
                        any medium.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        As such, ZIAWAY adopts precautions, in light of the nature of
                        the data and the risks presented by the processing to preserve
                        the security and, in particular, to prevent data from being
                        distorted, damaged, or accessed by unauthorized third parties.
                        The persons concerned are nevertheless informed that ZIAWAY and
                        its host cannot guarantee and does not guarantee the absolute
                        protection and security of personal information collected.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        With respect to online payments, all direct payment gateways
                        offered by our Digital Properties host and used by us comply
                        with the standards established by PCI-DSS, as administered by
                        the PCI Security Standards Council. The PCI-DSS requirements
                        help ensure the secure processing of credit card information by
                        our company and its service providers, including physical,
                        electronic and procedural measures.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 10 - COOKIES AND OTHER TRACERS</h2>

                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Note that the ZIAWAY Site https://ziaway.ca cookies and similar
                        technologies to enable third parties to automatically collect
                        information about you to improve the way you navigate our
                        Digital Properties, to improve their performance and personalize
                        your experience, and for security and fraud prevention purposes.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The purpose of this section is to clarify the circumstances
                        under which cookies may be sent to the computers of visitors to
                        the Site.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        A cookie is a small text file issued by a website's server and
                        written to the hard drive of your computer or mobile device. The
                        cookie contains a unique code that allows us to recognize your
                        browser during your visit to our website or during future
                        visits. Below is a table summarizing the list of cookies used on
                        the website as well as their purpose and lifespan.
                    </p>
                    <br/>
                    <br/>
                    <table className="table-responsive table-bordered">
                        <thead>
                        <tr>
                            <th>Cookie names</th>
                            <th>Categories</th>
                            <th>Goals</th>
                            <th>Duration</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="border-bottom">
                            <td>_zcsr_tmp</td>
                            <td>Necessary cookies</td>
                            <td>
                                This cookie is necessary for the login function on the
                                site.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>algoliasearch-client-js</td>
                            <td>Necessary cookies</td>
                            <td>
                                This cookie is Necessary to optimize the search bar
                                function of the website. The cookie ensures accurate and
                                fast search results.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>CONSENT</td>
                            <td>Necessary cookies</td>
                            <td>
                                This cookie is used to detect whether the visitor has
                                accepted the marketing category in the cookie banner. This
                                cookie is necessary for the website's compliance with the
                                GDPR.
                            </td>
                            <td>2 years</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>firebaseLocalStorageDb#firebaseinstallations-store</td>
                            <td>Necessary cookies</td>
                            <td>
                                This cookie is used to validate the user's request and
                                maintain session information
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>hex (10)</td>
                            <td>Necessary cookies</td>
                            <td>
                                Records which server group serves the visitor. This is
                                used in the context of load balancing to optimize the user
                                experience.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>i18nextLng</td>
                            <td>Preference cookies</td>
                            <td>
                                Determines the visitor's preferred language. Allows the
                                website to set the preferred language when the visitor
                                enters again.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>LS_CSRF_TOKEN</td>
                            <td>Preference cookies</td>
                            <td>
                                Identifies the visitor across devices and visits, in order
                                to optimize the website's dialog box function.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>#_zldp</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Records data about the behavior of visitors to the
                                website. This is used for internal analysis and
                                optimization of the site.
                            </td>
                            <td>399 days</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>#_zldt</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Defines an identifier for the visitor. This allows to
                                count the number of specific visitors on the site
                            </td>
                            <td>1 day</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>@firebase/performance/config</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Records the speed and performance of the site. This
                                feature can be used in context with statistics and load
                                balancing.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>@firebase/performance/configexpire</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Records site speed and performance. This feature can be
                                used in context with statistics and load balancing
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>_ga</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Stores a unique identifier used to generate statistical
                                data on how the visitor uses the site.
                            </td>
                            <td>399 days</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>_ga_#</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Used by Google Analytics or collect data on the number of
                                times a user has visited the website as well as the dates
                                of the first and most recent visit.
                            </td>
                            <td>399 days</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>siqlsdb</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Sets a unique identifier for the session. This allows the
                                website to obtain data on visitor behavior for statistical
                                purposes.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>utsdb</td>
                            <td>Statistical Cookies</td>
                            <td>
                                Records data about the behavior of visitors to the
                                website. This is used for internal analysis and
                                optimization of the site.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>uesign</td>
                            <td>Marketing cookies</td>
                            <td>
                                Defines an identifier for the specific user. This allows
                                the website to target the user with relevant offers via
                                its conversation feature.
                            </td>
                            <td>30 days</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>VISITOR_INFO1_LIVE</td>
                            <td>Marketing cookies</td>
                            <td>
                                Attempts to estimate user bandwidth on pages with embedded
                                YouTube videos.
                            </td>
                            <td>179 days</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>YSC</td>
                            <td>Marketing cookies</td>
                            <td>
                                By registering a unique identifier to keep statistics on
                                YouTube videos viewed by the user.
                            </td>
                            <td>Until the end of the session</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>
                                ytidb::LAST_RESULT_ENTRY_KEY youtube.com HTML Persistent{" "}
                            </td>
                            <td>Marketing cookies</td>
                            <td>
                                Stores the user's video playback preferences for embedded
                                YouTube videos
                            </td>
                            <td>Until the end of the session</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Users of the Site are informed that they can oppose the use of
                        cookies from their browser settings. The configuration of each
                        browser is different. Thus, it is up to them to follow the
                        instructions of the editor of their browser. Below are the links
                        to the instructions for setting cookies according to the
                        browser:
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        {`• If you use Chrome: https://support.google.com/chrome/answer/95647
            • If you are using Firefox : https://support.mozilla.org/fr/kb/effacer-cookies-donnees-sitefirefox?redirectslug=effacer-les-cookies-pour-supprimer-les-information&redirectlocale=fr
            • If you use Safari : https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
            • If you use Microsoft Edge: https://support.microsoft.com/fr-fr/microsoftedge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
`}
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In case of refusal of the recording of cookies, the browsing
                        experience on the Site may be limited. Therefore, ZIAWAY
                        disclaims any responsibility for the consequences related to the
                        degraded functioning of the site, resulting from the refusal of
                        cookies.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 11 - MINORS </h2>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        In any case, ZIAWAY does not voluntarily collect personal data
                        from minor users.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Parents, or guardians, who discover that their child has
                        provided personal data without their consent are requested to
                        contact ZIAWAY by e-mail at support@ziaway.ca or by mail at
                        104-600 avenue wilfrid laurier, Quebec, QC G1R 2L5 indicating
                        their full name, e-mail address and proving their identity by
                        sending a copy of an identification document that is their own.
                    </p>
                </section>

                <section>
                    <h2 className="mb-3">ARTICLE 12 - CONTACT</h2>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        All questions, comments and requests regarding this privacy
                        policy or any other requests will be well received. They should
                        be sent by e-mail to support@ziaway.ca or by mail to 104-600
                        avenue wilfrid laurier, Quebec, QC G1R 2L5
                    </p>
                </section>

                <section>
                    <h4 className="mb-3">
                        SECTION 13 - REVISION OF THE PRIVACY POLICY
                    </h4>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        This privacy policy is available at any time on the ZIAWAY
                        website
                        <a href="https://ziaway.ca/">https://ziaway.ca/</a>
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        We reserve the right to modify it in order to guarantee our
                        conformity in time with the evolution of the texts.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        Therefore, we invite you to come and consult this privacy policy
                        regularly in order to be informed of the latest changes that
                        will be made to it.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        However, in the event of a substantial change to this policy,
                        the user will be informed.
                    </p>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        The user is informed that this privacy policy was last updated
                        on 19/11/2022.
                    </p>
                </section>
            </div>
        );

    return (
        <div className="container py-5">
            <h1 className="mb-4 text-primary">Politique de confidentialité</h1>
            <p>(Version mise à jour le 15/11/2022)</p>

            <section>
                <h2 className="mb-3">ARTICLE 1 : PRÉAMBULE</h2>
                <p>
                    Cette politique de confidentialité concerne les traitements de
                    données effectués par ZIAWAY en sa qualité de responsable de
                    traitement et les traitements effectués dans le cadre de
                    l’exploitation du site internet
                    <a
                        className="px-1 font-weight-bold"
                        href="https://ziaway.ca/homepage"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://ziaway.ca/homepage
                    </a>
                    <br/>
                    <br/>
                    La présente politique de confidentialité a pour but d’exposer aux
                    personnes concernées par les traitements de données :
                    <br/>
                    <br/>
                    La manière dont sont collectées et traitées leurs données à
                    caractère personnel. Les données personnelles peuvent être
                    assimilées comme toutes données pouvant se rapporter à une
                    personne physique identifiée ou identifiable.
                </p>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`
              ▪ Quels sont leurs droits par rapport aux traitements de leurs données ;
              ▪ Qui est responsable du traitement des données à caractère personnel collectées et traitées ;
              ▪ A qui ces données peuvent être transmises ;
              ▪ La politique du site en matière de « cookies ».`}
                </p>
                <p>
                    La présente Politique de confidentialité vient en complément des
                    différentes Conditions Générales de Vente et Conditions Générales
                    d’Utilisation qui y renvoient expressément et en fait partie
                    intégrante.
                </p>
            </section>
            <section>
                <h2 className="mb-3">
                    ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE
                    TRAITEMENT DE DONNÉES
                </h2>
                <p>
                    Conformément aux dispositions de l’article 5 du Règlement européen
                    2016/679, la collecte et le traitement des données se fera
                    conformément aux principes suivants :
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`▪ Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées loyalement que sur le fondement de bases légales prévues par l’article 6 du RGPD.
                ▪ Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés ;
                ▪ Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs sont collectées ;
                ▪ Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, librement communiquées aux personnes concernées.
                ▪ Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s’engage à garantir l’intégrité et la confidentialité des données collectées ;`}
                </p>
            </section>
            <section>
                <h2 className="mb-3">
                    ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES
                </h2>

                <p>
                    Dans le cadre de son activité, ZIAWAY est amenée à traiter des
                    données personnelles. La société a notamment besoin de ces données
                    pour :
                </p>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`• La création et la gestion des comptes d’Utilisateurs
                • La fourniture et l’exploitation des services, en particulier ceux liées à l’information sur les prix de l’immobilier et la détermination des prix des biens immobiliers
                • Le suivi de la relation avec les utilisateurs
                • Transmettre les demandes de renseignements émises par les utilisateurs aux professionnels de l’immobilier présents sur le site afin de les recontacter
                • Permettre la mise en relation des utilisateurs avec un professionnel de l’immobilier présent sur le site, et notamment par l’intermédiaire d’un service d’appel, et de répondre au mieux au projet immobiliers des utilisateurs.
                • Mener des campagnes d’information et de communication par l’envoi de mails
                • Analyser les schémas de demandes et d’utilisations
                • Renforcer nos capacités en matière de sécurité des données et de prévention de la fraude
                • Répondre aux réactions des utilisateurs, demandes et autres requêtes afin de leur apporter assistance
                • Réalisation d’études statistiques, d’analyses et de mesures d’audience pour améliorer la performance du site lorsque les internautes consentent aux dépôts de cookies
                • Enquêter sur les éventuelles violations, faire respecter nos conditions ou nos politiques, ou se conformer à une loi, une réglementation ou une autorité gouvernementale applicable.`}
                </p>
                <h3 className="font-weight-bold">
                    Quelles sont les données collectées ?
                </h3>
                <p>
                    Afin de réaliser les finalités de traitement énoncées ci-dessus,
                    ZIAWAY peut être amenés à collecter :
                </p>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`• Le nom et prénoms
                • Le numéro de téléphone
                • L’adresse de la propriété
                • L’adresse courriel
                • Une copie des échanges entre ZIAWAY entre l’Utilisateur
                • Les identifiants de connexion
                • Données relatives à la géolocalisation des utilisateurs de l’application
                • L’adresse IP (Internet Protocol)
                • Des données de connexion et de navigation`}
                </p>
                <h3 className="font-weight-bold">
                    Sur quelles bases légales les traitements sont fondés ?
                </h3>
                <p>
                    Afin d’expliquer clairement sur quelles raisons se basent les
                    traitements de données présentés, un tableau a été dressé
                    ci-dessous.
                </p>
                <table className="table-responsive table-bordered">
                    <thead>
                    <tr>
                        <th>Traitements</th>
                        <th>Bases légales</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="border-bottom">
                        <td>Création et gestion des comptes d’Utilisateurs</td>
                        <td>
                            Exécution du contrat liés aux prestations (art 6.b RGPD)
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Fournir et exploiter les services</td>
                        <td>
                            Exécution du contrat liés aux prestations (art 6.b RGPD)
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Analyse des schémas et des demandes d’utilisation</td>
                        <td>
                            Intérêt légitime de ZIAWAY pour personnaliser et améliorer
                            ses services. (Art 6.a RGPD)
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Gestion des demandes et requêtes</td>
                        <td>
                            Consentement et intérêt légitime de ZIAWAY à porter
                            assistance (art 6.a et 6.f RGPD)
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td>
                            Enquêtes sur les éventuelles violations en conformité avec
                            la réglementation applicable{" "}
                        </td>
                        <td>Obligation légale (art 6.c RGPD)</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>
                            Renforcement des capacités en matière de prévention de la
                            fraude
                        </td>
                        <td>
                            Intérêt de ZIAWAY à assurer la sécurité des données traitées
                            (article 6.f RGPD){" "}
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Analyse et mesure d’audience du site Internet</td>
                        <td>Consentement aux cookies (art 6.a RGPD)</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>
                            Gestion des campagnes d’information et de communication et
                            de la prospection
                        </td>
                        <td>Consentement (art 6.a RGPD)</td>
                    </tr>
                    <tr className="border-bottom">
                        <td>Gestion des avis clients</td>
                        <td>Consentement (art 6.a RGPD)</td>
                    </tr>
                    </tbody>
                </table>
            </section>

            <section>
                <h2 className="mb-3">
                    ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES
                </h2>
                <h3 className="font-weight-bold">A. IDENTITÉ ET COORDONNÉES</h3>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Le responsable du traitement des données à caractère personnel est
                    la société ZIAWAY, représentée par : El Kihel - Aziz
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Courriel : support@ziaway.ca Numéro de téléphone : 581 986 2176
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Site internet :{" "}
                    <a href="https://ziaway.ca/homepage">
                        https://ziaway.ca/homepage
                    </a>
                </p>
                <br/>
                <br/>
                <h3 className="font-weight-bold">
                    B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES
                </h3>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Le responsable du traitement s’engage à protéger les données à
                    caractère personnel collectées, à ne pas les transmettre à des
                    tiers sans que l’utilisateur n’en ait été informé et à respecter
                    les finalités pour lesquelles ces données ont été collectées.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    De plus, le responsable du traitement des données s’engage à
                    notifier l’utilisateur en cas de rectification ou de suppression
                    des données, à moins que cela n’entraîne pour lui des formalités,
                    coûts et démarches disproportionnées.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Dans le cas où l’intégrité, la confidentialité ou la sécurité des
                    données à caractère personnel de l’utilisateur est compromise, le
                    responsable du traitement s’engage à informer l’utilisateur par
                    tout moyen.
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 5 : DESTINATAIRES DES DONNÉES</h2>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    ZIAWAY est le principal destinataire de vos données personnelles.
                    Toutefois, dans le cadre de son activité, la société est
                    susceptible de partager vos données avec certains collaborateurs,
                    tel que ses partenaires professionnels de l’immobilier, sites
                    affiliés ou Applications, prestataires de services (service
                    client, service marketing, services de paiement, analyse
                    commerciale, relation publique, annonceurs réseau, diffusion
                    d’enquêtes ou tirage au sort) et autres tiers intervenant pour la
                    cession de votre bien (notaire, diagnostiqueurs, etc…). Les
                    collaborateurs ayant accès aux informations personnelles ne sont
                    pas autorisés à les partager ou à les utiliser pour une autre
                    finalité qui n’est pas liée à l’accomplissement de leurs
                    fonctions.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Lorsque vous l’avez expressément accepté, nous pouvons transmettre
                    certaines de vos informations personnelles à nos partenaires
                    commerciaux pour que vous puissiez recevoir des offres
                    promotionnelles de leur part. Ces derniers peuvent également,
                    lorsque vous le consentez, recueillir certaines de vos données
                    personnelles par l’intermédiaire des cookies.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Si nous venons à vendre ou céder notre activité ou le cas échéant
                    une partie de celle-ci, et que vos données personnelles se
                    rapportent à cette partie vendue ou cédée, ou si nous fusionnons
                    avec une autre entreprise, nous pouvons être amenés à partager vos
                    données personnelles avec le nouveau propriétaire de l’entreprise
                    ou notre partenaire de fusion. Si vous envoyez un contenu sur nos
                    Propriétés numériques ou que vous le fournissez de toute autre
                    manière dans le cadre de l'utilisation d'un Service, vous le
                    faites à vos propres risques. Nous ne pouvons pas contrôler les
                    actions des autres utilisateurs ou des membres du public qui
                    peuvent accéder à vos informations et à votre contenu. Vous
                    comprenez et reconnaissez que, même après leur retrait de votre
                    part ou de la nôtre, des copies de vos informations peuvent rester
                    visibles sur des pages archivées ou mises en cache, ou qu’un tiers
                    a pu copier ou stocker ce contenu.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Enfin, veuillez noter que dans le cas où la loi l’impose, certains
                    tiers autorisés (juridictions concernées, médiateurs, avocats,
                    huissiers et autres auxiliaires de justice) peuvent avoir accès à
                    vos données personnelles.
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 6 : CONSERVATION DES DONNÉES </h2>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    La durée de conservation de vos données personnelles est limitée.
                    Nous les conservons uniquement le temps nécessaire pour fournir
                    nos Services ainsi que nous conformer à nos obligations légales ou
                    contractuelles envers vous, résoudre les litiges et faire
                    respecter nos accords.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    En tout état de cause, la durée de conservation des données
                    n’excède pas un délai maximum de 36 mois (3 ans) à compter du
                    moment où la société n’a plus de nouvelles de votre part. Cela est
                    notamment le cas lorsque vous n’avez pas répondu à la dernière
                    sollicitation ou lorsque vous n’avez pas contacté la société
                    depuis un certain temps. S’agissant des durées de conservation des
                    données enregistrées par les cookies, nous vous invitons à vous
                    reporter à « l’article 10 ; Cookies et autres traceurs » de la
                    présente Politique de confidentialité.
                </p>
                <br/>
                {/* <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              S’agissant des durées de conservation des données enregistrées par
              les cookies, nous vous invitons à vous reporter à « l’article 10;
              Cookies et autres « traceurs » de la présente Politique.
            </p> */}
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 7 : TRANSFERT DE DONNÉES </h2>

                {/* <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Nous nous engageons personnellement à conserver vos données au
              Canada
            </p> */}
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Dans la mesure du possible, nous nous engageons personnellement à
                    conserver vos données au Canada. Toutefois, en raison de la
                    localisation de certains de nos partenaires, nous pouvons être
                    amenés à transférer certaines de vos données vers des Pays Tiers.
                    Cela peut notamment être le cas vers des Pays Tiers pour lesquels
                    le Canada n’a pas pris une décision de « protection adéquate ».
                    Dans un tel cas, Ziaway s’assure que ce transfert soit effectué en
                    conformité avec la réglementation applicable afin de garantir un
                    niveau de protection adéquat pour le respect de la vie privée et
                    familiale des personnes concernées.
                </p>
            </section>

            <section>
                <h2 className="mb-3">
                    ARTICLE 8 : DROITS DE LA PERSONNE CONCERNEE EN MATIÈRE DE
                    TRAITEMENT DES DONNÉES{" "}
                </h2>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Conformément à la réglementation, les personnes concernées par le
                    traitement de leurs données personnelles disposent de droits.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Afin que ZIAWAY, en sa qualité de responsable du traitement des
                    données fasse droit à aux demandes d’exercices de droits, les
                    personnes concernées sont tenues de lui communiquer : leurs
                    prénoms et nom ainsi que leur adresse courriel, et de prouver leur
                    identité en communiquant une copie d’une pièce d’identité leur
                    appartenant.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Le responsable du traitement est tenu de répondre aux demandes
                    d’exercices des droits des personnes concernées dans un délai de
                    30 (trente) jours maximums à compter de la réception de la
                    demande.
                </p>
                <br/>
                <br/>

                <h3 className="font-weight-bold">
                    Quels sont les droits sur le traitement des données ?
                </h3>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    1) Droit d’accès, de rectification et droit à l’effacement
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les personnes concernées peuvent prendre connaissance, mettre à
                    jour, rectifier ou demander la suppression des données les
                    concernant. Elles ont la possibilité directement sur l’application
                    ZIAWAY de rectifier ou encore de supprimer leurs données grâce au
                    bouton dédié à cet effet.
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    2) Droit à la limitation et à l’opposition du traitement des
                    données
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les personnes concernées ont le droit de demander la limitation ou
                    de s’opposer au traitement de leurs données, excepté s’il existe
                    des motifs légitimes et impérieux pouvant prévaloir sur leurs
                    intérêts et leurs droits et libertés.
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    3) Droit de ne pas faire l’objet d’une décision fondée
                    exclusivement sur un procédé automatisé
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les personnes concernées ont le droit de ne pas faire l’objet
                    d’une décision fondée exclusivement sur un procédé automatisé si
                    la décision produit des effets juridiques les concernant, ou les
                    affecte de manière significative de façon similaire.
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    4) Droit à la portabilité
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les personnes concernées ont le droit de recevoir les données
                    personnelles qu’elles ont fournies, ou qui ont été générées par
                    leur activité ou qui ont été observées. Celles-ci doivent être
                    communiquées dans un format structuré, couramment utilisé et
                    lisible par la machine.
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    5) Droit de déterminer le sort des données après la mort
                </p>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les personnes concernées peuvent s’organiser et définir le sort de
                    leurs données collectées et traitées s’il décède.
                </p>

                <h3 className="font-weight-bold">Comment exercer les droits ?</h3>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les droits sur le traitement des données peuvent être exercer à
                    tout moment en contactant ZIAWAY à l’une des adresses suivantes :
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`• support@ziaway.ca
                • 104-600 avenue wilfrid laurier, Quebec, QC, G1R 2L5`}
                    <br/>
                    <br/>
                    <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                        En cas d’absence de réponses ou de refus de réponse de la part
                        de ZIAWAY aux demandes d’exercice des droits des personnes
                        concernées, les personnes concernées ont la possibilité de
                        porter réclamation en saisissant le Commissariat à la vie privée
                        du Canada.
                    </p>
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 9 – SÉCURITÉ DES DONNÉES</h2>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    ZIAWAY respecte le RGPD en matière de sécurité et de
                    confidentialité de vos données. La société met en œuvre toutes les
                    mesures techniques et organisationnelles nécessaires afin
                    d’assurer la sécurité des traitements de données à caractère
                    personnel et la confidentialité des données enregistrées.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Le service d'hébergement de nos Propriétés numériques met à notre
                    disposition la plateforme en ligne qui nous permet de vous fournir
                    le Service. Vos données peuvent être stockées par l'intermédiaire
                    des applications de stockage de données, de bases de données de
                    notre fournisseur d'hébergement. Ce dernier stocke vos
                    informations personnelles sur des serveurs sécurisés protégé par
                    un pare-feu et offre un accès sécurisé HTTPS à la plupart de ses
                    services.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    En outre, ZIAWAY encourage à définir des mots de passe forts et
                    recommande de ne fournir en aucun cas des informations sensibles
                    dont les personnes pensent que leur divulgation pourrait causer un
                    préjudice substantiel ou irréparable, sur quelques supports que ce
                    soit.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    À ce titre, ZIAWAY adopte les précautions, au regard de la nature
                    des données et des risques présentés par les traitements pour en
                    préserver la sécurité et, notamment, empêcher que les données
                    soient déformées, endommagées, ou que des tiers non autorisés y
                    aient accès. Les personnes concernées sont tout de même informées
                    que ZIAWAY et son hébergeur ne peut garantir et ne garantit pas la
                    protection et la sécurité absolues des informations personnelles
                    collectées.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Concernant les paiements en ligne, toutes les passerelles de
                    paiement direct proposées par l'hébergeur de nos Propriétés
                    numériques et que nous utilisons respectent les normes établies
                    par PCIDSS, telles que gérées par le PCI Security Standards
                    Council. Les exigences PCI-DSS contribuent à garantir le
                    traitement sécurisé des informations relatives aux cartes de
                    crédit par notre société et ses fournisseurs de services, y
                    compris les mesures physiques, électroniques et procédurales.
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 10 – COOKIES ET AUTRES TRACEURS</h2>

                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Il est à noter que le Site de ZIAWAY https://ziaway.ca des cookies
                    et technologies similaires en vue de permettre à des tiers de
                    recueillir automatiquement des informations vous concernant afin
                    d’améliorer la manière dont vous naviguez sur nos Propriétés
                    numériques, d’améliorer les performances de celles-ci et de
                    personnaliser votre expérience, ainsi qu’à des fins de sécurité et
                    de prévention de la fraude.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Le présent article a pour but de clarifier les circonstances dans
                    lesquelles des cookies peuvent être envoyés sur les ordinateurs
                    des visiteurs du Site.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Un cookie est un petit fichier texte émis par le serveur d'un site
                    web et qui est inscrit sur le disque dur de votre ordinateur ou de
                    votre appareil mobile. Le cookie contient un code unique
                    permettant de reconnaître votre navigateur lors de votre visite
                    sur notre site web ou lors de futures visites répétées.
                    Ci-dessous, un tableau récapitulant la liste des cookies utilisée
                    sur le site web ainsi que leur finalité et leur durée de vie.
                </p>
                <br/>
                <br/>
                <table className="table-responsive table-bordered">
                    <thead>
                    <tr>
                        <th>Noms cookie</th>
                        <th>Catégories</th>
                        <th>Finalités</th>
                        <th>Durées</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="border-bottom">
                        <td>_zcsr_tmp</td>
                        <td>Cookies nécessaires</td>
                        <td>
                            Ce cookie est nécessaire pour la fonction de connexion sur
                            le site.{" "}
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>algoliasearch-client-js</td>
                        <td>Cookies nécessaires</td>
                        <td>
                            Ce cookie est Nécessaire pour optimiser la fonction de barre
                            de recherche du site Web. Le cookie garantit des résultats
                            de recherche précis et rapides.
                        </td>
                        <td>JJusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>CONSENT</td>
                        <td>Cookies nécessaires</td>
                        <td>
                            Ce cookie est utilisé pour détecter si le visiteur a accepté
                            la catégorie marketing dans la bannière de cookie. Ce cookie
                            est nécessaire pour la conformité du site Web avec le RGPD.
                        </td>
                        <td>2 ans</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>firebaseLocalStorageDb#firebaseinstallations-store</td>
                        <td>Cookies nécessaires</td>
                        <td>
                            Ce cookie est utilisé pour valider la demande de
                            l'utilisateur et maintenir les informations de session
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>hex (10)</td>
                        <td>Cookies nécessaires</td>
                        <td>
                            Enregistre quel groupe de serveurs sert le visiteur. Ceci
                            est utilisé dans le contexte de l'équilibrage de charge afin
                            d'optimiser l'expérience utilisateur.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>i18nextLng</td>
                        <td>Cookies de préférences</td>
                        <td>
                            Détermine la langue préférée du visiteur. Permet au site Web
                            de définir la langue préférée lors de la nouvelle entrée du
                            visiteur.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>LS_CSRF_TOKEN</td>
                        <td>Cookies de préférences</td>
                        <td>
                            Identifie le visiteur à travers des appareils et des
                            visites, afin d'optimiser la fonction de boîte de dialogue
                            du site Web.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>#_zldp</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Enregistre des données sur le comportement des visiteurs sur
                            le site Web. Ceci est utilisé pour l'analyse interne et
                            l'optimisation du site.
                        </td>
                        <td>399 jours</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>#_zldt</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Définit un identifiant pour le visiteur. Cela permet de
                            compter le nombre de visiteurs spécifiques sur le site
                        </td>
                        <td>1 jour</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>@firebase/performance/config</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Enregistre la vitesse et la performance du site. Cette
                            fonction peut être utilisée en contexte avec des
                            statistiques et un équilibrage de charge.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>@firebase/performance/configexpire</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Enregistre la vitesse et la performance du site. Cette
                            fonction peut être utilisée en contexte avec des
                            statistiques et un équilibrage de charge
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>_ga</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Enregistre un identifiant unique utilisé pour générer des
                            données statistiques sur la façon dont le visiteur utilise
                            le site.
                        </td>
                        <td>399 jours</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>_ga_#</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Utilisé par Google Analytics ou recueillir des données sur
                            le nombre de fois qu'un utilisateur a visité le site web
                            ainsi que les dates de la première et de la plus récente
                            visite.
                        </td>
                        <td>399 jours</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>siqlsdb</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Définit un identifiant unique pour la session. Cela permet
                            au site Web d'obtenir des données sur le comportement des
                            visiteurs à des fins statistiques.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>utsdb</td>
                        <td>Cookies Statistiques</td>
                        <td>
                            Enregistre des données sur le comportement des visiteurs sur
                            le site Web. Ceci est utilisé pour l'analyse interne et
                            l'optimisation du site.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>uesign</td>
                        <td>Cookies marketing</td>
                        <td>
                            Définit un identifiant pour l'utilisateur spécifique. Cela
                            permet au site web de cibler l'utilisateur avec des offres
                            pertinentes via sa fonctionnalité de conversation.
                        </td>
                        <td>30 jours</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>VISITOR_INFO1_LIVE</td>
                        <td>Cookies marketing</td>
                        <td>
                            Tente d'estimer la bande passante des utilisateurs sur des
                            pages avec des vidéos YouTube intégrées.
                        </td>
                        <td>179 jours</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>YSC</td>
                        <td>Cookies marketing</td>
                        <td>
                            En registre un identifiant unique pour conserver des
                            statistiques sur les vidéos de YouTube vues par
                            l'utilisateur.
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>

                    <tr className="border-bottom">
                        <td>
                            ytidb::LAST_RESULT_ENTRY_KEY youtube.com HTML Persistent{" "}
                        </td>
                        <td>Cookies marketing</td>
                        <td>
                            Stocke les préférences de lecture vidéo de l'utilisateur
                            pour les vidéos YouTube incorporées
                        </td>
                        <td>Jusqu’à la fin de la session</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les utilisateurs du Site sont informés qu’ils peuvent s’opposer à
                    l’utilisation des cookies depuis les paramètres de leur
                    navigateur. La configuration de chaque navigateur est différente.
                    Ainsi, il leur appartient de suivre les instructions de l’éditeur
                    de leur navigateur. Ci-dessous, les liens vers les instructions de
                    paramétrages des cookies en fonction des navigateurs :
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    {`• Si vous utilisez Chrome : https://support.google.com/chrome/answer/95647
                • Si vous utilisez Firefox : https://support.mozilla.org/fr/kb/effacer-cookies-donnees-sitefirefox?redirectslug=effacer-les-cookies-pour-supprimer-les-information&redirectlocale=fr
                • Si vous utilisez Safari : https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
                • Si vous utilisez Microsoft Edge : https://support.microsoft.com/fr-fr/microsoftedge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
`}
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    En cas de refus de l'enregistrement des cookies, l'expérience de
                    navigation sur le Site peut être limitée. De ce fait, ZIAWAY
                    décline toute responsabilité pour les conséquences liées au
                    fonctionnement dégradé du site, résultant du refus de cookies.
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 11 – MINEURS</h2>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    En tout état de cause, ZIAWAY ne collecte pas de manière
                    volontaire des données à caractère personnel auprès des
                    utilisateurs mineurs.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Les parents, ou le cas échéants les tuteurs, qui s’aperçoivent que
                    leur enfant a fourni des données personnelles sans même obtenir
                    leur consentement sont priés de contacter ZIAWAY par courrier
                    électronique à l’adresse e-mail ; support@ziaway.ca ou par
                    courrier postal à l’adresse ; 104-600 avenue wilfrid laurier,
                    Quebec, QC G1R 2L5 en indiquant leurs noms, prénoms, adresse
                    e-mail et en prouvant leur identité en transmettant une copie
                    d’une pièce d’identité qui leur est propre.
                </p>
            </section>

            <section>
                <h2 className="mb-3">ARTICLE 12 – CONTACT</h2>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Toutes questions, commentaires et demandes concernant cette
                    politique de confidentialité ou toutes autres demandes seront bien
                    reçues. Ils devront être adressés par e-mail à support@ziaway.ca
                    ou par courrier postal à 104-600 avenue wilfrid laurier, Quebec,
                    QC G1R 2L5
                </p>
            </section>

            <section>
                <h2 className="mb-3">
                    ARTICLE 13 – RÉVISION DE LA POLITIQUE DE CONFIDENTIALITÉ
                </h2>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    La présente politique de confidentialité est consultable à tout
                    moment sur le Site de ZIAWAY{" "}
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Nous nous réservons le droit de la modifier afin de garantir notre
                    conformité dans le temps au regard de l’évolution des textes.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Par conséquent, nous vous invitons à venir consulter régulièrement
                    cette politique de confidentialité afin de se tenir informé des
                    derniers changements qui lui seront apportés.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Toutefois, en cas de modification substantielle de cette
                    politique, l’utilisateur en sera informé.
                </p>
                <br/>
                <p style={{whiteSpace: "pre-line", lineHeight: 1.9}}>
                    Il est porté à la connaissance de l’utilisateur que la dernière
                    mise à jour de la présente politique de confidentialité est
                    intervenue le 19/11/2022.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicyPage;
