import React, {lazy, useEffect, useState} from "react";
import StepWizard from "react-step-wizard";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import {useSelector} from "react-redux";

const SituationPropertyStep = lazy(() => import("./steps/SituationPropertyStep"))
const EstimateStep = lazy(() => import('./steps/EstimateStep'))
const TypeBaitment = lazy(() => import("./steps/TypeBaitment"))
const PropertyInformationStep = lazy(() => import("./steps/PropertyInformationStep"))
const AdditionalPropertyInformationStep = lazy(() => import("./steps/AdditionalPropertyInformationStep"))
const GeneralConditionStep = lazy(() => import("./steps/GeneralConditionStep"))
const OwnerProperty = lazy(() => import("./steps/OwnerProperty"));
const PersonalinformationStep = lazy(() => import("./steps/PersonalinformationStep"));
const FinalLoading = lazy(() => import("./steps/FinalLoading"));
const Emprunter = lazy(() => import( "./steps/Emprunter"));
const ProjectionFinancement = lazy(() => import( "./steps/ProjectionFinancement"));
const StatutRecherche = lazy(() => import( "./steps/StatutRecherche"));
const OuiCommenceVenteReponse = lazy(() => import( "./steps/OuiCommenceVenteReponse"));
const SellPropertyStep = lazy(() => import( "./steps/SellPropertyStep"));
const FunctionPropertyStep = lazy(() => import( "./steps/FunctionPropertyStep"));
const ContactedProfessionalStep = lazy(() => import( "./steps/ContactedProfessionalStep"));

const EstimateFormPage = ({ user, setUserData }) => {
    const auth = useSelector((state) => state.firebase.auth.uid);
    const location = useLocation();
    const { address = {}, } = useSelector(state => state.estimation);
    const [initialAuth, setInitialAuth] = useState(false);
    const [data, setData] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [formulaizeData, setFormulaizeData] = useState(null);
    const [result, setResult] = useState(null);
    const navigate = useNavigate();

    const handleNextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    useEffect(() => {
        if(address && address.postcode) {
            const data = location.state?.data || {}
            setFormulaizeData({...formulaizeData, ...data, location: address, formComplete: location.state?.step > 1})
        } else {
            navigate('/homepage');
        }
    }, [])


    useEffect(() => {
        if (auth) {
            setInitialAuth(true);
        }
        if (user) {
            if (
                moment(new Date().getTime()).diff(moment(user.verifiedAt), "hours") >=
                25
            ) {
                console.log('setUserData(null);')
            }
        }
    }, []);

    return (
        <section className="steps-form flex-fill py-5 my-xl-5 overflow-hidden">
            <div className="container">
                <div className="px-0 mx-auto col-12 col-xl-10">
                    <StepWizard initialStep={location.state?.step || currentStep} >
                        <SituationPropertyStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                            loggedIn={false}
                        />
                        <EstimateStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                            data={formulaizeData}
                        />
                        {formulaizeData?.type === "Unifamiliale" && (
                            <TypeBaitment
                                nextStep={handleNextStep}
                                previousStep={handlePreviousStep}
                                currentStep={currentStep}
                                setFormulaizeData={setFormulaizeData}
                            />
                        )}
                        <PropertyInformationStep
                            formulaizeData={formulaizeData}
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                        />
                        <AdditionalPropertyInformationStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                        />
                        <GeneralConditionStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                        />
                        <OwnerProperty
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                            formulaizeData={formulaizeData}
                            location={address}
                            setResult={setResult}
                        />
                        <FunctionPropertyStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                            formulaizeData={formulaizeData}
                        />

                        {formulaizeData?.estProprietaireReponse &&
                            formulaizeData?.estProprietaireReponse === "Oui" &&
                            formulaizeData.ceBienEstReponse && (
                                <SellPropertyStep
                                    nextStep={handleNextStep}
                                    previousStep={handlePreviousStep}
                                    currentStep={currentStep}
                                    setFormulaizeData={setFormulaizeData}
                                />
                            )}

                        {formulaizeData?.envisageVendreBienReponse &&
                            formulaizeData?.envisageVendreBienReponse ===
                            "ouiCommenceVente" && (
                                <OuiCommenceVenteReponse
                                    nextStep={handleNextStep}
                                    previousStep={handlePreviousStep}
                                    currentStep={currentStep}
                                    setFormulaizeData={setFormulaizeData}
                                />
                            )}
                        {formulaizeData?.raisonEstimation &&
                            formulaizeData.raisonEstimation === "souhaiteAcheter" && (
                                <StatutRecherche
                                    nextStep={handleNextStep}
                                    previousStep={handlePreviousStep}
                                    currentStep={currentStep}
                                    setFormulaizeData={setFormulaizeData}
                                />
                            )}

                        {formulaizeData?.raisonEstimation &&
                            formulaizeData.raisonEstimation === "souhaiteAcheter" &&
                            formulaizeData.statutRecherche && (
                                <ProjectionFinancement
                                    nextStep={handleNextStep}
                                    previousStep={handlePreviousStep}
                                    currentStep={currentStep}
                                    setFormulaizeData={setFormulaizeData}
                                />
                            )}

                        {formulaizeData?.raisonEstimation &&
                            formulaizeData.raisonEstimation === "souhaiteAcheter" &&
                            formulaizeData.statutRecherche &&
                            formulaizeData.projectionFinancement && (
                                <Emprunter
                                    nextStep={handleNextStep}
                                    previousStep={handlePreviousStep}
                                    currentStep={currentStep}
                                    setFormulaizeData={setFormulaizeData}
                                />
                            )}
                        <ContactedProfessionalStep
                            nextStep={handleNextStep}
                            previousStep={handlePreviousStep}
                            currentStep={currentStep}
                            setFormulaizeData={setFormulaizeData}
                        />
                        {initialAuth === false ? (
                            <PersonalinformationStep
                                nextStep={handleNextStep}
                                previousStep={handlePreviousStep}
                                currentStep={currentStep}
                                setUserData={setUserData}
                                result={result}
                                formValues={formulaizeData}
                                data={data}
                                setData={setData}
                                location={address}
                            />
                        ) : (
                            <FinalLoading result={result} formulaizeData={formulaizeData} />
                        )}
                    </StepWizard>
                </div>
            </div>
        </section>
    );
};

export default EstimateFormPage
