import actions from './actions'

const initialState = {
    recentEstimates: [],
}

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return {...initialState, ...action.payload};
        default:
            return state;
    }
}
