import React, { useEffect, useRef } from "react";
import getPlaceDetail from "../../utils/getPlaceDetail";

const Suggestions = ({
                         placePredictions,
                         placesService,
                         setValue,
                         placeToAddress,
                         followUpFunc,
                         setFieldValue,
                         setShowPredictions,
                         adressInputRef,
                     }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                adressInputRef.current &&
                !adressInputRef.current.contains(event.target)
            ) {
                setShowPredictions(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, adressInputRef, setShowPredictions]);

    return (
        <div className="autocomplete_predictions" ref={wrapperRef}>
            {placePredictions.map((prediction) => (
                <div
                    key={prediction.place_id}
                    onClick={() =>
                        getPlaceDetail(
                            prediction,
                            placesService,
                            setValue,
                            placeToAddress,
                            followUpFunc,
                            setFieldValue,
                            setShowPredictions
                        )
                    }
                    className="autocomplete_prediction"
                >
                    {prediction.description}
                </div>
            ))}
        </div>
    );
};

export default Suggestions;
