import { faChevronUp, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import { Container, Navbar, Modal } from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/images/Logo_h70.png"
import bars from "../../assets/icons/bars.svg"
import france from "../../assets/images/flags/france.png";
import { useTranslation } from "react-i18next";
import NotificationCard from "./notificationCard";
import 'assets/styles/phoneInput.css'
import {useDispatch, useSelector} from "react-redux";
import headerActions from "../../redux/header/actions";

const SignInModalNumber = React.lazy(() => import('./signinModal'));

const PublicHeader = () => {
    const { t, i18n } = useTranslation();
    const { users = [], blogs = []} = useSelector(state => state.header);
    const [smShow, setSmShow] = useState(false);
    const [smSidebar, setSmSidebar] = useState(false);
    const [signInShow, setSignInShow] = useState(false);
    const {showHeader = true} = useSelector(state => state.estimation);
    const location = useLocation();
    const dispatch = useDispatch();
    const handleLanguage = (lang) => {
        i18n.changeLanguage(lang).then(res => {
            console.log(res);
        }).catch((err) => {
            console.error(err)
        });
    };

    useEffect(() => {
        dispatch({
            type: headerActions.GET_NEWS,
        })
    }, []);

    if(location.pathname === '/accommodation') return <></>

    return (
        <>
            { !showHeader ?
                <div />
                :
                <>
                    {signInShow && <SignInModalNumber showModal={signInShow} setShowModal={setSignInShow} />}
                    {/* <NewSignInModal show={signInShow} setShow={setSignInShow} /> */}
                    <div className="bg-white shadow position-sticky fixed-top">
                        {location?.pathname === "/homepage" && (blogs && blogs.length > 0) && (
                            <div className="container position-relative">
                                <NotificationCard data={Array.from(blogs)} language={i18n.language} />
                            </div>
                        )}
                        <Container className="position-relative">
                            <Navbar bg="white" expand="lg">
                                <Navbar.Brand className="p-0" as={Link} to="/homepage">
                                    <img height={70} width={56} src={logo} alt=".." />
                                </Navbar.Brand>
                                <div className="d-lg-none">
                                    <button
                                        type="button"
                                        className="btn p-0"
                                        onClick={() => setSmSidebar(!smSidebar)}
                                    >
                                        <img height={20} width={20} src={bars} alt="..." />
                                    </button>
                                </div>
                                <Navbar.Collapse className={``}>
                                    <div className="mr-auto">
                                        <NavLink
                                            className="my-3 my-lg-0 mx-3 text-15"
                                            exact
                                            to="/homepage"
                                        >
                                            {t("Header.1")}
                                        </NavLink>
                                        <NavLink
                                            className="my-3 my-lg-0 mx-3 text-15"
                                            exact
                                            to="/estimation-mobiliere"
                                        >
                                            Estimation immobilière
                                        </NavLink>
                                        <NavLink className="my-3 my-lg-0 mx-3 text-15" to="/blog">
                                            {t("Header.2")}
                                        </NavLink>

                                        <NavLink className="text-15" to="/dataPolicy">
                                            {t("Header.11")}
                                        </NavLink>

                                        <a
                                            className="my-3 my-lg-0 mx-3 text-15"
                                            href="https://pro.ziaway.ca/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t("Header.10")}
                                        </a>
                                    </div>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="link"
                                                id="dropdown-basic"
                                                className="d-flex align-items-center"
                                            >
                                                <img
                                                    className="d-block mr-1"
                                                    height={15}
                                                    width={22}
                                                    src={france}
                                                    alt=".."
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleLanguage("sp")}>
                                                    <div className="d-flex align-items-center">
                                                        <span>French</span>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleLanguage("en")}>
                                                    <div className="d-flex align-items-center">
                                                        <span> English</span>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div>
                                        <button
                                            className="btn btn-primary "
                                            onClick={() => setSignInShow(true)}
                                        >
                                            <div className="d-flex  justify-content-between align-items-center">
                                                {t("Header.8")}{" "}
                                            </div>
                                        </button>
                                        <Modal
                                            className="container-xxl mx-auto"
                                            dialogClassName="modal-pos"
                                            size="sm"
                                            show={smShow}
                                            onHide={() => setSmShow(false)}
                                            aria-labelledby="example-modal-sizes-title-sm"
                                        >
                                            <Modal.Body>
                                                <div className="d-flex justify-content-end">
                                                    <FontAwesomeIcon
                                                        onClick={() => setSmShow(false)}
                                                        icon={faChevronUp}
                                                    />
                                                </div>
                                                <NavLink
                                                    className=" my-2 btn btn-primary w-100"
                                                    to="/session/signin"
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        {t("Header.8")}
                                                        <FontAwesomeIcon icon={faChevronRight} />
                                                    </div>
                                                </NavLink>
                                                <a
                                                    className=" btn btn-primary  w-100"
                                                    href="https://pro.ziaway.ca/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        {t("Header.9")}
                                                        <FontAwesomeIcon icon={faChevronRight} />
                                                    </div>
                                                </a>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    {/* </Nav> */}
                                </Navbar.Collapse>
                            </Navbar>
                        </Container>
                    </div>

                    <aside className={`sidebar-3 ${smSidebar ? "show" : "hide"}`}>
                        <div className="sidebar-3-menu">
                            <ul className="nav flex-column">
                                <li className="py-2">
                                    <NavLink className="text-15" exact to="/homepage">
                                        {t("Header.1")}
                                    </NavLink>
                                </li>
                                <li className="py-2">
                                    <NavLink className="text-15" to="/blog">
                                        {t("Header.2")}
                                    </NavLink>
                                </li>
                                <li className="py-2">
                                    Faq
                                </li>
                                <li className="py-2">
                                    <NavLink className="text-15" to="/dataPolicyAuth/false">
                                        {t("Header.11")}
                                    </NavLink>
                                </li>
                                <li className="py-2">
                                    <div className="d-flex">
                                        <div className="mr-auto">
                                            <button
                                                onClick={() => setSignInShow(true)}
                                                className="dropdown-item text-white bg-primary cursor-pointer"
                                            >
                                                <span className="h6 mb-0 text-bold"> {t("Header.8")}</span>
                                            </button>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="link"
                                                    id="dropdown-basic"
                                                    className="d-flex align-items-center"
                                                >
                                                    <img
                                                        className="d-block mr-1"
                                                        height={15}
                                                        width={22}
                                                        src={france}
                                                        alt=".."
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleLanguage("sp")}>
                                                        <div className="d-flex align-items-center">
                                                            {/* <img
                        className="d-block mr-2"
                        height={15}
                        width={18}
                        src={france}
                        alt=".."
                      /> */}
                                                            <span>French</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleLanguage("en")}>
                                                        <div className="d-flex align-items-center">
                                                            {/* <img
                        className="d-block mr-2"
                        height={15}
                        width={18}
                        src={england}
                        alt=".."
                      /> */}
                                                            <span> English</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div
                                        className={`collapse mt-2`}
                                        id="collapseExample"
                                    >
                                        <div className="card card-body">
                                            <NavLink
                                                className=" my-2 btn btn-primary w-100"
                                                to="/session/signin"
                                            >
                                                <div className="d-flex justify-content-between">
                                                    {t("Header.8")}
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </div>
                                            </NavLink>
                                            <a
                                                className=" btn btn-primary  w-100"
                                                href="https://pro.ziaway.ca/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <div className="d-flex justify-content-between">
                                                    {t("Header.9")}
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="sidebar-3-overlay" onClick={() => setSmSidebar(false)}>
                            <button
                                type="button"
                                className="btn p-2 w-100 text-right"
                                onClick={() => setSmSidebar(false)}
                            >
                                <i className="icon icon-deltad-cancel h2 mb-0"></i>
                            </button>
                        </div>
                    </aside>
                </>
            }
            </>
    )
}

export default PublicHeader;
