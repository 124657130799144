import firebase from "firebase/compat/app";
import {toast} from "react-toastify";

export const getEstimation = async (data = {}) => {
    const address = {
        postalCode: data.postcode,
        address: `${data.StreetNumber?.long_name} ${data.StreetName?.long_name}`,
    }
    const getPropertyDetailInfoNew = firebase.functions().httpsCallable("GetProperyDetailInfoNew")
    return getPropertyDetailInfoNew(address).then(res => {
        return res.data
    }).catch(() => {
        return false;
    })
}

export const evaluerBien = async (data) => {
    const getEvalueBien = firebase.functions().httpsCallable( 'EvaluerBienNew');
    return getEvalueBien(data).then(res => {
        return res.data;
    }).catch(err => {
        console.log(err);
        return false;
    })
}

export const handleVerifyCode = (verificationId, verificationCode) => {
    return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
}

export const signInWithCredential = (credentials) => {
    return firebase.auth().signInWithCredential(credentials).then(res => {
        return res.user.uid;
    })
}


export const getUserWithUID = async (uid) => {
    const docSnap = await firebase.firestore().collection("users").doc(uid).get();
    if (docSnap.exists) {
        return {uid, ...docSnap.data()};
    } else {
        return false;
    }
}

export const updateUserWithUID = async (uid, data) => {
    firebase.firestore().collection('users').doc(uid).set(data, {
        merge: true
    }).then(res => {
        console.log(res);
        return true;
    }).catch(err => {
        console.log(err);
        return false;
    })
}

export const createRapportEvaluation = async (rapportsEvaluations) => {
    firebase.firestore().collection("RapportsEvaluations")
        .add(rapportsEvaluations)
        .then(res => res.id)
        .catch(() => false);
}

export const sendEmailJson = (emailJson) => {
    const sendEmail = firebase.functions().httpsCallable( 'useSendEmail');
    sendEmail(emailJson);
}

export const zohoPostLead = (data) => {
    const postZoho = firebase.functions().httpsCallable( 'useZohoPostNewLead');
    postZoho(data);
}

export const updateUserCourtier = async (uid, recommandations) => {
    await firebase.firestore().collection("users").doc(uid).update({recommandations})
}

export const handleSendCode = (phoneNumber, appVerifier) => {
    return firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
            return confirmationResult;
        }).catch((error) => {
            console.log('error', error)
            toast.error(error?.message ? error.message : "Une erreur est servenue");
            return false;
        });
}