import React from "react";
import { Link } from "react-router-dom";

const SectionHeroImmobiliere = () => {
  return (
    <section
      className="py-5 bg-cover-image"
      style={{
        backgroundImage: `url(/assets/images/bg-4.jpg)`,
      }}
    >
      <div className="container py-md-4">
        <div className="text-center">
          <h1 className="font-poppins font-weight-bold text-violet-2 mb-3">
            ÉVALUATION IMMOBILIÈRE :
            <br className="d-none d-xl-block" />
            <span className="text-orange">ZIAWAY</span>, VOTRE ÉVALUATEUR MAISON
            AU QUÉBEC
          </h1>
          <p className="font-poppins h5 font-weight-normal mb-4 text-black-2">
            Obtenez la valeur de votre propriété au Québec en moins de 2
            minutes.
          </p>
          <Link
            to="/homepage"
            className="btn btn-primary font-poppins rounded-2 px-4 py-3 font-weight-500 text-16"
          >
            Obtenir mon estimation
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SectionHeroImmobiliere;
