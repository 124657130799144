import PublicLayout from "./publicLayout";
import {Suspense, lazy, useEffect, useState} from "react";
import PrivateLayout from "./privateLayout";
import Loading from "../components/loading";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import estimationActions from "../redux/estimation/actions";
import firebase from "../firebaseService";
const LazyLoadingScript = lazy(() => import("../utils/lazyLoadingScript"));

export const MainLayout = () => {
    const {isLoaded = false, isEmpty = true, verificationCode = false, redirectTo = ""} = useSelector(state => {
        return {
            isLoaded: state.firebase.auth.isLoaded,
            isEmpty: state.firebase.auth.isEmpty,
            verificationCode: state.estimation.verificationCode,
            redirectTo: state.estimation.redirectTo
        }
    });
    const [isFromLp, setFromLp] = useState(true);
    const navigate = useNavigate();
    const { search } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = new URLSearchParams(search).get("token");
        if(token) {
            firebase
                .auth()
                .signInWithCustomToken(token)
                .then((res) => {
                    navigate("/evaluation-bien")
                    setFromLp(false)
                })
                .catch((err) => {
                    setFromLp(false)
                });
            setFromLp(true);
        }else {
            console.log("no token")
            setFromLp(false)
        }
    }, [search])

    useEffect(() => {
        if(redirectTo && redirectTo.includes("vmz")) {
            dispatch({
                type: estimationActions.SET_STATE,
                payload: {
                    verificationCode: false,
                    redirectTo: "",
                }
            })
            navigate(redirectTo);
        }
    }, [redirectTo]);

    return (
        <>
            {(!isLoaded || isFromLp) ?
                <Loading />
                :
                <Suspense fallback={<Loading />}>
                    <LazyLoadingScript />
                    {isEmpty || verificationCode ? <PublicLayout /> : < PrivateLayout/>}
                </Suspense>
            }
        </>

    )
}

export default MainLayout;