const estimationActions = {
    GET_ESTIMATION: 'estimation/GET_ESTIMATION',
    EVALUER_BIEN: 'estimation/EVALUER_BIEN',
    SET_STATE: 'estimation/SET_STATE',
    HANDLE_SEND_CODE: 'estimation/HANDLE_SEND_CODE',
    HANDLE_VERIFY_CODE: 'estimation/HANDLE_VERIFY_CODE'
}

export function setState(data) {
    return {
        type: estimationActions.SET_STATE,
        payload: data
    }
}
export default estimationActions;
