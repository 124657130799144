import SectionHeroImmobiliere from "./components/SectionHeroImmobiliere";
import ImmobiliereSectionTwo from "./components/ImmobiliereSectionTwo";
import ImmobiliereSectionThree from "./components/ImmobiliereSectionThree";
import ImmobiliereSectionFour from "./components/ImmobiliereSectionFour";
import ImmobiliereSectionFive from "./components/ImmobiliereSectionFive";
import ImmobiliereSectionSix from "./components/ImmobiliereSectionSix";
import ImmobiliereSectionSeven from "./components/ImmobiliereSectionSeven";
import ImmobiliereSectionEight from "./components/ImmobiliereSectionEight";

const EstimationImmobiliere = () => {
    return (
        <section className="pb-5">
            <SectionHeroImmobiliere />
            <ImmobiliereSectionTwo />
            <ImmobiliereSectionThree />
            <ImmobiliereSectionFour />
            <ImmobiliereSectionFive />
            <ImmobiliereSectionSix />
            <ImmobiliereSectionSeven />
            <ImmobiliereSectionEight />
        </section>
    )
}

export default EstimationImmobiliere;
