import React, { useContext, useEffect, useState } from "react";
import {
    Accordion,
    AccordionContext,
    Button,
    Card,
    Modal,
    useAccordionButton,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <span
            onClick={decoratedOnClick}
            className="d-flex align-items-start cursor-pointer"
        >
      <span className="h3 mb-0 mr-1">{isCurrentEventKey ? "-" : "+"}</span>
      <span className="pt-1">{children}</span>
    </span>
    );
}

const Buttons = () => {
    const [check, setCheck] = useState("");
    return (
        <div className="d-flex" style={{ gap: "0.375rem" }}>
            <Button
                size="sm"
                variant={`${check === "Refuserd" ? "danger" : "outline-danger"}`}
                onClick={() => setCheck("Refuserd")}
            >
                Refuser
            </Button>
            <Button
                size="sm"
                variant={`${check === "Accepter" ? "success" : "outline-success"}`}
                onClick={() => setCheck("Accepter")}
            >
                Accepter
            </Button>
        </div>
    );
};

const ButtonsPartners = () => {
    const [check, setCheck] = useState("");
    return (
        <div className="d-flex" style={{ gap: "0.375rem" }}>
            <Button
                size="sm"
                variant={`${check === "Bloquer" ? "danger" : "outline-danger"}`}
                onClick={() => setCheck("Bloquer")}
            >
                Bloquer
            </Button>
            <Button
                size="sm"
                variant={`${check === "Autoriser" ? "success" : "outline-success"}`}
                onClick={() => setCheck("Autoriser")}
            >
                Autoriser
            </Button>
        </div>
    );
};

const CookiesModal = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState("Your privacy is important to us");

    const [isCookiesAccepted, setIsCookiesAccepted] = useState(false);

    const handleClose = () => {
        localStorage.setItem("cookiesAccepted", true);
        setShow("");
    };

    const authorizeList = [
        {
            title: "Personnalisation de publicités par notre site et nos partenaires",
            text: "Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.",
            isButtons: true,
            link: "politique de confidentialité",
        },
        {
            title: "Mesure d'audience",
            text: "Ces cookies nous permettent de mesurer l'audience de notre site, de faire des statistiques et d'améliorer la qualité de nos services. Nous pouvons utiliser AT Internet, un outil de mesure d’audience bénéficiant d’une exemption au consentement afin de communiquer des statistiques anonymes sur la visibilité de nos annonces, à destination des professionnels de l'immobilier qui diffusent ces annonces sur nos plateformes. Pour en savoir plus, vous pouvez consulter notre Politique Générale de Protection des Données.",
            isButtons: true,
            link: "politique de confidentialité",
        },
        {
            title: "Fonctionnel",
            text: "Ces cookies sont nécessaires pour le fonctionnement du service.",
            boldText:
                "Cette finalité est requise par notre site pour fonctionner normalement et ne peut pas être désactivée.",
            subTitle: "REQUIS",
        },
    ];

    const partnersListOfA = [
        {
            title: "Algolia",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Algolia et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfC = [
        {
            title: "Criteo",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Criteo et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfD = [
        {
            title: "Datadome",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Datadome et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfF = [
        {
            title: "Facebook",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Facebook et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Font Awesome",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Font Awesome et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfG = [
        {
            title: "Google Advertising Products",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Google Advertising Products et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Analytics",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Google Analytics et sur la façon dont cette entité traite les données dans sa ",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Mesure d'audience
                    </li>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Fonts",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google Fonts et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google reCAPTCHA",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google reCAPTCHA et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Tag Manager",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google Tag Manager et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfL = [
        {
            title: "LinkedIn",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur LinkedIn et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfY = [
        {
            title: "Youtube",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Youtube et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    useEffect(() => {
        const ck = localStorage.getItem("cookiesAccepted");
        setIsCookiesAccepted(ck);
    }, []);
    return (
        <>
            {!isCookiesAccepted && (
                <Modal
                    show={show === "Your privacy is important to us"}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="text-primary">{t("cookies.0")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("cookies.1")}</p>

                        <p>{t("cookies.2")}</p>
                        <Link to="/dataPolicy/true"> {t("cookies.3")}</Link>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                            <p className="mb-2 mb-sm-0 mr-2">
                                <small>{t("cookies.4")}</small>
                            </p>
                            <Button
                                variant="outline-primary"
                                className="mr-2"
                                onClick={() => setShow("Paramètres des cookies")}
                            >
                                {t("cookies.5")}
                            </Button>
                            <Button variant="primary" onClick={handleClose}>
                                {t("cookies.6")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}

            <Modal
                show={show === "Paramètres des cookies"}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                scrollable
            >
                <Modal.Header>
                    <Modal.Title className="text-primary">{t("cookies.7")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("cookies.8")}</p>

                    <p>{t("cookies.9")}</p>

                    <p>{t("cookies.10")}</p>

                    <p>
                        {t("cookies.11")}
                        <Link to="/dataPolicy/false">{t("cookies.12")}</Link>
                    </p>

                    <p className="font-weight-bold">{t("cookies.13")}</p>
                    <Accordion>
                        {authorizeList.map((item, index) => (
                            <Card key={`authorize-${index}`}>
                                <Card.Header>
                                    <div className="d-flex align-items-start">
                                        <div className="mr-auto pr-2">
                                            <ContextAwareToggle eventKey={item.title}>
                                                {item.title}
                                            </ContextAwareToggle>
                                        </div>
                                        {item.isButtons && <Buttons />}
                                        {item.subTitle && (
                                            <div className="mb-0 font-weight-bold">
                                                {item.subTitle}
                                            </div>
                                        )}
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={item.title}>
                                    <Card.Body>
                                        <p className="mb-0">{item.text}</p>
                                        {item?.link && (
                                            <Link to="/dataPolicy/false">{item?.link}</Link>
                                        )}
                                        {item.boldText && (
                                            <p className="mt-3 mb-0 font-weight-bold">
                                                {item.boldText}
                                            </p>
                                        )}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                    <div className="text-right mt-3">
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setShow("partners")}
                        >
                            {t("cookies.14")}
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            variant="outline-primary"
                            className="mr-2"
                            onClick={handleClose}
                        >
                            {t("cookies.15")}
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            {t("cookies.16")}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show === "partners"}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                scrollable
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title
                        className="text-primary cursor-pointer"
                        onClick={() => setShow("Paramètres des cookies")}
                    >
                        <div className="d-flex align-items-baseline">
                            <i className="icon i-Arrow-Back-3 mr-2"></i>
                            <span>{t("cookies.17")}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("cookies.18")}</p>

                    <div>
                        <div className="bg-gray-200 p-2 rounded d-flex align-items-center">
                            <b className="mr-auto"> {t("cookies.19")}</b>
                            <ButtonsPartners />
                        </div>
                        <div className="overflow-auto py-3" style={{ height: "18.75rem" }}>
                            <ul
                                className="nav flex-column flex-nowrap"
                                style={{ gap: "1rem" }}
                            >
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        A
                                    </h5>
                                    <Accordion>
                                        {partnersListOfA.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        C
                                    </h5>
                                    <Accordion>
                                        {partnersListOfC.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        D
                                    </h5>
                                    <Accordion>
                                        {partnersListOfD.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        F
                                    </h5>
                                    <Accordion>
                                        {partnersListOfF.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        G
                                    </h5>
                                    <Accordion>
                                        {partnersListOfG.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        L
                                    </h5>
                                    <Accordion>
                                        {partnersListOfL.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        Y
                                    </h5>
                                    <Accordion>
                                        {partnersListOfY.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <Link
                                                                className="font-weight-bold"
                                                                to="/dataPolicy/false"
                                                            >
                                                                {t("cookies.20")}
                                                            </Link>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Accordion>
                                <Card className="bg-gray-200">
                                    {/* <Card.Header>
                    <div className="d-flex align-items-start">
                      <div className="mr-auto pr-2">
                        <ContextAwareToggle eventKey="Voir les infos de l'utilisateur">
                          {t("cookies.21")}
                        </ContextAwareToggle>
                      </div>
                    </div>
                  </Card.Header> */}
                                    <Accordion.Collapse eventKey="Voir les infos de l'utilisateur">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <b className="mr-1" style={{ minWidth: "6.25rem" }}>
                                                    {t("cookies.22")}
                                                </b>
                                                <span style={{ wordBreak: "break-all" }}>
                          184b9fcc-b526-66de-ad15-0fa5b917242f
                        </span>
                                            </div>
                                            <div className="mt-3 d-flex">
                                                <b className="mr-1" style={{ minWidth: "6.25rem" }}>
                                                    {t("cookies.23")}
                                                </b>
                                                <span style={{ wordBreak: "break-all" }}>
                          eyJ1c2VyX2lkIjoiMTg0YjlmY2MtYjUyNi02NmRlLWFkMTUtMGZhNWI5MTcyNDJmIiwiY3JlYXRlZCI6IjIwMjItMTEtMjdUMTY6NDc6NDIuOTU5WiIsInVwZGF0ZWQiOiIyMDIyLTExLTI3VDE2OjQ3OjQyLjk1OVoiLCJ2ZXJzaW9uIjpudWxsfQ==
                        </span>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>

                    <div className="text-right mt-3">
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setShow("partners")}
                        >
                            {t("cookies.24")}
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            variant="outline-primary"
                            className="mr-2"
                            onClick={handleClose}
                        >
                            {t("cookies.25")}
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            {t("cookies.26")}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CookiesModal;