import React from "react";
import { compose } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  KmlLayer,
  Polygon,
} from "react-google-maps";

const MapEstimate = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  //Fonction a refaire trop harcodé
  const getUrlIcon = (raison, proprietaire) => {
    switch (raison) {
      case "Je souhaite connaître sa valeur":
        return {
          url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
        };

      case "Je souhaite acheter pour investir":
      case "Je souhaite acheter pour habiter":
        return {
          url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
        };

      case "Non, je viens de le vendre":
      case "Non, je n'ai pas de projet de vente":
        return { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" };
      case "Je souhaite vendre":
      case "Oui, d'ici 6 mois":
      case "Oui, dès que possible":
      case "Oui, d'ici 3 mois":
      case "Oui, dans plus de 6 mois":
      case "Oui, j'ai déjà commencé la vente":
        if (proprietaire) {
          return {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
          };
        } else {
          return {
            url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
          };
        }

      default:
        return { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" };
    }
  };

  return (
    <div>
      <GoogleMap
        defaultZoom={7}
        center={{
          lat: props?.markers[0]?.location?.latlng?.lat,
          lng: props?.markers[0]?.location?.latlng?.lng,
        }}
      >
        <KmlLayer
          url="https://www.donneesquebec.ca/recherche/fr/dataset/8dba5a63-49b2-4d20-8333-f99df764ce10/resource/d7166ada-eb9d-413f-9d12-f019402eb950/download/vdq-reseaucyclable.kml"
          options={{ preserveViewport: true }}
        />

        <Polygon
          // Make the Polygon editable / draggable
          editable
          draggable
          path={props.polygon}
        />
        {props.markers.map((marker) => {
          return (
            <Marker
              key={marker.id}
              icon={getUrlIcon(marker.raison, marker.proprietaire)}
              position={{
                lat: marker.location.latlng.lat,
                lng: marker.location.latlng.lng,
              }}
            ></Marker>
          );
        })}
      </GoogleMap>
    </div>
  );
});

export default MapEstimate;
