import { call, all, takeEvery, put } from 'redux-saga/effects'
import * as API from './services';
import actions from "./actions";
export function * GET_POSTS() {
    const response = yield call(API.getPosts);
    if(response) {
        yield put({
            type: actions.SET_STATE,
            payload: {
                posts: response,
            }
        })
    }
}


export default function * dashboardSagas() {
    yield all([
        takeEvery(actions.GET_POSTS, GET_POSTS),
    ])
}