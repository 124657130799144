import { call, all, takeEvery, put, select } from "redux-saga/effects";
import * as API from "./services";
import * as ESTIMATION_API from "../estimation/services";
import actions from "./actions";
import headerActions from "./actions";
import userActions from "../user/actions";
import swal from "sweetalert2";
import { zohoPostLead } from "./services";

const headerState = (state) => state.header;
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* GET_NEWS(action) {
  try {
    const [blogs, users] = yield all([
      yield call(API.getNews),
      yield call(API.getUsers),
    ]);
    const data = {
      blogs: [],
      users: [],
    };
    if (blogs) {
      data.blogs = blogs;
    }
    if (users) {
      data.users = users;
    }
    yield put({
      type: headerActions.SET_STATE,
      payload: data,
    });
  } catch (e) {}
}

function* HANDLE_SEND_CODE({ payload }) {
  const { phoneNumber, recaptchaVerifier, userData } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(
    ESTIMATION_API.handleSendCode,
    phoneNumber,
    recaptchaVerifier
  );
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        verificationId: response.verificationId,
        showVerificationModal: true,
        loading: false,
        userData,
        confirmationResult: response,
      },
    });
  } else {
    console.log("error");
  }
}

export function* HANDLE_VERIFY_CODE({ payload }) {
  const { navigate, verificationCode } = payload;
  const { userData = {}, verificationId = null } = yield select(headerState);

  try {
    const credentails = yield call(
      ESTIMATION_API.handleVerifyCode,
      verificationId,
      verificationCode
    );
    const uid = yield call(ESTIMATION_API.signInWithCredential, credentails);

    const isExists = yield call(ESTIMATION_API.getUserWithUID, uid);
    console.log("isExists", isExists);
    if (!isExists) {
      yield call(ESTIMATION_API.updateUserWithUID, uid, {
        userId: uid,
        role: "membre",
        displayName: userData.username
          ? userData.username
          : isExists?.displayName,
        phoneNumber: userData.telephone,
        email: userData.email,
      });

      const lead_data = {
        info: {
          data: [
            {
              Last_Name: userData.username
                ? userData.username
                : isExists?.displayName,
              Email: userData.email,
              Phone: userData.telephone,
              ConnectionSource: "lp",
            },
          ],
        },
        vmodule: "Leads",
      };

      console.log("lead_data", lead_data);

      zohoPostLead(lead_data)
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    yield put({
      type: userActions.SET_STATE,
      payload: {
        profile: {
          userId: uid,
          displayName: userData.username
            ? userData.username
            : isExists?.displayName,
          email: userData.email,
          photoURL: "/assets/images/face-7.jpg",
          age: 25,
          token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
        },
        user: {
          ...userData,
        },
      },
    });
    isExists ? navigate("/dashboard") : navigate("/redirectUrl");
  } catch (error) {
    console.log(error);
    if (error.code === "auth/invalid-verification-code") {
      swal.fire(
        "Oups erreur!",
        "Nous nous attendions à une réponse différente. Veuillez réessayer.",
        "error"
      );
    } else {
      console.error("Error checking object:", error);
      swal.fire("Oups erreur!", error?.message, "error");
    }
  }
}

function* sagas() {
  yield all([
    yield takeEvery(actions.HANDLE_SEND_CODE, HANDLE_SEND_CODE),
    yield takeEvery(actions.GET_NEWS, GET_NEWS),
    yield takeEvery(actions.HANDLE_VERIFY_CODE, HANDLE_VERIFY_CODE),
  ]);
}

export default sagas;
