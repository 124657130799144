import React from "react";
import { useTranslation } from "react-i18next";

const TermOfService = () => {
    const { i18n } = useTranslation();

    if (i18n.language === "en")
        return (
            <>
                <div className="container">
                        <h1 className="mb-4 text-primary">TERMS AND CONDITIONS OF USE</h1>
                        <p>(Version updated on 21/12/2022)</p>

                        <section>
                            <h2 className="mb-3">ARTICLE 1. DEFINITIONS</h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                {`
          ▪ Advertisement: Real estate advertisement of sale or rent added on the Site by the Professionals of the real estate partners
          ▪ Customer review: Appreciation of the Real Estate Professional's services by its customers, in accordance with these TOS, the TOSP, and the charter of the properties sold,
          ▪ Personal Data: means any information relating, directly or indirectly, to an identified or identifiable natural person.
          ▪ Partner: Any real estate professional with a free or paid subscription to Ziaway Technology
          ▪ Real estate professional: Real estate agent, real estate representative or any professional user of the Services.
          ▪ Real estate professional partner: Real estate agent, real estate representative or any professional who uses the Services for free or for a fee, and who is contractually bound to Ziaway Technologie.
          ▪ Party: means both the User and the Provider.
          ▪ Services: refers to all services offered by Ziaway Technologie to users through the Platform
          ▪ Site: refers to the online platform accessible from the website https://ziaway.ca/ by Ziaway Technologie and allowing to benefit from the Services. The Platform includes all web pages and features offered to Users.
          ▪ User: means any natural person accessing and browsing the https://ziaway.ca/ platform.
          ▪ Password: means the password associated with the Username. With the Username and Password, the user can benefit from the Services offered on the Platform.".
          `}
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-3">ARTICLE 2. OBJECT</h2>
                            <p>
                                The present General Terms of Use (GTU) are applicable to the use
                                of the website accessible at the address https://ziaway.ca/ as
                                well as the services offered therein.
                            </p>
                            <h3>2.1. Applicability of GCU</h3>
                            <p>
                                These Terms of Use are fully effective as of the date of
                                publication on the https://ziaway.ca/ website and apply to all
                                services offered by Ziaway Technologie{" "}
                            </p>
                            <h3>2.2. Enforceability of the GCU</h3>
                            <p>
                                The use of the site and services offered by https://ziaway.ca/
                                implies the final, full, and unreserved acceptance of the User
                                to these GCU. No indication or document may create an obligation
                                not included in these Terms of Use unless it is the subject of a
                                new agreement between the Parties.
                            </p>
                            <p>
                                The fact that one of the Parties has not required the
                                application of any clause of these TOU, whether permanently or
                                temporarily, shall in no case be considered as a waiver of said
                                clause.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-3">
                                ARTICLE 3. ACCEPTANCE OF THE GENERAL CONDITIONS OF USE
                            </h2>

                            <p>
                                The User agrees to carefully read these Terms of Use (TOU) and
                                is invited to print, download, and keep a copy. The use of the
                                features of the Ziaway Technology platform implies the express
                                acceptance of these conditions. As such, the user acknowledges
                                having been fully informed of the nature of the services offered
                                by Ziaway Technologie.
                            </p>
                            <p>
                                It is specified that these conditions are referenced at the
                                bottom of each page of the site https://ziaway.ca/ by means of a
                                hyperlink and can thus be consulted at any time.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">
                                ARTICLE 4. MODIFICATION OF THE GENERAL CONDITIONS OF USE
                            </h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The present conditions apply to all users of the site. They were
                                last updated on 21 December 2022. They may be modified at any
                                time, to comply with legislative or regulatory changes. A
                                notification may then be sent to users to inform them of the
                                changes made. In any case, the applicable conditions are those
                                in force at the time of navigation on the site.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">
                                ARTICLE 5. VALIDITY OF THE GENERAL CONDITIONS OF USE
                            </h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                If any provision of these terms and conditions is found to be
                                invalid by any applicable law or regulation and/or by a court
                                decision, it shall be deemed unwritten but shall not affect the
                                validity of the remaining provisions which shall remain in full
                                force and effect.
                            </p>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Such a modification or decision does not authorize customers and
                                Users to disregard these conditions.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 6. CONTENT OF THE SITE </h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The purpose of the Site is to present the Services and benefits
                                offered by Ziaway Technologie and its respective partners as
                                part of Ziaway Technologie's service offerings, as described in
                                the pages of the Site. The Site is a platform offering the User
                                several real estate information services and assistance in the
                                sale or purchase of real estate. In particular, the Site allows
                                Users to estimate a property online. It also allows Users to be
                                put in contact with real estate professionals, in particular by
                                listing partner real estate professionals
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The services offered on the site are subject to change. Ziaway
                                Technologie reserves the right to add, delete or modify all or
                                part of the site or the Services it offers, without prejudice to
                                the application of any conditions previously concluded and
                                without notice.
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Ziaway Technologie strives to verify and validate the
                                information and data it offers for consultation on the Site. It
                                is hereby specified that the real estate estimates on the Site
                                are automated estimates calculated through algorithms. There is
                                therefore a degree of uncertainty concerning the result of the
                                estimates.
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Ziaway Technologie does not assume any obligation related to the
                                accuracy of the information and data orrelated to the uses that
                                may be made of said information and data. Therefore, Ziaway
                                Technologie does not provide any guarantee, express or implied,
                                regarding the content of the Website or the results of the
                                estimates.
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The User is informed and acknowledges that the activity of the
                                Site is limited to facilitating the real estate process and
                                projects, as well as putting Users in contact with real estate
                                professionals. The responsibility of the Site can in no way be
                                engaged in the context of relationships, agreements and
                                discussions that may occur between Users and real estate
                                professionals and any other partner.
                            </p>
                            <br />
                            <h3>
                                ARTICLE 6.1 REFERENCING OF REAL ESTATE PROFESSIONALS ON THE SITE
                            </h3>
                            <p>
                                Ziaway Technology performs a listing activity by allowing the
                                User to access a sample of Real Estate Professionals and a
                                comparison activity by ranking the Real Estate Professionals
                                Partners.
                            </p>
                            <h3>ARTICLE 6.1.1 FUNCTIONING OF THE REFERENCING</h3>
                            <p>
                                The referencing allows the User to access a sample of Real
                                Estate Professionals. The latter are referenced according to
                                criteria chosen by the User such as the dynamism of the Partners
                                or the satisfaction of the Customers. It also allows the User
                                looking to sell or buy a property to access a sample of similar
                                properties that Real Estate Professionals have sold and chosen
                                to publish near the address entered. The real estate
                                professionals listed on the Website are Ziaway Technology
                                Partners. They have subscribed to a free or paid visibility
                                offer allowing them to be displayed to users.
                            </p>
                            <p>
                                In order to obtain more functionalities and increase the
                                information displayed, Real Estate Professionals may choose
                                adapted Services for a fee. Ziaway Technology does not own any
                                equity interest in the Real Estate Professionals presented on
                                the Website, nor do any of these Partners have an equity
                                interest in Ziaway Technology.
                            </p>
                            <p>
                                By subscribing to the various visibility services offered by
                                Ziaway Technology, Real Estate Professionals agree to abide by
                                the General Terms and Conditions of Service (GTCS)
                            </p>
                            <p>
                                Professionals who do not comply with the applicable rules may be
                                dereferenced by Ziaway Technologie. They will no longer appear
                                on the various lists. De-listing may be temporary and last only
                                for the period of non-compliance with the established
                                provisions.
                            </p>

                            <h3>ARTICLE 6.1.2 "COMPARATOR" MENTIONS</h3>
                            <p>
                                ZIAWAY offers Services designed to facilitate the User's real
                                estate projects. To this end, the Site allows them to connect
                                with Professionals and provides Users with objective comparisons
                                of different real estate Professionals partners. Any
                                professional who has subscribed to an offer is bound to Ziaway
                                Technology by the General Terms and Conditions of Service (GTCS)
                                applicable at the time of their subscription.
                            </p>
                            <p>
                                In order to obtain more functionality, Real Estate Professionals
                                can subscribe to different Services, for a fee adapted according
                                to the Service chosen. Only Real Estate Professionals who are
                                Ziaway Technology Partners are compared. The list of compared
                                professionals is not exhaustive. Nevertheless, the large number
                                of Partners allows Ziaway Technologie to present a wide choice
                                of professionals.
                            </p>
                            <p>
                                Ziaway Technologie does not own any equity interest in the real
                                estate professionals whose services are compared on the Site,
                                nor do any of these Partners have an equity interest in Ziaway
                                Technologie
                            </p>
                            <p>
                                Ziaway Technology offers four types of comparators on the Site:
                            </p>

                            <p className="font-weight-bold">
                                {" "}
                                {`
          ◼ RANKING ACCORDING TO DYNAMISM
         
          `}
                            </p>
                            <p>
                                The comparison between Real Estate Professionals is based on the
                                sales they have made and published on Ziaway Technology during
                                the last 12 months in each geographical area. The size of this
                                geographical area varies according to its population density.
                                The Professionals who have sold and published the most
                                properties are located at the top of the ranking.
                            </p>
                            <p className="font-weight-bold">
                                {" "}
                                {`
          ◼ RANKING BY RELEVANCE
         
          `}
                            </p>
                            <p>
                                The comparison between Real Estate Professionals is based on
                                their performance in selling a similar property near the address
                                entered. Property similarity considers location, property type,
                                number of rooms, size and date of sale. The more similar
                                properties a professional has sold and published near the
                                address, the better they are ranked.
                            </p>
                            <p className="font-weight-bold">
                                {" "}
                                {`
          ◼ CLASSIFICATION ACCORDING TO DISTANCE
         
          `}
                            </p>
                            <p>
                                The comparison between the Real Estate Professionals is done
                                according to the distance from the address entered. The ranking
                                is done from the professional who sold properties closest to the
                                address entered to the professional who sold the furthest away.
                                The ranking is not influenced by any remuneration from the Real
                                Estate Professionals. These different rankings available on the
                                Site are continuously updated. Ziaway Technology strives to
                                offer a real-time ranking by integrating each data transmitted
                                by the Partners or their Clients in the system allowing the
                                comparison.
                            </p>

                            <h3>ARTICLE 6.2 DISCLAIMER</h3>
                            <p>
                                Ziaway Technologie aims to present you, without exhaustiveness,
                                various useful information and various partners for the sale or
                                acquisition of your property. Nevertheless, because we do not
                                know exactly what your real estate project is, and because we
                                cannot know all our partners, it is up to you to make any
                                verifications that you feel are necessary or appropriate before
                                contacting one of the real estate professionals listed on our
                                Site.
                            </p>
                            <p>
                                Ziaway Technologie does not guarantee in any way whatsoever the
                                information published. Given the wide variety of Partners
                                present on our Website, Ziaway Technologie does not guarantee in
                                any way whatsoever the business practices and services offered
                                by real estate professionals, and more generally by any third
                                party, present on the Website.
                            </p>
                            <p>
                                Consequently, the connection with a real estate professional, a
                                real estate developer or, more generally, with any third party,
                                through our Site is made under your own responsibility without
                                any guarantee on our part as to the satisfaction of the services
                                offered.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">
                                ARTICLE 7. PUBLICATION OF COMMENTS AND OPINIONS ON THE PLATFORM
                            </h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Users may publish comments on the Ziaway Technologie blog under
                                their sole editorial responsibility. The User is solely
                                responsible for the comments. Ziaway Technologie has the right
                                to delete any comment or customer review or rating, without this
                                deletion triggering its civil or criminal liability
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 8. OBLIGATIONS OF THE PARTIES</h2>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                When using the Ziaway Technologie Platform, each User agrees not
                                to violate public order and to comply with the laws and
                                regulations in force and to respect the provisions of these
                                Terms of Use. Ziaway Technologie 's general obligation is an
                                obligation of means. Ziaway Technologie has no obligation of
                                result or reinforced means of any kind.
                            </p>
                            <h3>ARTICLE 8.1 OBLIGATIONS OF USERS</h3>
                            <p>Each User is obliged to:</p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                {`
          ▪ Exercise the utmost caution in protecting the Username and Password. In case of doubt about the fraudulent use of the Username and Password, the User is invited to change his/her password on the Ziaway Technology Website;
          ▪ Use the Platform in accordance with its purpose as described in these Terms of Use
          ▪ Not to divert the purpose of the Platform to commit crimes, misdemeanors or contraventions punishable by the Penal Code or any other law
          ▪ Respect the intellectual property rights of Ziaway Technologie on the elements of the Platform and,where appropriate, the intellectual property rights of other Users (see Article 10 Intellectual Property)
          ▪ Do not use the Platform to send unsolicited mass messages (advertising or otherwise);
          ▪ Not to disseminate data that would diminish, disorganize, slow down or interrupt the normal operation of the Platform.
          `}
                            </p>

                            <p>
                                {" "}
                                In compliance with the legal and regulatory provisions in force,
                                the User undertakes not to disseminate any message or
                                information:
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                {`
          ▪ Constitute wrongful denigration of Ziaway Technology or other Users of the Platform;
          ▪ Contrary to public order and morality;
          ▪ Of an abusive, defamatory, racist, xenophobic or revisionist nature, or which is prejudicial to the honour or reputation of others;
          ▪ Inciting discrimination, hatred of a person or a group of persons because of their origin or their membership or non-membership of a particular ethnic group, nation, race or religion;
          ▪ Threatening a person or group of persons;
          ▪ Sexual in nature;
          ▪ Pedophile in nature;
          ▪ Inciting to commit an offence, a crime or an act of terrorism or advocating war crimes or crimes against humanity;
          ▪ Inciting to suicide;
          ▪ Allowing third parties to obtain directly or indirectly pirated software, software serial numbers, software allowing acts of piracy and intrusion into computer and telecommunications systems, viruses, and other logic bombs and in general any software or other tool allowing infringement of the rights of others and the security of persons and property.
          `}
                            </p>
                            <p>
                                The User guarantees Ziaway Technologie against any creation of a
                                false profile, and all the damages that could result, including
                                the production of false, use of false or misuse of personal data
                                processing.
                            </p>
                            <h3>ARTICLE 8.2 OBLIGATIONS OF ZIAWAY TECHNOLOGIE</h3>
                            <p>
                                The Platform agrees to do everything possible to ensure
                                continuity of access and use of the Platform 7 days a week and
                                24 hours a day, except during maintenance periods.
                            </p>
                            <p>
                                However, it draws the attention of the Users to the fact that
                                the current protocols of communication via Internet do not make
                                it possible to ensure in a certain and continuous way the
                                transmission of electronic exchanges (messages, documents,
                                identity of the transmitter or the recipient).
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 9. RESPONSIBILITY</h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Ziaway Technologie declines any responsibility in particular:
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                {`
          ▪ In case of impossibility to temporarily access the Platform for technical maintenance operations, malfunction or updating of published information. Users acknowledge that Ziaway Technologie shall not be held liable in the event of malfunctions or interruptions of said transmission networks;
          ▪ In case of virus attacks, illicit intrusion into an automated data processing system
          ▪ In case of abnormal use or illicit exploitation of the Platform by a User or a third party;
          ▪ Regarding the content of third-party websites to which hyperlinks on the Platform refer;
          ▪ In case of impersonation of the User. All access and actions carried out using the Username and Password transmitted to the User will be presumed to be carried out by the User, insofar as Ziaway Technologie is not obliged and does not have the technical means to ensure the real identity of persons accessing the Platform using the Username and Password.
          ▪ In case of failure to secure the User ID and Password by the User. Any loss, misappropriation, or unauthorized use of a User's Username and Password and their consequences are the sole responsibility of the User, who must notify Ziaway Technologie, without delay, by email (see Article 14; Questions);
          ▪ In the event of non-compliance with these Terms of Use attributable to Users ;
          ▪ In case of delay or non-performance of its obligations, when the cause of the delay or nonperformance is related to a case of force majeure as defined in Article 12 of these Terms of Use;
          ▪ In case of foreign cause not attributable to Ziaway Technologie ;
          ▪ In case of inaccuracy of the information provided by the Users on the Platform;
          ▪ In case of abnormal use or illicit exploitation of the Platform, the User is then solely responsible for damages caused to third parties and the consequences of claims or actions that could result from it.
          
          `}
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 10. INTELLECTUAL PROPERTY </h2>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The User acknowledges the intellectual property rights of Ziaway
                                Technologie on its name and the elements that make up its
                                identity as well as on its website, its elements, and the
                                contents related to it (graphic charts, tabs, features, brands,
                                images, logos, illustrations, photos, texts, etc.). In this
                                sense, the User is informed and warned not to contest these
                                rights in any form whatsoever.
                            </p>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                These various elements are the exclusive intellectual property
                                of Ziaway Technologie and may not be reproduced, used, or
                                represented without its express authorization, under penalty of
                                legal action. Acceptance of these Terms of Use is recognition by
                                Users of the property rights Ziaway Technology's intellectual
                                property rights and its commitment to respect them. Ziaway
                                Technology grants a personal, non-exclusive, and
                                non-transferable license to Users to use the Platform and the
                                information contained therein in accordance with these TOU
                            </p>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Any representation or reproduction, in whole or in part, of the
                                site and its contents, or of the elements that make up its brand
                                image, by any means whatsoever, without the prior express
                                permission of Ziaway Technologie, is prohibited and will
                                constitute a punishable offence.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 11. PERSONAL DATA </h2>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                Ziaway Technologie complies with current regulations on the
                                protection of personal data. The User of the platform is
                                informed that he/she has a number of rights regarding the
                                processing of his/her personal data (right of access,
                                opposition, rectification, deletion, portability, ...).
                            </p>

                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                These rights can be exercised: By e-mail to: support@ziaway.ca
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The User is invited to consult Ziaway Technology's Privacy
                                Policy to obtain further information regarding the processing of
                                personal data.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 12. FORCE MAJEURE </h2>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                The responsibility of Ziaway Technology Platform cannot be
                                implemented if the non-performance or delay in the performance
                                of any of its obligations described in these Terms of Use
                                results from a case of force majeure as defined by the
                                jurisprudence of courts and tribunals. In the event of the
                                occurrence of any of the above events, Ziaway Technologie will
                                endeavor to inform the User as soon as possible.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 13. SETTLEMENT OF DISPUTES</h2>
                            <h3>ARTICLE 13.1 JURISDICTION </h3>
                            <p>
                                These conditions are subject to Quebec law. Thus, any dispute,
                                challenge or litigation relating to these conditions will be
                                submitted to the courts of the domicile of the head office of
                                Ziaway Technologie, to which the Parties expressly attribute
                                jurisdiction, even in the event of a warranty claim and/or
                                plurality of defendants, notwithstanding any clause to the
                                contrary
                            </p>
                            <h3>ARTICLE 13.2 MEDIATOR </h3>
                            <p>
                                In the event of a dispute between the Ziaway Technologie
                                platform and the User regarding the interpretation, execution or
                                termination of these terms and conditions, the Parties shall
                                endeavor to settle the dispute amicably.
                            </p>
                            <p>
                                If necessary, the User may contact the Mediator designated by
                                Ziaway Technology. The mediation entity selected is the Quebec
                                City - Chaudière - Appalaches Community Justice Center.
                            </p>
                            <p>
                                In the event of a dispute, the User may file a complaint online
                                at https://www.justicedeproximite.qc.ca/centres/quebec/.
                            </p>
                            <p>
                                If the Mediation is unsuccessful, the User retains the
                                possibility to refer the matter to the judicial authority, that
                                is to say, to the geographically competent court within the
                                jurisdiction of Ziaway Technologie's headquarters.
                            </p>
                        </section>

                        <section>
                            <h2 className="mb-3">ARTICLE 14. QUESTIONS </h2>

                            <p>
                                Any question or claim concerning the present conditions and/or
                                the use of the platform can be addressed to
                            </p>
                            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                                {`▪ By e-mail to: support@ziaway.ca `}
                            </p>
                        </section>
                    </div>
            </>
        );

    return (
        <>
            <div className="container">
                    <h1 className="mb-4 text-primary">
                        CONDITIONS GÉNÉRALES D’UTILISATION
                    </h1>
                    <p>(Version mise à jour le 21/12/2022)</p>

                    <section>
                        <h2 className="mb-3">ARTICLE 1. DÉFINITIONS </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            {`
          ▪ Annonce : Annonce immobilière de vente ou de location ajoutée sur le Site par les Professionnels de l’immobilier partenaires
          ▪ Avis client : Appréciation des services du Professionnel de l’immobilier par ses clients, conformément aux présentes CGU, aux CGPS, et en particulier à la charte des propriétés vendues,
          ▪ Données personnelles : désigne toute information se rapportant, directement ou indirectement, à une personne physique identifiée ou identifiable.
          ▪ Partenaire : Tout Professionnel de l’immobilier ayant un abonnement gratuit ou payant auprès de Ziaway Technologie
          ▪ Professionnel de l’immobilier : Agent immobilier, mandataire immobilier ou tout professionnel utilisateur des Services.
          ▪ Professionnel de l’immobilier partenaire : Agent immobilier, mandataire immobilier ou tout professionnel utilisateur des Services à titre gratuit ou onéreux, et lié contractuellement avec Ziaway Technologie.
          ▪ Partie : désigne communément l’Utilisateur et le Prestataire.
          ▪ Services : désigne l’ensemble des services proposés par Ziaway Technologie aux utilisateurs par l’intermédiaire de la Plateforme
          ▪ Site : désigne la plateforme en ligne accessible à partir du Site web https://ziaway.ca/ par Ziaway Technologie et permettant de bénéficier des Services. La Plateforme regroupe l’ensemble des pages web et fonctionnalités proposées aux Utilisateurs.
          ▪ Utilisateur : désigne toute personne physique accédant et naviguant sur la plateforme https://ziaway.ca/
          ▪ Mot de passe : désigne le mot de passe associé à l’Identifiant. A partir de l’Identifiant et du Mot de passe, l’utilisateur pourra bénéficier des Services proposés sur la Plateforme.
          ".
          `}
                        </p>
                    </section>
                    <section>
                        <h2 className="mb-3">ARTICLE 2. OBJET</h2>
                        <p>
                            Les présentes Conditions Générales d’Utilisation (CGU) sont
                            applicables à l’utilisation du site web accessible à l’adresse
                            https://ziaway.ca/ ainsi que des services qui y sont proposés.
                        </p>
                        <h3>2.1. APPLICABILITÉ DES CGU</h3>
                        <p>
                            Les présentes CGU sont entrées pleinement en vigueur à leur date
                            de publication sur le Site https://ziaway.ca/ et s’appliquent
                            ainsi à tous les services proposés par Ziaway Technologie
                        </p>
                        <h3>2.2. OPPOSABILITÉ DES CGU</h3>
                        <p>
                            L’utilisation du site et des services proposés par
                            https://ziaway.ca/ implique l’adhésion définitive, entière et sans
                            réserve de l’Utilisateur aux présentes CGU. Aucune indication, ni
                            aucun document ne pourra engendrer d’obligation non comprise dans
                            les présentes CGU, s'il ne fait l'objet d'un nouvel accord entre
                            les Parties.
                        </p>
                        <p>
                            Le fait que l'une des Parties n'ait pas exigé l'application d'une
                            clause quelconque des présentes CGU, que ce soit de façon
                            permanente ou temporaire, ne pourra en aucun cas être considéré
                            comme une renonciation à ladite clause.
                        </p>
                    </section>
                    <section>
                        <h2 className="mb-3">
                            ARTICLE 3. ACCEPTATION DES CONDITIONS GÉNÉRALES D’UTILISATION
                        </h2>

                        <p>
                            L’Utilisateur s’engage à lire attentivement les présentes
                            Conditions Générales d’Utilisation (CGU) et est invité à les
                            imprimer, les télécharger et à en conserver une copie.
                            L’utilisation des fonctionnalités de la plateforme Ziaway
                            Technologie impliquent l’acceptation expresse des présentes
                            conditions. A ce titre, l’utilisateur reconnaît avoir été
                            parfaitement informé de la nature des services proposés par Ziaway
                            Technologie.
                        </p>
                        <p>
                            Il est précisé que les présentes conditions sont référencées en
                            bas de chaque page du site https://ziaway.ca/ au moyen d’un lien
                            hypertexte et peuvent ainsi être consultées à tout moment.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">
                            ARTICLE 4. MODIFICATION DES CONDITIONS GÉNÉRALES D’UTILISATION
                        </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Les présentes conditions s’appliquent à tous les utilisateurs du
                            site. Elles ont été mises à jour dernièrement le 21 décembre 2022.
                            Elles pourront être modifiées à tout moment, notamment pour faire
                            face aux évolutions législatives ou réglementaires. Une
                            notification pourra alors être adressée aux utilisateurs pour les
                            prévenir des modifications effectuées. En tout état de cause, les
                            conditions applicables sont celles en vigueur au moment de la
                            navigation sur le site.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">
                            ARTICLE 5. VALIDITÉ DES CONDITIONS GÉNÉRALES D’UTILISATION
                        </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Si l’une quelconque des stipulations des présentes conditions
                            venait à être déclarée nulle au regard d’une disposition
                            législative ou réglementaire en vigueur et/ou d’une décision de
                            justice ayant autorité de la chose jugée, elle sera réputée non
                            écrite mais n’affectera en rien la validité des autres
                            dispositions qui demeureront pleinement applicables
                        </p>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Une telle modification ou décision n’autorise en aucun cas les
                            clients et Utilisateurs à méconnaître les présentes conditions.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 6. CONTENUS DU SITE </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Le site a pour objet de présenter les Services et avantages
                            proposés par Ziaway Technologie et ses partenaires respectifs dans
                            le cadre des offres de services de Ziaway Technologie, telles que
                            décrites dans les pages du Site. Le site est une plateforme
                            offrant à l’Utilisateur plusieurs services d’informations
                            immobilières et d’aides à la vente ou à l’achat d’immeubles. Le
                            Site permet notamment d’estimer un bien immobilier en ligne, Il
                            permet également la mise en relation des Utilisateurs avec des
                            Professionnels de l’immobilier notamment en référençant des
                            Professionnels de l’immobilier partenaires.
                        </p>
                        <br />
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Les Services proposés sur le site sont susceptibles d’évolution.
                            Ziaway Technologie se réserve le droit d'ajouter, de supprimer ou
                            de modifier tout ou partie des espaces du site qu'il édite ou des
                            Services qu'il propose, sans préjudice de l'application
                            d'éventuelles conditions antérieurement conclues et sans préavis.
                        </p>
                        <br />
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ziaway Technologie s'efforce de vérifier et valider les
                            informations et données qu'il propose à la consultation sur le
                            Site. Il est ici précisé que les estimations immobilières sur le
                            Site sont des estimations automatisées calculées par
                            l'intermédiaire d'algorithmes. Il existe donc une part
                            d'incertitude qui concerne le résultat des estimations.
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ziaway Technologie ne prend aucune obligation quelconque liée à
                            l'exactitude des informations et des données, ou relative aux
                            utilisations qui seraient effectuées desdites informations et
                            données. Ziaway Technologie n'assure donc aucune garantie,
                            expresse ou tacite, concernant le contenu du Site ou le résultat
                            des estimations.
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            L’Utilisateur est informé et reconnaît que l’activité du site se
                            limite à faciliter les démarches et projets immobiliers ainsi que
                            la mise en relation des Utilisateurs avec les Professionnels de
                            l’immobilier. La responsabilité du Site ne saurait en aucune
                            manière être engagée dans le cadre des relations, accords, et
                            discussions susceptibles d’intervenir entre les Utilisateurs et
                            les Professionnels de l’immobilier et tout autre partenaire.
                        </p>
                        <br />
                        <h3>
                            ARTICLE 6.1 RÉFÉRENCEMENT DES PROFESSIONNELS DE L’IMMOBILIER SUR
                            LE SITE
                        </h3>
                        <p>
                            Ziaway Technologie effectue une activité de référencement en
                            permettant à l’Utilisateur d’accéder à un échantillon de
                            Professionnels de l’immobilier et une activité de comparaison en
                            classant les Professionnels de l’immobilier Partenaires.
                        </p>
                        <h3>ARTICLE 6.1.1 FONCTIONNEMENT DU RÉFÉRENCEMENT</h3>
                        <p>
                            Le référencement permet à l’Utilisateur d’accéder à un échantillon
                            de Professionnels de l’immobilier. Ces derniers sont référencés en
                            fonction des critères choisis par l’Utilisateur tels que le
                            dynamisme des Partenaires ou la satisfaction des Clients. Il
                            permet également à l’Utilisateur cherchant à vendre ou à acheter
                            une propriété d’accéder à un échantillon de biens similaires que
                            des Professionnels de l’immobilier ont vendu et choisi de publier
                            à proximité de l’adresse saisie. Les Professionnels de
                            l’immobilier référencés sur le Site sont Partenaires de Ziaway
                            Technologie. Ils ont souscrit à une offre de visibilité gratuite
                            ou payante leur permettant d’être affichés auprès des
                            utilisateurs.
                        </p>
                        <p>
                            Dans le but d’obtenir plus de fonctionnalités et d’augmenter les
                            informations affichées, les Professionnels de l’immobilier peuvent
                            choisir des Services adaptés moyennant une rémunération. Ziaway
                            Technologie ne détient aucune participation capitalistique des
                            Professionnels de l’immobilier qui sont présentés sur le Site et
                            aucun de ces Partenaires ne détient de participation dans le
                            capital de Ziaway Technologie.
                        </p>
                        <p>
                            En souscrivant aux différents Services de visibilité proposés par
                            Ziaway Technologie, les Professionnels de l’immobilier acceptent
                            de respecter les Conditions Générales de Prestations de Services
                            (CGPS).
                        </p>
                        <p>
                            Les Professionnels ne respectant pas les règles applicables
                            peuvent se voir déréférencés par Ziaway Technologie. Ils
                            n’apparaîtront plus sur les différentes listes. Le déréférencement
                            peut être provisoire et ne durer que pendant la période de
                            non-conformité aux dispositions établies.
                        </p>

                        <h3>ARTICLE 6.1.2 MENTIONS « COMPARATEUR </h3>
                        <p>
                            ZIAWAY propose des Services ayant vocation à faciliter les
                            démarches de l’Utilisateur dans le cadre de ses projets
                            immobiliers. A cet effet, le Site permet leur mise en relation
                            avec des Professionnels et met à la disposition des Utilisateurs
                            des comparaisons objectives des différents Professionnels de
                            l’immobilier partenaires. Tout professionnel ayant souscrit une
                            offre est lié à Ziaway Technologie par les Conditions Générales de
                            Prestations de Services (CGPS) applicables au moment de leur
                            souscription.
                        </p>
                        <p>
                            Dans le but d’obtenir plus de fonctionnalité, les Professionnels
                            de l’immobilier peuvent souscrire à différents Services, moyennant
                            une rémunération adaptée en fonction du Service choisi. Seuls les
                            Professionnels de l’immobilier Partenaires de Ziaway Technologie
                            sont comparés. La liste des professionnels comparés n’est pas
                            exhaustive. Néanmoins, le nombre important de Partenaires permet à
                            Ziaway Technologie de présenter un large choix de professionnels.
                        </p>
                        <p>
                            Ziaway Technologie ne détient aucune participation capitalistique
                            des Professionnels de l’immobilier dont les services sont comparés
                            sur le Site et aucun de ces Partenaires ne détient de
                            participation dans le capital de Ziaway Technologie
                        </p>
                        <p>
                            Ziaway Technologie propose quatre types de comparateurs sur le
                            Site :
                        </p>

                        <p className="font-weight-bold">
                            {" "}
                            {`
          ◼ CLASSEMENT SELON LE DYNAMISME
         
          `}
                        </p>
                        <p>
                            La comparaison entre les Professionnels de l’immobilier s’effectue
                            en fonction des ventes qu’ils ont réalisées et publiées sur Ziaway
                            Technologie au cours des 12 derniers mois dans un secteur
                            géographique déterminé. L’étendue de ce secteur géographique varie
                            en fonction de sa densité de population. Les Professionnels ayant
                            vendu et publié le plus de propriétés sont situés en haut du
                            classement.
                        </p>
                        <p className="font-weight-bold">
                            {" "}
                            {`
          ◼ CLASSEMENT SELON LA PERTINENCE
         
          `}
                        </p>
                        <p>
                            La comparaison entre les Professionnels de l’immobilier s’effectue
                            en fonction de leur performance pour vendre une propriété
                            similaire et proche de l’adresse saisie. La similarité de la
                            propriété prend en compte l’emplacement, le type de propriété, le
                            nombre de pièces, la surface ainsi que la date de vente. Plus un
                            professionnel a vendu et publié des biens similaires et proches de
                            l’adresse, mieux il est classé.
                        </p>
                        <p className="font-weight-bold">
                            {" "}
                            {`
          ◼ CLASSEMENT SELON LA DISTANCE
         
          `}
                        </p>
                        <p>
                            La comparaison entre les Professionnels de l’immobilier s’effectue
                            en fonction de la distance avec l’adresse saisie. Le classement
                            s’effectue du professionnel ayant vendu des propriétés le plus
                            proche de l’adresse saisie au professionnel ayant vendu les plus
                            éloignés. Le classement n’est pas influé par une quelconque
                            rémunération de la part des Professionnels de l’immobilier. Ces
                            différents classements disponibles sur le Site sont
                            continuellement mis à jour. Ziaway Technologie s’efforce d’offrir
                            un classement en temps réel en intégrant chaque donnée transmise
                            par les Partenaires ou leurs Clients dans le système permettant la
                            comparaison.
                        </p>

                        <h3>ARTICLE 6.2 AVERTISSEMENT</h3>
                        <p>
                            Ziaway Technologie a pour but de vous présenter, sans
                            exhaustivité, diverses informations utiles et divers partenaires
                            pour la vente ou l’acquisition de votre bien immobilier.
                            Néanmoins, faute de connaître précisément votre projet immobilier
                            et faute de pouvoir connaître l’ensemble de nos partenaires, il
                            vous appartient de faire toutes vérifications qui vous semblent
                            nécessaires ou opportunes avant de contacter un des Professionnels
                            de l’immobilier référencés sur notre Site.
                        </p>
                        <p>
                            Ziaway Technologie ne garantit aucunement et de quelque façon que
                            ce soit les informations publiées. Compte tenu de la grande
                            diversité des Partenaires présents sur notre Site, Ziaway
                            Technologie ne garantit aucunement et de quelque façon que ce soit
                            les pratiques commerciales et les services proposés par les
                            Professionnels de l’immobilier, et plus généralement de tous
                            tiers, présents sur le Site
                        </p>
                        <p>
                            En conséquence, la mise en relation avec un Professionnel de
                            l’immobilier, un promoteur immobilier ou, plus généralement avec
                            tout tiers, par l’intermédiaire de notre Site s’effectue sous
                            votre propre responsabilité sans garantie de notre part quant à la
                            satisfaction des services proposés.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">
                            ARTICLE 7. PUBLICATION DES COMMENTAIRES ET AVIS SUR LA PLATEFORME
                        </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Les Utilisateurs peuvent publier des commentaires sur le blog de
                            Ziaway Technologie sous leur seule responsabilité éditoriale.
                            L'Utilisateur est seul responsable des commentaires. Ziaway
                            Technologie dispose de la faculté de supprimer tout commentaire ou
                            Avis client ou notation, sans que cette suppression ne puisse
                            engager sa responsabilité civile ou pénale.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 8. OBLIGATIONS DES PARTIES</h2>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Dans le cadre de l’utilisation de la Plateforme Ziaway
                            Technologie, chaque Utilisateur s’engage à ne pas porter atteinte
                            à l’ordre public et à se conformer aux lois et règlements en
                            vigueur et à respecter les dispositions des présentes Conditions
                            Générales d’Utilisation. L’obligation générale de Ziaway
                            Technologie est une obligation de moyens. Il ne pèse sur celle-ci
                            aucune obligation de résultat ou de moyens renforcée d’aucune
                            sorte
                        </p>
                        <h3>ARTICLE 8.1 OBLIGATIONS DES UTILISATEURS</h3>
                        <p>Chaque Utilisateur a pour obligation de :</p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            {`
          ▪ Faire preuve de la plus grande prudence quant à la protection de l’Identifiant et du Mot de passe. En cas de doute sur un usage frauduleux de l’Identifiant et du Mot de passe, l’Utilisateur est invité à changer son mot de passe sur le Site.de Ziaway Technologie ;
          ▪ Utiliser la Plateforme conformément à son objet tel que décrit dans les présentes Conditions Générales d’Utilisation ;
          ▪ Ne pas détourner la finalité de la Plateforme pour commettre des crimes, délits où contraventions réprimées par le Code pénal ou par toute autre loi ;
          ▪ Respecter les droits de propriété intellectuelle de Ziaway Technologie portant sur les éléments de la Plateforme et le cas échéant, les droits de propriété intellectuelle des autres Utilisateurs (cf article 10 Propriété intellectuelle) ;
          ▪ Ne pas utiliser la Plateforme pour envoyer massivement des messages non sollicités (publicitaires ou autres) ;
          ▪ Ne pas diffuser des données ayant pour effet de diminuer, de désorganiser, de ralentir où d’interrompre le fonctionnement normal de la Plateforme
          `}
                        </p>

                        <p>
                            {" "}
                            Dans le respect des dispositions légales et réglementaires en
                            vigueur, l’Utilisateur s’engage à ne pas diffuser de message où
                            d’information :
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            {`
          ▪ Constitutifs de dénigrement fautif visant Ziaway Technologie ou les autres Utilisateurs de la Plateforme ;
          ▪ Contraires à l’ordre public et aux bonnes mœurs ;
          ▪ À caractère injurieux, diffamatoire, raciste, xénophobe, révisionniste ou portant atteinte à l'honneur ou à la réputation d'autrui ;
          ▪ Incitant à la discrimination, à la haine d'une personne ou d'un groupe de personnes à raison de leur origine ou de leur appartenance ou de leur non-appartenance à une ethnie, une nation, une race ou une religion déterminée ;
          ▪ Menaçant une personne ou un groupe de personnes ;
          ▪ A caractère sexuel ;
          ▪ À caractère pédophile ;
          ▪ Incitant à commettre un délit, un crime ou un acte de terrorisme ou faisant l'apologie des crimes de guerre ou des crimes contre l'humanité ;
          ▪ Incitant au suicide ;
          ▪ Permettant à des tiers de se procurer directement ou indirectement des logiciels piratés, des numéros de série de logiciels, des logiciels permettant des actes de piratage et d'intrusion dans les systèmes informatiques et de télécommunications, des virus et autres bombes logiques et d'une manière générale tout outil logiciel ou autre permettant de porter atteinte aux droits d'autrui et à la sécurité des personnes et des biens.
          `}
                        </p>
                        <p>
                            L’Utilisateur garantit Ziaway Technologie contre toute création
                            d’un faux profil, et de l’ensemble des préjudices qui pourraient
                            en résulter, et notamment concernant la production de faux,
                            l’usage de faux ou le détournement de finalité des traitements de
                            données à caractère personnel.
                        </p>
                        <h3>ARTICLE 8.2 OBLIGATIONS DE ZIAWAY TECHNOLOGIE</h3>
                        <p>
                            La Plateforme s’engage à mettre tous les moyens en œuvre pour
                            assurer une continuité d’accès et d’utilisation de la Plateforme 7
                            jours sur 7 et 24 heures sur 24 hors périodes de maintenance
                        </p>
                        <p>
                            Elle attire toutefois l’attention des Utilisateurs sur le fait que
                            les protocoles actuels de communication via Internet ne permettent
                            pas d’assurer de manière certaine et continue la transmission des
                            échanges électroniques (messages, documents, identité de
                            l’émetteur ou du destinataire)
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 9. RESPONSABILITÉ </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ziaway Technologie décline toute responsabilité notamment :
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            {`
          ▪ En cas d’impossibilité d’accéder temporairement à la Plateforme pour des opérations de maintenance technique, de dysfonctionnement ou d’actualisation des informations publiées. Les Utilisateur reconnaissent que la responsabilité de Ziaway Technologie ne saurait être engagée en cas de dysfonctionnements ou d’interruptions desdits réseaux de transmission ;
          ▪ En cas d’attaques virales, intrusion illicite dans un système de traitement automatisé de données
          ▪ En cas d’utilisation anormale ou d’une exploitation illicite de la Plateforme par un Utilisateur ou un tiers ;
          ▪ Relativement au contenu des sites internet tiers vers lesquels renvoient des liens hypertextes présents sur la Plateforme ;
          ▪ En cas d’usurpation de l’identité de l’Utilisateur. Tout accès et actions effectués à partir de l’Identifiant et du Mot de passe transmis à l’Utilisateur sera présumé être effectué par cet Utilisateur, dans la mesure où Ziaway Technologie n’a pas pour obligation et ne dispose pas des moyens techniques lui permettant de s’assurer de l’identité réelle des personnes ayant accès à la Plateforme à partir de l’Identifiant et du Mot de passe.
          ▪ En cas de défaut de sécurisation de l’Identifiant et du Mot de passe par l’Utilisateur. Toute perte, détournement, ou utilisation non autorisée de l’Identifiant et du Mot de passe d’un Utilisateur et leurs conséquences relèvent de la seule responsabilité de l’Utilisateur, ce dernier étant tenu d’en avertir Ziaway Technologie, sans délai, par courriel (cf article 14 ; Questions) ;
          ▪ En cas de non-respect des présentes Conditions Générales d’Utilisation imputable aux Utilisateurs ;
          ▪ En cas de retard ou d’inexécution de ses obligations, lorsque la cause du retard ou de l’inexécution est liée à un cas de force majeure telle qu’elle est définie à l’article 12 des présentes Conditions Générales d’Utilisation ;
          ▪ En cas de cause étrangère non imputable à Ziaway Technologie ;
          ▪ En cas d’inexactitude des informations renseignées par les Utilisateurs sur la Plateforme ;
          ▪ En cas d’utilisation anormale ou d’une exploitation illicite de la Plateforme, l’Utilisateur est alors seul responsable des dommages causés aux tiers et des conséquences des réclamations ou actions qui pourraient en découler
          `}
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 10. PROPRIÉTÉ INTELLECTUELLE </h2>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            L’Utilisateur reconnaît les droits de propriété intellectuelle de
                            Ziaway Technologie sur sa dénomination et les éléments composants
                            son identité ainsi que sur son Site web, ses éléments, et les
                            contenus y afférant (chartes graphiques, onglets, fonctionnalités,
                            marques, images, logos, illustrations, photos, textes, etc, ...).
                            En ce sens, l’Utilisateur est informé et avertit à ne pas
                            contester ces droits sous quelques formes que ce soit.
                        </p>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ces différents éléments sont de la propriété intellectuelle
                            exclusive de Ziaway Technologie et ne peuvent être reproduits,
                            utilisés ou représentés sans autorisation expresse de celle-ci
                            sous peine de poursuites judiciaires. L’acceptation des présentes
                            CGU vaut reconnaissance par les Utilisateurs des droits de
                            propriété Intellectuelle de Ziaway Technologie et engagements à
                            les respecter. Ziaway Technologie accorde une licence personnelle,
                            non-exclusive et non cessible aux Utilisateurs les autorisant à
                            utiliser la Plateforme et les informations qu’elle contient
                            conformément aux présentes CGU.
                        </p>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Toute représentation ou reproduction, totale ou partielle, du site
                            et de son contenu, ou des éléments composant son image de marque,
                            par quelques procédés que ce soit, sans l’autorisation préalable
                            expresse de Ziaway Technologie, est interdite et constituera une
                            contrefaçon sanctionnée.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 11. DONNÉES PERSONNELLES </h2>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ziaway Technologie respecte la réglementation en vigueur sur la
                            protection des données à caractère personnel. L’Utilisateur de la
                            plateforme est informé du fait qu’il dispose d’un certain nombre
                            de droits sur le traitement de ses données personnelles (droit
                            d’accès, d’opposition, de rectification, d’effacement, de
                            portabilité, ...).
                        </p>

                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            Ces droits peuvent être exercés : Par courrier électronique à :
                            support@ziaway.ca
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            L’Utilisateur est invité à consulter la Politique de
                            Confidentialité de Ziaway Technologie afin d’obtenir de plus
                            amples informations relatives au traitement de ces données
                            personnelles.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 12. FORCE MAJEURE </h2>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            La responsabilité de la Plateforme Ziaway Technologie ne pourra
                            pas être mise en œuvre si la nonexécution ou le retard dans
                            l’exécution de l’une de ses obligations décrites dans les
                            présentes Conditions Générales d’Utilisation découle d’un cas de
                            force majeure tel que défini par la jurisprudence des cours et
                            tribunaux. En cas de survenance d’un des évènements susvisés,
                            Ziaway Technologie s’efforcera d’informer l’Utilisateur dès que
                            possible.
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 13. RÈGLEMENT DES LITIGES</h2>
                        <h3>ARTICLE 13.1 JURIDICTION</h3>
                        <p>
                            Les présentes conditions sont soumises au Droit québécois. Ainsi,
                            tout différends, contestations, litiges relatifs aux présentes
                            conditions seront soumis aux tribunaux du domicile du siège social
                            de Ziaway Technologie à qui les Parties attribuent expressément
                            juridiction, et ce même en cas d’appel en garantie et/ou de
                            pluralités de défendeurs et ce nonobstant toutes clauses
                            contraires
                        </p>
                        <h3>ARTICLE 13.2 MÉDIATEUR </h3>
                        <p>
                            REn cas de différend survenant entre la plateforme Ziaway
                            Technologie et l’Utilisateur au sujet de l’interprétation, de
                            l’exécution ou de la résiliation des présentes conditions, les
                            Parties s’efforceront de le régler à l’amiable
                        </p>
                        <p>
                            Au besoin, l’Utilisateur pourra s’adresser au Médiateur désigné
                            par Ziaway Technologie. L’entité de médiation retenue est le
                            centre de justice de proximité Québec – Chaudière – Appalaches.
                        </p>
                        <p>
                            En cas de différends, l’Utilisateur pourra déposer sa réclamation
                            en ligne sur le site :
                            https://www.justicedeproximite.qc.ca/centres/quebec/.
                        </p>
                        <p>
                            Dans le cas où la Médiation se révélerait être un échec,
                            l’Utilisateur conserve la possibilité de saisir l’autorité
                            judiciaire, soit le tribunal géographiquement compétent dans le
                            ressort du siège sociale Ziaway Technologie
                        </p>
                    </section>

                    <section>
                        <h2 className="mb-3">ARTICLE 14. QUESTIONS </h2>

                        <p>
                            Toute question ou réclamation concernant les présentes conditions
                            et/ou l’utilisation de la plateforme pourront être adressées :
                        </p>
                        <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                            {`▪ Par courrier électronique à : support@ziaway.ca  `}
                        </p>
                    </section>
                </div>
        </>
    );
};

export default TermOfService;
