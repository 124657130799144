const getPlaceDetail = async (
    prediction,
    placesService,
    setValue,
    placeToAddress,
    followUpFunc,
    setFieldValue,
    setShowPredictions
) => {
    try {
        await placesService.getDetails(
            { placeId: prediction.place_id },
            (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setValue(prediction.description);
                    console.log("place", place);
                    console.log("placeToAddress", placeToAddress(place));
                    setFieldValue("address", placeToAddress(place));
                    if (followUpFunc) followUpFunc(placeToAddress(place));
                    setShowPredictions(false);
                }
            }
        );
    } catch (e) {
        console.log("error", e);
    }
};

export default getPlaceDetail;
