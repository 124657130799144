import actions from './actions'

const initialState = {
    recentEstimates: [],
    postalCode: '',
    address: {},
    evaluerBien: {},
    ficheEstimation: {},
    redirectTo: '',
    verificationCode: false,
}

export default function estimationReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
