import React from "react";
import { Link } from "react-router-dom";

const dataArray = [
  {
    title: "La localisation de la propriété",
    description: `Critère primordial : où se situe le bien. En effet, la ville ou le quartier dans lequel se situe
votre patrimoine immobilier, ainsi que son environnement, joue un rôle crucial sur sa valeur
économique. La propriété est-elle en ville ou à la campagne ? Bénéficie-t-elle d’une vue
spectaculaire ? Est-elle au bord de l’eau avec un accès privé ? Les services essentiels, les
écoles, les transports en commun sont-ils à proximité ? Tous ces éléments sont à prendre
en compte et impactent le résultat final.`,

    imageUrl:
      "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Le type de bien",
    description: `Le type de propriété représente l’une des bases de l’évaluation immobilière ayant un impact
important. S’agit-il d’un condo, d’un appartement, d’un loft, d’une maison unifamiliale, d’une
maison jumelée, d’une maison de plain-pied, d’une maison à étages, etc. Bien entendu,
certains types de logements sont plus recherchés que d’autres. En conséquence, ceux-
ci auront une valeur plus élevée sur le marché.`,

    imageUrl:
      "https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Les caractéristiques intérieures et extérieures",
    description: `Lors de votre évaluation immobilière avec Ziaway, c’est l’ensemble de votre propriété qui
sera examinée. Qu’il s’agisse de l’intérieur comme de l’extérieur. En effet, c’est la
combinaison de ces éléments qui permettra d’affiner la valeur marchande de votre bien. Par
exemple, il est demandé de renseigner des informations telles que : le nombre de pièces et
leur configuration, la superficie totale, la surface habitable, le nombre d'étages, la présence
d’une piscine, d’un garage, d’un balcon, etc...`,
    imageUrl:
      "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "L’état général et l’historique",
    description: `En sa qualité d’évaluateur maison professionnel, Ziaway prend en compte l’état général

de votre bien pour améliorer et rendre plus fiable votre estimation. Votre maison est-
elle en bon ou mauvais état ? Il y a-t-il des problèmes à remonter ? Quel est le degré de

dégradation de votre propriété ? Avez-vous réalisé des rénovations ces dernières années ?
Toutes ces questions permettent à notre outil de vous fournir une évaluation immobilière
plus précise.`,
    imageUrl:
      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Le marché immobilier actuel",
    description: `Pour toutes les estimations de valeur marchande, le contexte du marché actuel est
évidemment un facteur substantiel. Effectivement, l’offre et la demande jouent un rôle
décisif dans le résultat final. C’est bien pour cela que le prix des biens immobiliers
semblables peut varier d’une période à une autre, ou encore, d’une localisation à une autre.`,
    imageUrl:
      "https://images.unsplash.com/photo-1598228723793-52759bba239c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const ImmobiliereSectionSix = () => {
  return (
    <section>
      <div className="container py-5">
        <div className="text-center">
          <h2 className="mb-4 max-w-2 mx-auto font-poppins font-weight-600">
            ÉVALUATION IMMOBILIÈRE : LES CRITÈRES QUI INFLUENCENT LA VALEUR
            D’UNE PROPRIÉTÉ
          </h2>
          <p className="text-16">
            Comme aperçu rapidement en amont, les évaluations immobilières
            s’appuient sur plusieurs critères et facteurs. Ceux-ci permettent de
            définir la valeur marchande de votre maison, appartement ou
            propriété. Chaque caractéristique peut entraîner des répercussions,
            à la hausse ou à la baisse, sur le résultat final de votre
            estimation.
          </p>
          <p className="text-16">
            Ci-dessous, vous trouverez une liste des éléments principaux ayant
            un impact sur la valeur réelle de votre bien.
          </p>
        </div>
      </div>

      <div className="py-5" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="container">
          <div className="d-flex flex-column" style={{ rowGap: "3.5rem" }}>
            {dataArray.map((data, index) => (
              <div
                key={index}
                className="row row-cols-1 row-cols-lg-2 align-items-center"
                style={{ rowGap: "2rem" }}
              >
                <div className="col">
                  <div className={index % 2 === 0 ? "pr-xl-4" : "pl-xl-4"}>
                    <h3 className="mb-3 font-poppins font-weight-600">
                      {data.title}
                    </h3>
                    <p>{data.description}</p>
                    <div className="mt-4">
                      <Link
                        to="/homepage"
                        className="btn btn-outline-primary font-poppins font-weight-500 px-3 py-2 rounded-lg"
                      >
                        Estimer ma propriété
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className={`col ${
                    index % 2 === 0
                      ? "order-first order-lg-last"
                      : "order-first"
                  }`}
                >
                  <div className="h-100 w-100">
                    <img
                      className="d-block h-100 w-100 rounded-2"
                      src={data.imageUrl}
                      alt=".."
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionSix;
