import React, {useContext} from "react";
import {Accordion, Container, Card, Button, Tab, Tabs, AccordionContext, useAccordionButton} from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey
    );
    const isCurrentEventKey = currentEventKey?.activeEventKey === eventKey;

    return (
        <>
            <Card.Header onClick={decoratedOnClick}
                         className="px-0 bg-white d-flex justify-content-between cursor-pointer">
                <span className="pt-1">{children}</span>
                <i className={isCurrentEventKey ? "i-Arrow-Up":"i-Arrow-Down"} />
            </Card.Header>

        </>
    );
}

const FaqPageLogIn = () => {
    const {t} = useTranslation();

    const data = [
        {
            title: `${t("faq.2")}`,
            tabs: [
                {
                    subtitle: `${t("faq.3")}`,
                    text: `${t("faq.4")}`,
                },
                {
                    subtitle: `${t("faq.5")}`,
                    text: `${t("faq.6")}`,
                },
                {
                    subtitle: `${t("faq.7")}`,
                    text: `${t("faq.8")}`,
                },
            ],
        },
        {
            title: `${t("faq.9")}`,
            tabs: [
                {
                    subtitle: `${t("faq.10")}`,
                    text: `${t("faq.11")}`,
                },
                {
                    subtitle: `${t("faq.12")}`,
                    text: `${t("faq.13")}`,
                },
                {
                    subtitle: `${t("faq.14")}`,
                    text: `${t("faq.15")}`,
                },
            ],
        },
        {
            title: `${t("faq.16")}`,
            tabs: [
                {
                    subtitle: `${t("faq.17")}`,
                    text: `${t("faq.18")}`,
                },
                {
                    subtitle: `${t("faq.19")}`,
                    text: `${t("faq.20")}`,
                },
                {
                    subtitle: `${t("faq.21")}`,
                    text: `${t("faq.22")}`,
                },
            ],
        },
        {
            title: `${t("faq.23")}`,
            tabs: [
                {
                    subtitle: `${t("faq.24")}`,
                    text: `${t("faq.25")}`,
                },
                {
                    subtitle: `${t("faq.26")}`,
                    text: `${t("faq.27")}`,
                },
                {
                    subtitle: `${t("faq.28")}`,
                    text: `${t("faq.29")}`,
                },
                {
                    subtitle: `${t("faq.30")}`,
                    text: `${t("faq.31")}`,
                },
                {
                    subtitle: `${t("faq.32")}`,
                    text: `${t("faq.33")}`,
                },
            ],
        },
        {
            title: `${t("faq.34")}`,
            tabs: [
                {
                    subtitle: `${t("faq.35")}`,
                    text: `${t("faq.36")}`,
                },
                {
                    subtitle: `${t("faq.37")}`,
                    text: `${t("faq.38")}`,
                },
                {
                    subtitle: `${t("faq.39")}`,
                    text: `${t("faq.40")}`,
                },
                {
                    subtitle: `${t("faq.41")}`,
                    text: `${t("faq.42")}`,
                },
                {
                    subtitle: `${t("faq.43")}`,
                    text: `${t("faq.44")}`,
                },
            ],
        },
    ];
    return (
        <Container>
            <div className="py-5 faq-tabs fade-in-bottom">
                <div>
                    <h1 className="d-none d-md-block mb-4 text-center">
                        {t("faq.1")}
                    </h1>
                    <h2 className="d-md-none mb-4 text-center">{t("faq.1")}</h2>
                </div>
                <Tabs defaultActiveKey="0" className="white-space-nowrap">
                    {data.map((v, id) => (
                        <Tab
                            style={{ height: "auto" }}
                            key={id}
                            eventKey={id}
                            title={v.title}
                        >
                            {v.tabs.map((data, id) => (
                                <Accordion key={id} defaultActiveKey="0">
                                    <Card className={`shadow-none bg-white border-bottom px-4`}>
                                        <ContextAwareToggle eventKey={data.subtitle}>
                                            {data.subtitle}
                                        </ContextAwareToggle>
                                        <Accordion.Collapse
                                            className="fade-in-left"
                                            eventKey={data.subtitle}
                                        >
                                            <Card.Body className="px-0">
                                                <div>
                                                    <p>{data.text}</p>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            ))}
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </Container>
    );
};

export default FaqPageLogIn;
