import React from "react";
import { Link } from "react-router-dom";
import ficon1 from "assets/images/f-icon-1.png";
import ficon2 from "assets/images/f-icon-2.png";
import ficon3 from "assets/images/f-icon-3.png";
import ficon4 from "assets/images/f-icon-4.png";
import ficon5 from "assets/images/f-icon-5.png";
import ficon6 from "assets/images/f-icon-6.png";

const data = [
  {
    icon: ficon1,
    content:
      "Avant tout, il faut comprendre que l’évaluation foncière (ou évaluation municipale) est un processus d’estimation réalisé par les municipalités. Celle-ci permet aux institutions d’établir le montant des taxes foncières dont les propriétaires devront avoir la charge annuellement.",
  },
  {
    icon: ficon2,
    content:
      "Lors d’une demande d’évaluation foncière, les municipalités font appel à des évaluateurs agréés qui n’estiment que sommairement les propriétés.",
  },
  {
    icon: ficon3,
    content:
      "En conséquence, les résultats obtenus lors d’une évaluation foncière ne reflètent pas de manière fiable la réalité du marché immobilier actuel. Ainsi, si vous avez le projet de vendre votre maison ou votre bien immobilier, il est déconseillé de s’appuyer sur la valeur foncière pour fixer votre prix de vente.",
  },
  {
    icon: ficon4,
    content: `À contrario, l’évaluation marchande ou évaluation immobilière représente un processus
d’estimation beaucoup plus précis et fiable. En effet, elle vous permet de connaître la
véritable valeur de votre bien sur le marché. En d’autres termes, celle-ci vous permet
d’obtenir le prix de vente le plus probable pour une transaction réussie.`,
  },
  {
    icon: ficon5,
    content: `Pour parvenir à une valeur économique juste et fiable de votre maison, appartement ou tout
autre bien immobilier, Ziaway prend en compte de nombreux facteurs. En sa qualité
d’évaluateur maison et biens, nous considérons non seulement l’intérieur et l’extérieur de
votre propriété, son terrain, sa localisation, son état et son âge.`,
  },
  {
    icon: ficon6,
    content: `De plus, nous prenons en compte la réalité actuelle du marché immobilier au Québec. En
effet, toutes les évaluations immobilières sont influencées par l’offre et la demande de
maisons ou logements dans votre secteur.`,
  },
];

const ImmobiliereSectionThree = () => {
  return (
    <section className="py-5 bg-gray-100">
      <div className="container">
        <h3 className="mb-4 mb-lg-5 text-center font-poppins font-weight-600">
          Quelle différence avec l’évaluation foncière ?
        </h3>
        <div
          className="row row-cols-1 row-cols-lg-2"
          style={{ rowGap: "1.5rem" }}
        >
          {data.map((item, index) => (
            <div className="col" key={index}>
              <div className="d-flex">
                <div className="icon-box-2 mr-4">
                  <img
                    className="d-block h-100 w-100"
                    src={item.icon}
                    alt="..."
                  />
                </div>
                <p className="mb-0">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 text-center">
          <Link
            to="/homepage"
            className="btn btn-primary font-poppins rounded-2 px-4 py-3 font-weight-500 text-16"
          >
            Estimer ma propriété
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionThree;
