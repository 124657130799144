import React from "react";
import { Link } from "react-router-dom";
import UserOctagonIcon from "../../../components/icons/UserOctagonIcon";
import SmartHomeIcon from "../../../components/icons/SmartHomeIcon";
import image1 from "../../../assets/images/homepage/image-1.jpg"
const WhoAreWeSection = () => {
    return (
        <section
            className="whoarewe-section mt-lg-4 pt-5 pb-5"
            style={{ backgroundColor: "#FAFAFA" }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-5">
                        <div>
                            <div className="sub-title text-primary mb-3">
                                Qui sommes-nous ?
                            </div>
                            <h2 className="heading-2 mb-4">
                                ÉVALUATION MAISON EN LIGNE AVEC ZIAWAY : DES PROFESSIONNELS À
                                VOTRE SERVICE
                            </h2>
                            {/* <h2 className=" mb-4">
                Des professionnels à votre écoute qui... Lorem ipsum lorem ipsum
              </h2> */}
                            <p>
                                Chez Ziaway, nous mettons en action toute notre expertise et nos
                                compétences pour que vos projets soient couronnés de succès.
                                Notre objectif est de vous offrir une estimation gratuite, sans
                                engagement et ultra rapide pour votre logement.
                            </p>
                            <p>
                                Notre équipe est exclusivement constituée de professionnels du
                                secteur immobilier au Québec. Sélectionnés avec soin selon leurs
                                accréditations, certifications et permis, nos collaborateurs
                                sont reconnus pour leur expertise, leurs compétences et leur
                                réputation.
                            </p>
                            <p>
                                Grâce à tous ces paramètres, Ziaway représente un évaluateur de
                                confiance pour tous vos projets immobiliers : vente, achat, mise
                                en location ou simplement curiosité.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7">
                        <div className="h-100 w-100">
                            <img
                                className="d-block h-100 w-100 object-contain"
                                src={image1}
                                alt="...."
                            />
                        </div>
                    </div>
                </div>
                {/* items box */}
                <div className="mt-4 text-center">
                    <div
                        className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center"
                        style={{ rowGap: "1.8rem" }}
                    >
                        <div className="col">
                            <div className="card-4 h-100 p-4">
                                <div className="text-center">
                  <span>
                    <SmartHomeIcon />
                  </span>
                                    <div className="mt-2">
                                        <h3 className="text-primary font-poppins font-weight-500">
                                            Un service de qualité supérieure
                                        </h3>
                                        <p className="mb-0 font-poppins">
                                            Nous offrons des services de qualité et de confiance pour
                                            toutes les évaluations et estimations. Rapidité,
                                            transparence, liberté, écoute et professionnalisme.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-4 h-100 p-4">
                                <div className="text-center">
                  <span>
                    <UserOctagonIcon />
                  </span>
                                    <div className="mt-2">
                                        <h3 className="text-primary font-poppins font-weight-500">
                                            Des estimations immédiates
                                        </h3>
                                        <p className="mb-0 font-poppins">
                                            Nous sommes la seule entreprise capable de fournir des
                                            estimations immobilières instantanément. Notre force
                                            réside dans cette différence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-4 h-100 p-4">
                                <div className="text-center">
                  <span>
                    <UserOctagonIcon />
                  </span>
                                    <div className="mt-2">
                                        <h3 className="text-primary font-poppins font-weight-500">
                                            Des professionnels certifiés
                                        </h3>
                                        <p className="mb-0 font-poppins">
                                            Nos collaborateurs sont triés sur le volet selon des
                                            critères stricts. Tous sont reconnus dans leur domaine et
                                            leur secteur d’expertise.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link
                        to
                        className="mt-5 block font-poppins rounded-2 p-3 btn btn-primary font-weight-500 text-16"
                    >
                        Estimer ma propriété
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default WhoAreWeSection;
