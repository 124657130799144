import React from "react";

const AdressField = ({
                         getPlacePredictions,
                         isPlacePredictionsLoading,
                         setShowPredictions,
                         value,
                         setValue,
                         adressInputRef,
                     }) => {
    const getPredictions = async (input) => {
        try {
            getPlacePredictions({
                input: input,
                componentRestrictions: { country: "ca" },
                types: ["address"],
            });
        } catch (e) {
            console.log("error", e);
        }
    };

    return (
        <div
            style={{
                width: "100%",
            }}
            ref={adressInputRef}
        >
            <input
                className="form-control rounded-2 py-4 font-poppins"
                value={value}
                placeholder="Saisissez l’adresse de la propriété à évaluer..."
                onChange={async (evt) => {
                    setValue(evt.target.value);
                    getPredictions(evt.target.value);
                }}
                loading={isPlacePredictionsLoading}
                onFocus={() => setShowPredictions(true)}
            />
        </div>
    );
};

export default AdressField;
