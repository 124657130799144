import { SET_DEFAULT_LAYOUT_SETTINGS, SET_LAYOUT_SETTINGS } from "./actions";

const layout1Settings = {
  leftSidebar: {
    open: true,
    secondaryNavOpen: false,
    theme: "sidebar-dark-purple",
  },
  header: {
    show: true,
    fixed: true,
    theme: "slateDark1", // View all valid theme colors inside GullTheme/themeColors.js
  },
  searchBox: {
    open: false,
  },
};

const privateLayoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  dir: "ltr", // ltr, rtl
  layout1Settings,
  customizer: {
    show: false,
    open: false,
  },
  footer: {
    show: true,
  },
};

const initialState = {
  settings: {
    ...privateLayoutSettings,
  },
  defaultSettings: {
    ...privateLayoutSettings,
  },
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      };
    case SET_DEFAULT_LAYOUT_SETTINGS:
      return {
        ...state,
        defaultSettings: { ...action.data },
      };
    default:
      return { ...state };
  }
};

export default layoutReducer;
