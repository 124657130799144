import React from "react";
import { Link } from "react-router-dom";
import image2 from "../../../assets/images/homepage/image-2.webp"
const GetFirstEstimateSection = () => {
    return (
        <section className="getfirst-estimate-section my-lg-5 pt-5 pb-5">
            <div className="container mt-lg-5">
                <div className="box pt-5 px-3 px-lg-5 text-center">
                    <h2 className="heading-big">
                        Obtenir ma première évaluation immobilière
                    </h2>
                    <div className="text mx-auto">
                        Ziaway s’engage à fournir une estimation objective, fiable et
                        complète pour tous vos projets.
                    </div>
                    <Link
                        to
                        className="mt-4 font-poppins rounded-2 py-3 px-5 btn btn-primary font-weight-500 text-16"
                    >
                        Estimer ma propriété
                    </Link>

                    <div className="image-box mt-4 mx-auto">
                        <img
                            className="d-block h-100 w-100"
                            src={image2}
                            alt="..."
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetFirstEstimateSection;
