import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import headerActions from "../../../redux/header/actions";
import {useTranslation} from "react-i18next";

const NotificationCard = ({data, language}) => {
    const { users = []} = useSelector(state => state.header);
    const [showCard, setShowCard] = useState(false);
    const [index, setIndex] = useState(0);
    const [firstRender, setFirstRender] = useState(true);
    const [blogs, setBlogs] = useState(data);

    useEffect(() => {
        if(language === "en") {
            setBlogs([...data].filter((v) => v.language ==="en"));
        } else {
            setBlogs([...data].filter((v) => v.language ==="fr"));
        }

    }, [data, language]);

    useEffect(() => {
        if (showCard === true && firstRender === true) {
            setFirstRender(false);
        }
    }, [showCard, firstRender]);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowCard((prevState) => !prevState); // Toggle the value between true and false
        }, 5000); // 3000 milliseconds = 3 seconds

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (index >= 19) {
            setIndex(0);
        } else if (showCard === true) {
            setIndex(index + 1);
        }
    }, [showCard]);

    const getImages = (license) => {
        return users.filter((v) => v.licenseId === license);
    };

    return (
        <>
            {blogs !== null && blogs.length > 0 && !firstRender && (
                <div
                    className={`notification-pos animated ${
                        showCard ? "fadeInRightBig" : "fadeOutRightBig"
                    }`}
                >
                    <div className="notification-card p-3">
                        <div className="d-flex">
                            <div className="notification-card__avatar bg-light rounded-circle">
                                {getImages(blogs[index]?.license).length !== 0 &&
                                getImages(blogs[index]?.license)[0]?.image ? (
                                    <img
                                        className="d-block h-100 w-100 rounded-circle"
                                        src={getImages(blogs[index]?.license)[0]?.image}
                                        alt=".."
                                    />
                                ) : (
                                    <>
                                        {blogs[index]?.photo && (
                                            <img
                                                className="d-block h-100 w-100"
                                                src={blogs[index]?.photo}
                                                alt=".."
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="ml-3">
                                <div className="notification-card__badge">
                                    Vente immobilière
                                </div>
                                <div className="notification-card__text">
                                    {blogs[index]?.courtier}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default NotificationCard;
