import React from "react";

const SmartHomeIcon = () => {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none">
      <path
        d="M30.531 38.8724H19.1278C18.3737 38.8724 17.7483 38.2471 17.7483 37.493C17.7483 36.7389 18.3737 36.1136 19.1278 36.1136H30.531C32.499 36.1136 34.4302 34.4765 34.7613 32.5269L37.2075 17.8867C37.4282 16.5625 36.6927 14.6679 35.6627 13.8402L22.9166 3.651C21.4452 2.47389 18.999 2.47387 17.546 3.63259L4.80016 13.8402C3.7518 14.6863 3.0345 16.5625 3.25521 17.8867L3.77019 20.9949C3.89894 21.7489 3.38397 22.4662 2.62988 22.5766C1.8758 22.7237 1.17691 22.1904 1.04816 21.4363L0.533174 18.3465C0.146934 16.0659 1.25049 13.1415 3.07134 11.6885L15.8172 1.48061C18.2817 -0.505767 22.1625 -0.487356 24.6455 1.49902L37.3914 11.6885C39.1938 13.1415 40.2974 16.0659 39.9295 18.3465L37.4833 32.9867C36.9316 36.2421 33.8233 38.8724 30.531 38.8724Z"
        fill="#5F3994"
      />
      <path
        d="M7.35726 39.2219C6.60317 39.2219 5.99622 38.6149 5.97783 37.8792C5.92265 35.3963 4.28575 33.7593 1.80278 33.7042C1.0487 33.6858 0.441732 33.0604 0.460124 32.288C0.478516 31.5339 1.08546 30.9453 1.83955 30.9453H1.87632C5.83067 31.0373 8.66311 33.8513 8.73668 37.8057C8.75508 38.5597 8.14811 39.2035 7.39402 39.2219C7.37563 39.2219 7.37565 39.2219 7.35726 39.2219Z"
        fill="#5F3994"
      />
      <path
        d="M12.875 39.222C12.1209 39.222 11.4955 38.6151 11.4955 37.861C11.4771 36.6471 11.2748 35.4884 10.907 34.4032C9.96894 31.7179 7.96417 29.7314 5.27889 28.775C4.19374 28.3887 3.03502 28.1866 1.82113 28.1866C1.06704 28.1866 0.44172 27.5612 0.460112 26.7888C0.460112 26.0347 1.08545 25.4277 1.83954 25.4277H1.85793C3.3845 25.4461 4.83747 25.7036 6.19851 26.1818C9.67466 27.4141 12.268 30.0074 13.5003 33.4836C13.9785 34.8446 14.236 36.316 14.2544 37.8242C14.2544 38.5967 13.6474 39.222 12.875 39.222Z"
        fill="#5F3994"
      />
      <path
        d="M1.83924 39.6814C0.809264 39.6814 0 38.8537 0 37.8422C0 36.8306 0.827656 36.0029 1.83924 36.0029C2.85082 36.0029 3.67847 36.8306 3.67847 37.8422C3.67847 38.8537 2.86921 39.6814 1.83924 39.6814Z"
        fill="#5F3994"
      />
    </svg>
  );
};

export default SmartHomeIcon;
