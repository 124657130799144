const TrustZiaway = () => {

    const data = [
        {
            title: `Estimation instantanée`,
            text: `En moins de 2 minutes, vous avez accès à l’estimation de la Valeur Marchande Ziaway
      de votre bien immobilier. Pour ce faire, il suffit d’indiquer les critères essentiels de votre
      propriété.`,
            text2: `Le rapport d’évaluation indique une fourchette de prix et un indice de confiance de
      l’estimation.`,
            image:
                "https://static.wixstatic.com/media/b4537e_bf4ff9fccae74487a630c40795b55f57~mv2.png/v1/fit/w_809,h_453,al_c,q_80/file.webp",
        },
        {
            title: `100% gratuit et sans engagement`,
            text: `Notre estimation immobilière est gratuite et sans engagement. Rapide, simple et efficace,
      vous savez en quelques clics combien vaut votre maison. À vous de jouer !`,
            text2: `L’avis d’estimation Ziaway demande uniquement votre courriel pour la création de votre
      compte où se trouve votre estimation instantanée.`,
            image:
                "https://static.wixstatic.com/media/b4537e_6ea4d4b6a6b14e7e829cd884b6ecc790~mv2.png/v1/fit/w_300,h_300,al_c,q_5/file.png",
        },
        {
            title: `Votre estimation immobilière est là`,
            text: `Pour chaque estimation de la valeur marchande de votre maison, Ziaway vous propose des
      comparables et le taux de similarité avec la propriété évaluée.`,
            text2: `Le rapport d’évaluation indique une liste de comparable et un score de similarité.`,
            image:
                "https://static.wixstatic.com/media/b4537e_c439bc61da5a4b39b9e162d887be8239~mv2.png/v1/fill/w_740,h_383,al_c,q_90/b4537e_c439bc61da5a4b39b9e162d887be8239~mv2.webp",
        },
        // {
        //   title: `Recommandations de professionnels`,
        //   text: `Pour que votre projet soit un véritable succès, Ziaway offre une liste de professionnels
        //     pertinents selon vos besoins. Nous prenons en compte leur dynamisme et leur
        //     connaissance du secteur.`,
        //   text2: `Le rapport d’évaluation indique une liste et les performances des courtiers recommandés.`,
        //   image:
        //     "https://static.wixstatic.com/media/b4537e_c439bc61da5a4b39b9e162d887be8239~mv2.png/v1/fill/w_740,h_383,al_c,q_90/b4537e_c439bc61da5a4b39b9e162d887be8239~mv2.webp",
        // },
    ];

    return (
        <>
            <section className="services-section bg-gray-100 p-5 py-5">
                <div className="container mt-4">
                    <div className="text-center mb-5">
                        {/* <div className="sub-title mb-2">Nos services.js</div> */}
                        <h2 className="heading-2 mb-0">
                            Évaluation maison : les raisons de faire confiance à ziaway
                        </h2>
                    </div>

                    <div className="mt-5">
                        <div
                            className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center"
                            style={{ rowGap: "2.7rem" }}
                        >
                            {data.map((item, index) => (
                                <div key={index} className="col">
                                    <div
                                        className="card-6 text-center h-100"
                                        style={{ backgroundImage: `url(${item.image})` }}
                                    >
                                        <div className="image-box p-2">
                                            <img
                                                className="d-block h-100 w-100 rounded-xl"
                                                src={item.image}
                                                alt="..."
                                            />
                                        </div>
                                        <div className="p-3">
                                            <h4
                                                title={item.title}
                                                className="mb-1 text-white font-weight-bold text-truncate"
                                            >
                                                {item.title}
                                            </h4>
                                            <div className="text-14 text-gray-500 mb-0">
                                                {item.text}
                                            </div>
                                            <div className="text-14 text-gray-500 mb-0">
                                                {item.text2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrustZiaway;
