import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    fallbackLng: 'es', // Fallback language
    lng: 'es', // Default language
    resources: {
        en: { translations: require('./assets/locales/en/translation.json') },
        es: { translations: require('./assets/locales/sp/translation.json') }
    },
    ns: ['translations'], // Namespace
    defaultNS: 'translations' // Default namespace
});

i18n.languages = ['en', 'es']; // Supported languages
export default i18n;
