import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const FormModify = ({
  data,
  address,
  setShowForm,
  dashboardEval,
  setFormulaizeData,
  goToStep,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const history = useNavigate();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    if (dashboardEval && dashboardEval === true) {
      setFormulaizeData((prev) => ({
        ...prev,
        ...data,
        anneeConstruction: parseInt(data.anneeConstruction),
        superficie: parseInt(data.superficie),
        // anneeConstruction: parseInt(
        //   date.getFullYear() - data.anneeConstruction
        // ),
        formComplete: true,
      }));
      if (data?.type === "Unifamiliale") {
        goToStep(9);
      } else if (data?.type === "condo") {
        goToStep(8);
      }
    } else {
      history("/estimate-form", { state: {address: address, data: data} });
    }
  };

  const validateNonNegative = (value) => {
    if (parseFloat(value) < 0) {
      return false; // Return false if the value is negative
    }
    return true; // Return true if the value is non-negative
  };

  useEffect(() => {
    setValue(
      "anneeConstruction",
      data.anneeConstruction
      // parseInt(date.getFullYear() - data.anneeConstruction)
    );
    setValue("superficie", parseInt(data.superficieTerrain));
    setValue("chambres", data.NbrChambres);
    setValue("bains", data.salleBains);
    setValue("stationnement", data.stationnements);
    // setValue("taxesMunicipale", data.taxeMunicipaleCalc);
    // setValue("evaluationMunicipale", data.evalMunicipalCalc);
    setValue("pieces", data.Nbrpieces);
    setValue("type", data.type);
    setValue("genreProprietes", data.genreProprietes);
    setValue("typeBatiment", data.typeBatiment);
  }, [data, setValue]);

  const numberValue = watch("superficie", ""); // Watching the 'number' field

  const enforceMaxLength = (e) => {
    const inputValue = e.target.value;
    const currentValue = numberValue;
    const limit = data?.type === "Unifamiliale" ? 6 : 5;

    if (inputValue.length > limit || inputValue.length < currentValue.length) {
      e.preventDefault();
      setValue("superficie", currentValue);
    } else {
      setValue("superficie", inputValue);
    }
  };

  const handleBackspace = (e) => {
    console.log("running", e.key);
    if (e.key === "Backspace") {
      e.preventDefault();
      const currentValue = numberValue;
      setValue("superficie", currentValue.toString().slice(0, -1));
    }
  };

  const numberValueyear = watch("anneeConstruction", ""); // Watching the 'number' field

  const enforceMaxLengthYear = (e) => {
    const inputValue = e.target.value;
    const currentValue = numberValueyear;

    if (inputValue.length > 4 || inputValue.length < currentValue.length) {
      e.preventDefault();
      setValue("anneeConstruction", currentValue);
    } else {
      setValue("anneeConstruction", inputValue);
    }
  };

  const handleBackspaceYear = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const currentValue = numberValueyear;
      setValue("anneeConstruction", currentValue.toString().slice(0, -1));
    }
  };

  const numberOptions = Array.from({ length: 11 }, (_, index) => index);
  const numberOptions2 = Array.from({ length: 6 }, (_, index) => index);
  const numberOptions3 = Array.from({ length: 30 }, (_, index) => index + 1);

  return (
    <div className="bg-white box-shadow-3 rounded-2 p-3 h-100 d-flex flex-column align-items-center justify-content-center text-center">
      <h4 className="mb-4 d-block text-violet-2 font-weight-bold font-poppins">
        {t("Estimate-new.10")}
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="nav" style={{ rowGap: "0.6rem" }}>
          <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                Type
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                disabled
                {...register("type", { required: true })}
              />
              {errors && errors?.type?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
            </div>
          </li>
          <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                {t("Estimate-new.12")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                disabled
                {...register("genreProprietes", { required: true })}
              />
              {errors && errors?.genreProprietes?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
            </div>
          </li>
          <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                {t("Estimate-new.13")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                disabled
                {...register("typeBatiment", { required: true })}
              />
              {errors && errors?.typeBatiment?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
            </div>
          </li>
          <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {t("Estimate-new.14")}
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="1990"
                {...register("anneeConstruction", {
                  required: true,
                  // min: 1850,
                  max: new Date().getFullYear(),
                })}
                onKeyDown={handleBackspaceYear}
                onInput={enforceMaxLengthYear}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setError("anneeConstruction", {
                      type: "required",
                    });
                  }
                  // else if (value < 1850) {
                  //   setError("anneeConstruction", {
                  //     type: "min",
                  //   });
                  // } else if (value > new Date().getFullYear()) {
                  //   setError("anneeConstruction", {
                  //     type: "max",
                  //   });
                  // }
                  else {
                    setError("anneeConstruction", null);
                  }
                }}
              />
            </div>
            {errors.anneeConstruction?.type === "required" && (
              <span className="text-danger"> {t("Estimate-new.11")}</span>
            )}
            {/* {errors?.anneeConstruction?.type === "min" && (
              <span className="text-danger">{t("Estimate-new.15")}</span>
            )} */}
            {errors.anneeConstruction?.type === "max" && (
              <span className="text-danger">{t("Estimate-new.16")}</span>
            )}
          </li>

          <li className="col-12">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                {t("Estimate-new.17")} <smal>(square ft)</smal>
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="10000 sq ft"
                {...register("superficie", {
                  required: true,
                  validate: validateNonNegative,
                  // min: data?.type === "Unifamiliale" ? 500 : 100,
                  // max: data?.type === "Unifamiliale" ? 100000 : 50000,
                })}
                onKeyDown={handleBackspace}
                onInput={enforceMaxLength}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setError("superficie", {
                      type: "required",
                      message: "This field is required",
                    });
                  } else if (!validateNonNegative(value)) {
                    setError("superficie", {
                      type: "validate",
                      message: "Value must be non-negative",
                    });
                  }
                  // else if (
                  //   data?.type === "Unifamiliale" ? value < 500 : value < 100
                  // ) {
                  //   setError("superficie", {
                  //     type: "min",
                  //     // message: "Value must be more",
                  //   });
                  // } else if (
                  //   data?.type === "Unifamiliale"
                  //     ? value > 100000
                  //     : value > 50000
                  // ) {
                  //   setError("superficie", {
                  //     type: "max",
                  //     // message: "Value must be less",
                  //   });
                  // }
                  else {
                    setError("superficie", null);
                  }
                }}
              />
              {errors?.superficie?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
              {errors.superficie?.type === "validate" && (
                <span className="text-danger"> {t("Estimate-new.124")} </span>
              )}
              {/* {errors.superficie?.type === "min" && (
                <span className="text-danger">
                  {" "}
                  {t("Estimate-new.125")}{" "}
                  {data?.type === "Unifamiliale" ? "500" : "100"}{" "}
                </span>
              )}
              {errors.superficie?.type === "max" && (
                <span className="text-danger">
                  {" "}
                  {t("Estimate-new.126")}{" "}
                  {data?.type === "Unifamiliale" ? "100000" : "50000"}{" "}
                </span>
              )} */}
            </div>
          </li>

          <li className="col-4">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                {t("Estimate-new.18")}
              </label>
              <Controller
                name="pieces" // Name for your select field
                control={control}
                // defaultValue={""}
                rules={{
                  required: true,
                  validate: validateNonNegative,
                  min: 1,
                  // max: 30,
                }} // Add rules if needed
                render={({ field }) => (
                  <select
                    className="form-control "
                    {...field}
                    //value={field.value}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {numberOptions3.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                    {!numberOptions3.includes(parseInt(field.value, 10)) && (
                      <option style={{ display: "none" }} value={field.value}>
                        {field.value}
                      </option>
                    )}
                  </select>
                )}
              />
              {/* <input
                type="number"
                className="form-control"
                placeholder="pieces"
                {...register("pieces", {
                  required: true,
                  validate: validateNonNegative,
                  min: 1,
                  max: 12,
                })}
              /> */}
              {errors?.pieces?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
              {/* {errors.pieces?.type === "max" && (
                <span className="text-danger"> {t("Estimate-new.127")} </span>
              )} */}
              {errors.pieces?.type === "min" && (
                <span className="text-danger"> {t("Estimate-new.128")} </span>
              )}
              {errors.pieces?.type === "validate" && (
                <span className="text-danger"> {t("Estimate-new.124")} </span>
              )}
            </div>
          </li>
          <li className="col-4">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {t("Estimate-new.19")}
              </label>
              <Controller
                name="chambres" // Name for your select field
                control={control}
                // defaultValue={""}
                rules={{
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  // max: 10,
                }} // Add rules if needed
                render={({ field }) => (
                  <select
                    className="form-control "
                    {...field}
                    //value={field.value}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {numberOptions.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                    {!numberOptions.includes(parseInt(field.value, 10)) && (
                      <option style={{ display: "none" }} value={field.value}>
                        {field.value}
                      </option>
                    )}
                  </select>
                )}
              />
              {/* <input
                type="number"
                className="form-control"
                {...register("chambres", {
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  max: 10,
                })}
              /> */}
              {errors?.chambres?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
              {errors.chambres?.type === "validate" && (
                <span className="text-danger"> {t("Estimate-new.124")} </span>
              )}
              {/* {errors.chambres?.type === "max" && (
                <span className="text-danger"> {t("Estimate-new.129")} </span>
              )} */}
            </div>
          </li>
          <li className="col-4">
            <div>
              <label
                title="salles de bain"
                className="d-block text-left text-truncate mb-2"
              >
                {t("Estimate-new.20")}
              </label>
              <Controller
                name="bains" // Name for your select field
                control={control}
                // defaultValue={""}
                rules={{
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  // max: 10,
                }} // Add rules if needed
                render={({ field }) => (
                  <select
                    className="form-control py-2 font-poppins text-14"
                    {...field}
                    //value={field.value}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {numberOptions.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                    {!numberOptions.includes(parseInt(field.value, 10)) && (
                      <option style={{ display: "none" }} value={field.value}>
                        {field.value}
                      </option>
                    )}
                  </select>
                )}
              />
              {/* <input
                type="number"
                className="form-control"
                {...register("bains", {
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  max: 10,
                })}
              /> */}
              {errors?.bains?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
              {errors.bains?.type === "validate" && (
                <span className="text-danger"> {t("Estimate-new.124")} </span>
              )}
              {/* {errors.bains?.type === "max" && (
                <span className="text-danger"> {t("Estimate-new.129")} </span>
              )} */}
            </div>
          </li>
          <li className="col-12">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {t("Estimate-new.21")}
              </label>

              <Controller
                name="stationnement" // Name for your select field
                control={control}
                rules={{
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  // max: 5,
                }} // Add rules if needed
                render={({ field }) => {
                  return (
                    <select className="form-control" {...field}>
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions2.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                      {!numberOptions2.includes(parseInt(field.value, 10)) && (
                        <option style={{ display: "none" }} value={field.value}>
                          {field.value}
                        </option>
                      )}
                    </select>
                  );
                }}
              />
              {/* <input
                type="number"
                className="form-control"
                {...register("stationnement", {
                  required: true,
                  validate: validateNonNegative,
                  min: 0,
                  max: 5,
                })}
              /> */}
              {errors?.stationnement?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
              {errors.stationnement?.type === "validate" && (
                <span className="text-danger"> {t("Estimate-new.124")} </span>
              )}
              {/* {errors.stationnement?.type === "max" && (
                <span className="text-danger"> {t("Estimate-new.130")} </span>
              )} */}
            </div>
          </li>
          {/* <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                {" "}
                Taxes municipale
              </label>
              <input
                type="number"
                className="form-control"
                {...register("taxesMunicipale", { required: true })}
              />
              {errors?.taxesMunicipale?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
            </div>
          </li>
          <li className="col-6">
            <div>
              <label className="d-block text-left text-truncate mb-2">
                Évaluation municipale
              </label>
              <input
                type="number"
                className="form-control"
                {...register("evaluationMunicipale", { required: true })}
              />
              {errors?.evaluationMunicipale?.type === "required" && (
                <span className="text-danger"> {t("Estimate-new.11")}</span>
              )}
            </div>
          </li> */}
        </ul>
        <div className="mt-4 text-center">
          <button
            type="submit"
            className="btn btn-lg text-12 btn-primary font-weight-600 font-poppins rounded-lg py-2 px-5 "
          >
            {t("Estimate-new.22")}
          </button>
          <button
            onClick={() => setShowForm(false)}
            type="button"
            className="btn btn-lg text-12 btn-outline-primary font-weight-600 font-poppins rounded-lg py-2 px-5 ml-2"
          >
            {t("Estimate-new.23")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormModify;
