import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import estimationActions from "../../../redux/estimation/actions";

const ThankyouPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(3);
  const dispatch = useDispatch();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(countdownInterval);
        if (
          location?.state?.newAccount &&
          location?.state?.newAccount === true
        ) {
            dispatch({
                type: estimationActions.SET_STATE,
                payload: {
                    verificationCode: false,
                    redirectTo: "/dashboard",
                }
            })
        } else {
            dispatch({
                type: estimationActions.SET_STATE,
                payload: {
                    verificationCode: false,
                    redirectTo: "/vmz/" + location?.state?.id,
                }
            })
        }

        console.log("Countdown reached 1. Your function is called!", location);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown]);

  useEffect(() => {
    if (!location?.state?.id && !location?.state?.newAccount) {
        location("/");
    }
  }, [location]);

  return (
      <div className="flex-fill text-center d-flex flex-column align-items-center justify-content-center p-3 p-sm-4">
        <h1 className="mb-5 display-5 font-weight-bold text-primary max-w-2">
          {/*{location?.state?.newAccount && location?.state?.newAccount === true
            ? "Just a little patience... Your personalized account is almost ready!"
            : t("Estimate-new.121")}*/}
          Just a little patience... Your personalized account is almost ready!"
        </h1>
        <h2 className="mb-5 text-primary">
          {t("Estimate-new.131")} {countdown} {t("Estimate-new.132")}
        </h2>
        <div className="position-relative">
          <div className="spinner-bubble spinner-bubble-primary"></div>
        </div>
      </div>
  );
};

export default ThankyouPage;
