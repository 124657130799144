import React from "react";
import { Link } from "react-router-dom";

const dataArray = [
  {
    title: "Lors de la vente d’un bien immobilier",
    description1:
      "Il est évident que la raison la plus répandue pour effectuer une évaluation immobilière réside dans le projet de vendre une maison, un appartement ou tout autre propriété. En effet, si vous avez le projet de vendre votre maison, vous devez impérativement savoir combien elle vaut véritablement.",
    description2:
      "Il faut donc la faire évaluer consciencieusement par un évaluateur neutre et fiable comme Ziaway. Le résultat de votre évaluation immobilière permettra de déterminer et de fixer le plus juste et le meilleur prix de vente pour votre bien.",
    imageUrl:
      "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Lors de l’achat d’un bien immobilier",
    description1:
      "Si vous êtes dans un projet d’achat de propriété, il est possible d’avoir un ordre d’idée de la véritable valeur marchande d’un bien grâce à notre outil d’estimation. En effet, si vous possédez assez d’éléments, de caractéristiques et d’informations concernant le bien que vous souhaitez acquérir, Ziaway est en mesure de vous fournir une fourchette de prix concernant la propriété visée.",
    description2:
      "De plus, dans le cas d’un achat de maison ou autre bien immobilier, afin d’approuver votre prêt, il est fort probable que votre prêteur hypothécaire demande un avis de valeur de la propriété convoitée.",
    imageUrl:
      "https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Lors de la location d’un bien immobilier",
    description1:
      "Dans le cas de la mise en location de votre maison, appartement ou propriété, il est bien utile de connaître la valeur réelle de votre bien. Grâce à une estimation immobilière réalisée par Ziaway, vous avez accès à la valeur marchande de votre bien et obtenez une estimation de loyer applicable.",
    description2:
      "En d’autres termes, dans le cas d’une mise en location, l’estimation immobilière permet de fournir un point de référence objectif pour déterminer un loyer compétitif. Vous serez donc en mesure de proposer un loyer approprié, maximisant vos rendements tout en restant attractif.",
    imageUrl:
      "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Lors d’un refinancement hypothécaire",
    description1: `Par définition, le refinancement hypothécaire est “un emprunt basé sur la valeur de votre
maison ou propriété”. Dans la majorité des cas, il est possible d’obtenir jusqu’à 80% de la
valeur marchande de votre bien immobilier. En conséquence, dans le cadre d’une
demande de refinancement, votre banque exige un rapport d’évaluation immobilière récent.
Celle-ci influence donc directement le montant de votre prêt, les taux d’intérêt applicables et
les conditions de refinancement.`,
    description2: `En résumé, une évaluation maison ou une estimation de bien précise assure aux
propriétaires de réussir à tirer le meilleur parti de la valeur du bien pour obtenir un
financement plus avantageux avec des conditions plus favorables.`,
    imageUrl:
      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Lors d’une séparation",
    description1: `Lors d’une séparation alors que vous êtes copropriétaire d’un bien immobilier avec votre
ancien.ne partenaire, une estimation immobilière s’avère très utile. En effet, elle permet
d’établir une valeur objective du bien en commun.`,
    description2: `La situation peut rapidement devenir complexe et il faut se poser les bonnes questions :
mettez-vous la maison en vente, votre partenaire rachète vos parts ou non, etc. L’évaluation
immobilière permet de guider le processus de partage des actifs afin d’éviter tout conflit pour
parvenir à un accord équitable. Elle offre donc une base solide pour la négociation des
arrangements financiers, une revente éventuelle ou le rachat des parts.`,
    imageUrl:
      "https://images.unsplash.com/photo-1598228723793-52759bba239c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Lors d’un règlement de succession",
    description1: `Dans le cadre d’une succession, le liquidateur successoral a pour mission d’effectuer
l’inventaire des biens du défunt. Si un bien immobilier est en jeu, le professionnel doit le faire
évaluer pour en déterminer la valeur sur le marché. L’évaluation immobilière offre un avis
objectif de la valeur marchande permettant de déterminer les droits de succession,
assurant une répartition équitable des actifs entre les héritiers.`,
    description2: `En conclusion, cela permet d’éviter les litiges familiaux en établissant une base
transparente pour le partage des biens immobiliers. De plus, elle facilite également la
planification financière des héritiers, que ce soit pour une conservation, une vente ou une
redistribution des biens.`,
    imageUrl:
      "https://images.unsplash.com/photo-1584738766473-61c083514bf4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const ImmobiliereSectionFour = () => {
  return (
    <section>
      <div className="container py-5">
        <div className="text-center">
          <h2 className="mb-4 max-w-2 mx-auto font-poppins font-weight-600">
            ÉVALUATION IMMOBILIÈRE : QUAND CONNAÎTRE LA VALEUR MARCHANDE DE
            VOTRE MAISON
          </h2>
          <p className="text-16">
            Tout d’abord, connaître la valeur de votre bien est utile tant sur
            le plan financier que décisionnel. En effet, l’évaluation
            immobilière vous permet de mieux appréhender la situation économique
            de votre patrimoine et d'ajuster une stratégie financière en
            conséquence. L’objectif étant évidemment de maximiser la valeur de
            votre patrimoine immobilier.
          </p>
          <p className="text-16">
            Être informé et conscient de la valeur marchande de votre propriété
            offre donc une base solide pour prendre des décisions éclairées, que
            ce soit pour vendre, acheter, louer ou gérer vos actifs immobiliers.
            Par ailleurs, celle-ci influence directement la capacité d'emprunt,
            offrant ainsi une vision claire de la situation financière globale.
          </p>
        </div>
      </div>

      <div className="py-5" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="container">
          <div className="d-flex flex-column" style={{ rowGap: "3.5rem" }}>
            {dataArray.map((data, index) => (
              <div
                key={index}
                className="row row-cols-1 row-cols-lg-2 align-items-center"
                style={{ rowGap: "2rem" }}
              >
                <div className="col">
                  <div className={index % 2 === 0 ? "pr-xl-4" : "pl-xl-4"}>
                    <h3 className="mb-3 font-poppins font-weight-600">
                      {data.title}
                    </h3>
                    <p>{data.description1}</p>
                    <p>{data.description2}</p>
                    <div className="mt-4">
                      <Link
                        to="/homepage"
                        className="btn btn-outline-primary font-poppins font-weight-500 px-3 py-2 rounded-lg"
                      >
                        Estimer ma propriété
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className={`col ${
                    index % 2 === 0
                      ? "order-first order-lg-last"
                      : "order-first"
                  }`}
                >
                  <div className="h-100 w-100">
                    <img
                      className="d-block h-100 w-100 rounded-2"
                      src={data.imageUrl}
                      alt=".."
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmobiliereSectionFour;
